// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Call } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = { Call };

// ==============================|| MENU ITEMS - category ||============================== //

const SuperAdminContacts: NavItemType = {
    id: 'SuperAdminContacts',
    type: 'group',
    name: 'Super Admin Contacts List',
    icon: icons.Call,
    children: [
        {
            icon: icons.Call,
            id: 'SuperAdminContacts',
            title: <FormattedMessage id="Contacts List" />,
            type: 'item',
            url: '/superadmin/contacts/list'
        }
    ]
};

export default SuperAdminContacts;

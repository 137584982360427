import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  organizationsdata: [],
  pageIndex: 1,
  deleted: false,
  count: 0,
  size: 10,
  search: undefined
};

const organizationsdata = createSlice({
  name: 'organizationsdata',
  initialState,
  reducers: {
    updateorganizationsdata(state, action) {
      state.organizationsdata = action.payload;
    },
    setorganizationsdataSearch(state, action) {
      state.search = action.payload;
    },
    setOrganizationTotalCount(state, action) {
      state.count = action.payload;
    },
    setorganizationsdatastatus(state, action) {
      state.deleted = action.payload;
    },
    setorganizationsdataPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    delorganizationsdata(state: any, action) {
      state.organizationsdata.data = state.organizationsdata.data.filter((item: { _id: any }) => item._id !== action.payload);
    },
    setorganizationsdataTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    },
    resetStateorganizationsdata: (state) => {
      return initialState;
    }
  }
});

export const {
  updateorganizationsdata,
  setorganizationsdataSearch,
  setOrganizationTotalCount,
  setorganizationsdatastatus,
  setorganizationsdataPageIndex,
  delorganizationsdata,
  setorganizationsdataTableSize,
  resetStateorganizationsdata
} = organizationsdata.actions;
export default organizationsdata.reducer;

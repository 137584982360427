// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Setting2 } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  Setting: Setting2
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const Settings: NavItemType = {
  id: 'group-Settings',
  // title: <FormattedMessage id="Settings" />,
  icon: icons.Setting,
  type: 'group',
  name: 'AccountSettings',
  children: [
    {
      id: 'Settings',
      title: <FormattedMessage id="Account Settings" />,
      type: 'item',
      url: '/settings/accountSettings',
      icon: icons.Setting
    }
  ]
};

export default Settings;

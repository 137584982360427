import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sections: [],
  ticket: [],
  pageIndex: 1,
  size: 10,
  totalCount: 0,
  deleted: false,
  search: undefined,
  ticketSorting: []
};

const ticket = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    updateticket(state, action) {
      state.ticket = action.payload;
    },
    updatesections(state, action) {
      state.sections = action.payload;
    },
    updateSectionDetails(state: any, action) {
      const newSection = { title: action.payload.title, totalMark: 0, questionDetails: [] };
      state.sections = [...state.sections, newSection];
    },
    setticketTotalCount(state, action) {
      state.totalCount = action.payload;
    },

    setticketPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    deleteSectionData(state: any, action) {
      state.sections.splice(action.payload, 1);
    },
    deleteQuestionData(state: any, action) {
      const { index, _id } = action.payload;
      state.sections[index].questionDetails = state.sections[index].questionDetails.filter(
        (item: { questionId: any }) => item.questionId !== _id
      );
    },
    setticketstatus(state, action) {
      state.deleted = action.payload;
    },
    setticketSearch(state, action) {
      state.search = action.payload;
    },
    setticketSorting(state, action) {
      state.ticketSorting = action.payload;
    },
    delticket(state: any, action) {
      state.ticket = state.ticket.filter((item: { _id: any }) => item._id !== action.payload);
    },
    setticketTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    },
    resetStateticketTable: (state) => {
      return initialState;
    },
  }
});

export const {
  updateticket,
  updatesections,
  updateSectionDetails,
  deleteSectionData,
  deleteQuestionData,
  setticketSearch,
  setticketstatus,
  setticketSorting,
  setticketPageIndex,
  delticket,
  setticketTotalCount,
  setticketTableSize,
  resetStateticketTable
} = ticket.actions;
export default ticket.reducer;

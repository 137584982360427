import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mainReview: {},
  allUserReview: []
};

const courseReview = createSlice({
  name: 'courseReview',
  initialState,
  reducers: {
    setMainReview(state, action) {
      state.mainReview = action.payload;
    },
    setMainReview2(state, action) {
      var myFeedback = action.payload.feedback;
      let feedbackCopy = JSON.parse(JSON.stringify(myFeedback));
      let index = myFeedback?.ratings.findIndex((feedback: any) => feedback.rating === Number(action.payload.prevRating));

      if ((index === 0 || index) && index !== -1) {
        let count = feedbackCopy.ratings[index].docCount;
        feedbackCopy.ratings[index].docCount = count + 1;
      } else {
        if (feedbackCopy) {
          feedbackCopy.ratings = [...feedbackCopy.ratings, { docCount: 1, rating: Number(action.payload.prevRating), percentage: 0 }];
        } else {
          feedbackCopy = { average: 0, ratings: [{ docCount: 1, rating: Number(action.payload.prevRating), percentage: 0 }] };
        }
      }
      let sumRating = feedbackCopy.ratings.reduce((sum: any, data: any) => sum + data.docCount, 0);
      let avgrating = 0;
      let datas = feedbackCopy;
      feedbackCopy?.ratings.map((data: any, index: number) => {
        avgrating += data.rating * data.docCount;
        let percent = (data.docCount / sumRating) * 100;
        if (feedbackCopy.ratings && feedbackCopy.ratings[index]) {
          feedbackCopy.ratings[index].percentage = percent;
        }
      });
      feedbackCopy = datas;
      avgrating = avgrating / sumRating;
      feedbackCopy.average = avgrating;
      state.mainReview = feedbackCopy;
    },
    setAllUserReview(state, action) {
      state.allUserReview = action.payload;
    },
    addAllUserReview(state: any, action: any) {
      state.allUserReview = [action.payload, ...state.allUserReview];
    },
    updateUserReview(state: any, action: any) {
      let prevrating = 0;
      const arr = state.allUserReview.map((section: any) => {
        prevrating = section.rating;
        if (section._id === action.payload._id) {
          section.description = action.payload.description;
          section.rating = action.payload.rating;
          return section;
        }
        return section;
      });
      state.allUserReview = arr;
      if (prevrating !== action.payload.rating) {
        const sumRatings = state.mainReview.ratings || [];
        let count = 0;
        var newReview = sumRatings.map((data: any) => {
          const newData = { ...data };

          if (data.rating === Number(prevrating)) {
            let count = data.docCount;
            count--;
            newData.docCount = count;
          }
          if (data.rating === Number(action.payload.rating)) {
            count++;
            newData.docCount += 1;
          }

          // if (newData?.docCount !== 0) {
            return newData;
          // }
        });
        newReview = newReview.filter((data: any) => data?.docCount !== 0)
       
        if (count === 0) {
          newReview = [...newReview, { rating: action.payload.rating, docCount: 1, percentage: 0 }];
        }

        const sumRating = newReview.reduce((sum: any, data: any) => sum + data?.docCount, 0); // Provide an initial value

        let avgrating = 0;
        const updatedReview = newReview.map((data: any) => {
          if (data) {
            avgrating += data.rating * data?.docCount;
            const percent = (data.docCount / sumRating) * 100;
            data.percentage = percent;
            return data;
          }
          return {}
        });

        avgrating = avgrating / sumRating;
        state.mainReview.average = avgrating;
        state.mainReview.ratings = updatedReview;
      }
    },
    delUserReview(state: any, action: any) {
      const prevarr = state.allUserReview.filter((section: any) => section._id === action.payload);
      const arr = state.allUserReview.filter((section: any) => section._id !== action.payload);

      state.allUserReview = arr;
      const sumRatings = prevarr.ratings || [];
      var newReview = sumRatings.filter((data: any) => {
        if (data.rating === Number(prevarr.rating)) {
          data.docCount -= 1;
        }
        if (data?.docCount !== 0) {
          return data;
        }
      });
      const sumRating = newReview.reduce((sum: any, data: any) => sum + data?.docCount, 0); // Provide an initial value

      let avgrating = 0;
      const updatedReview = newReview.map((data: any) => {
        avgrating += data.rating * data?.docCount;
        const percent = (data.docCount / sumRating) * 100;
        data.percentage = percent;
        return data;
      });
      avgrating = avgrating / sumRating;
      state.mainReview.average = avgrating ? avgrating : 0;
      state.mainReview.ratings = updatedReview;
    }
  }
});
export const { setMainReview, setMainReview2, delUserReview, updateUserReview, addAllUserReview, setAllUserReview } = courseReview.actions;
export default courseReview.reducer;

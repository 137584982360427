// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { ElementPlus } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = { ElementPlus };

// ==============================|| MENU ITEMS - category ||============================== //

const LandingPageManagement: NavItemType = {
  id: 'LandingPageManagement',
//   title: <FormattedMessage id="LandingPageManagement" />,
  type: 'group',
  //   name: 'LandingPageManagement',
  icon: icons.ElementPlus,
  children: [
    {
      icon: icons.ElementPlus,
      id: 'LandingPageManagement',
      title: <FormattedMessage id="Landing Page Content" />,
      type: 'item',
      url: '/superadmin/landingpagemanagement'
    }
  ]
};

export default LandingPageManagement;

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Calendar } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  icon1: Calendar
};

// ==============================|| MENU ITEMS - FORMS & TABLES ||============================== //

const Events: NavItemType = {
  id: 'events',
  icon: icons.icon1,
  type: 'group',
  name: 'Events',
  children: [
    {
      id: 'events',
      title: <FormattedMessage id="Events" />,
      type: 'item',
      url: '/event',
      icon: icons.icon1,
      breadcrumbs: false
    }
  ]
};

export default Events;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  usercourseslist: [],
  pageIndex: 1,
  size: 10
};

const usercourseslist = createSlice({
  name: 'usercourseslist',
  initialState,
  reducers: {
    updateusercourseslist(state, action) {
      state.usercourseslist = action.payload;
    },
    setusercourseslistPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    setusercourseslistTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    }
  }
});

export const { updateusercourseslist, setusercourseslistPageIndex, setusercourseslistTableSize } = usercourseslist.actions;
export default usercourseslist.reducer;
// @ts-nocheck -- this will disable all the warnings and errors in this particular file
import { useEffect, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import './styles.css';
Quill.register('modules/imageResize', ImageResize);
const Editor = (props: {
  placeholder: string;
  htmlContent: string;
  setHtmlContent: (html: string) => void;
  extraTool?: Array<string>;
  setIsPlaying?: (val: boolean) => void;
}) => {
  const { placeholder, htmlContent, setHtmlContent, extraTool, setIsPlaying } = props;
  const [html, setHtml] = useState(htmlContent);
  useEffect(() => {
    setHtml(htmlContent);
  }, [htmlContent]);
  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image'
  ];
  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['clean'],
      extraTool
    ],
    clipboard: {
      matchVisual: false
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    }
  };
  const handleChange = (html: string) => {
    setHtml(html);
    setHtmlContent(html);
  };
  const handleFocus = () => {
    setIsPlaying?.(false);
  };
  const handleBlur = () => {
    setIsPlaying?.(true);
  };
  return (
    <ReactQuill
      theme={'snow'}
      onChange={handleChange}
      value={html}
      modules={modules}
      style={{ color: '#333' }}
      formats={formats}
      bounds={'#root'}
      placeholder={placeholder}
      onFocus={setIsPlaying ? handleFocus : () => {}}
      onBlur={setIsPlaying ? handleBlur : () => {}}
    />
  );
};

export default Editor;

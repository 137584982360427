import axios from 'axios';

const API_URL = process.env.REACT_APP_BASEURL + 'userCourse';

export const getUserCourses = async (reqData: any) => {
  return axios.get(`${API_URL}/getUserCourses`, { params: reqData });
};

export const getUserContent = async (reqData: any) => {
  return axios.put(`${API_URL}/inprogressCourses`, reqData);
};

export const userSubscribed = async (reqData: any) => {
  return axios.put(`${API_URL}/subscribeCourse`, reqData);
};

export const unSubscribeCourse = async (reqData: any) => {
  return axios.put(`${API_URL}/unSubscribeCourse`, reqData);
};

export const getViewCountData = (orgId: Object) => {
  return axios.get(`${API_URL}/getViewCount`, { params: orgId });
};
import { useEffect, useRef, useState } from 'react';

// material-ui
import { CircularProgress, Grid, Stack } from '@mui/material';

// project-imports
import ComponentWrapper from 'sections/components-overview/ComponentWrapper';
import ComponentSkeleton from 'sections/components-overview/ComponentSkeleton';

// ==============================|| COMPONENTS - PROGRESS ||============================== //

const Loader2 = () => {
  const [bufferProgress, setBufferProgress] = useState(0);

  const progressRef = useRef(() => {});
  useEffect(() => {
    progressRef.current = () => {
      if (bufferProgress > 100) {
        setBufferProgress(0);
      } else {
        const diff = Math.random() * 10;
        // const diff2 = Math.random() * 10;
        setBufferProgress(bufferProgress + diff);
      }
    };
  });

  useEffect(() => {
    const bufferTimer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(bufferTimer);
    };
  }, []);

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {/* <ComponentSkeleton> */}
      <ComponentWrapper>
        <Stack spacing={3}>
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <CircularProgress size={50} />
            </Grid>
          </Grid>
        </Stack>
      </ComponentWrapper>
      {/* </ComponentSkeleton> */}
    </div>
  );
};

export default Loader2;

import { useEffect, useRef, useState } from 'react';
import { Tabs, Tab, Box, useMediaQuery } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

const HeaderOption = (props: any) => {
  const { user }: any = useAuth();
  const location = useLocation();
  // const anchorRef = useRef<any>(null);
  const permission = user?.userTypeId?.permission;
  const [value, setValue] = useState(location.pathname);
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('1072'));
  useEffect(() => {
    setValue(location.pathname);
  }, [location.pathname]);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: isSmallScreen ? 'fit-content' : '100%'
      }}
      className="navbar-control"
    >
      <Tabs
        value={value}
        onChange={handleChange}
        onClick={props.handleClose}
        textColor="inherit"
        variant={isSmallScreen ? 'scrollable' : 'standard'}
        orientation={isSmallScreen ? 'vertical' : 'horizontal'}
        // indicatorColor="#13AC9E"
        TabIndicatorProps={{
          sx: { backgroundColor: '#13AC9E', height: { lg: 4, md: 4, sm: 30 } }
        }}
      >
        <Tab
          label="Dashboard"
          value="/learner"
          component={Link}
          to="/learner"
          onClick={props.handleClose}
          style={{
            color: value === '/learner' ? '#13AC9E' : '#5B6B79',
            textDecoration: 'none',
            padding: '0px 8px',
            fontSize: '16px',
            fontWeight: '500'
          }}
        />
        {permission?.UserCourses?.View && (
          <Tab
            label="My Learning"
            value="/learner/mylearning"
            component={Link}
            to="/learner/mylearning"
            onClick={props.handleClose}
            style={{
              color: value === '/learner/mylearning' ? '#13AC9E' : '#5B6B79',
              textDecoration: 'none',
              padding: '0px 8px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )}
        {permission?.Quiz?.View && (
          <Tab
            label="Quiz"
            value="/learner/quiz"
            component={Link}
            to="/learner/quiz"
            onClick={props.handleClose}
            style={{
              color: value === '/learner/quiz' ? '#13AC9E' : '#5B6B79',
              textDecoration: 'none',
              padding: '0px 8px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )}
        {permission?.TimeTable?.View && (
          <Tab
            label="Time-Table"
            value="/learner/timetable"
            component={Link}
            to="/learner/timetable"
            onClick={props.handleClose}
            style={{
              color: value === '/learner/timetable' ? '#13AC9E' : '#5B6B79',
              textDecoration: 'none',
              padding: '0px 8px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )}
        {permission?.TimeTable?.View && (
          <Tab
            label="Results"
            value="/learner/result"
            component={Link}
            to="/learner/result"
            onClick={props.handleClose}
            style={{
              color: value === '/learner/result' ? '#13AC9E' : '#5B6B79',
              textDecoration: 'none',
              padding: '0px 8px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )}
        {(permission?.Events?.View || permission?.Meeting?.View) && (
          <Tab
            label="Events"
            value="/learner/event"
            component={Link}
            to="/learner/event"
            onClick={props.handleClose}
            style={{
              color: value === '/learner/event' ? '#13AC9E' : '#5B6B79',
              textDecoration: 'none',
              padding: '0px 8px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )}
        {permission?.Discussions?.View && (
          <Tab
            label="Discussions"
            value="/learner/discussion"
            component={Link}
            to="/learner/discussion"
            onClick={props.handleClose}
            style={{
              color: value === '/learner/discussion' ? '#13AC9E' : '#5B6B79',
              textDecoration: 'none',
              padding: '0px 8px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )}
        {permission?.Assignment?.View && (
          <Tab
            label="Assignments"
            value="/learner/assignments"
            component={Link}
            to="/learner/assignments"
            onClick={props.handleClose}
            style={{
              color: value === '/learner/assignments' ? '#13AC9E' : '#5B6B79',
              textDecoration: 'none',
              padding: '0px 8px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )}
        {permission?.Tickets?.View && (
          <Tab
            label="Tickets"
            value="/learner/ticket"
            component={Link}
            to="/learner/ticket"
            style={{
              color: value === '/learner/ticket' ? '#13AC9E' : '#5B6B79',
              textDecoration: 'none',
              padding: '0px 8px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )}

        {permission?.UserCourses?.Purchase && isSmallScreen && (
          <Tab
            label="Cart"
            value="/learner/checkout"
            component={Link}
            to="/learner/checkout"
            style={{
              color: value === '/learner/checkout' ? '#13AC9E' : '#5B6B79',
              textDecoration: 'none',
              padding: '0px 8px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )}
        {permission?.UserCourses?.Purchase && isSmallScreen && (
          <Tab
            label="Wishlist"
            value="/learner/wishlist"
            component={Link}
            to="/learner/wishlist"
            style={{
              color: value === '/learner/wishlist' ? '#13AC9E' : '#5B6B79',
              textDecoration: 'none',
              padding: '0px 8px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )}
        {permission?.Subscription?.View && (
          <Tab
            label="Packages"
            value="/learner/packages"
            component={Link}
            to="/learner/packages"
            style={{
              color: value === '/learner/packages' ? '#13AC9E' : '#5B6B79',
              textDecoration: 'none',
              padding: '0px 8px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )}
        {permission?.Chats?.View && (
          <Tab
            label="Chats"
            value="/learner/chat"
            component={Link}
            to="/learner/chat"
            style={{
              color: value === '/learner/chat' ? '#13AC9E' : '#5B6B79',
              textDecoration: 'none',
              padding: '0px 20px',
              fontSize: '16px',
              fontWeight: '500'
            }}
          />
        )}
      </Tabs>
    </Box>
  );
};

export default HeaderOption;

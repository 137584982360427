import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  feedback: [],
  pageIndex: 1,
  size: 10,
  search: undefined,
  count: 0
};

const feedback = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    updatefeedback(state, action) {
      state.feedback = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload;
    },
    deleteOneFeedback(state, action) {
      const arr = state.feedback?.data.filter((item: any) => item._id !== action.payload);
      const count = state.feedback?.totalCount?.[0]?.count;
      state.feedback = { data: arr, totalCount: [{ count: count - 1 }] };
    },
    setfeedbackPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    setfeedbackSearch(state, action) {
      state.search = action.payload;
    },
    setfeedbackTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    },
    resetFeedback: (state) => {
      return initialState;
    },
  }
});

export const { updatefeedback, setCount, deleteOneFeedback, setfeedbackSearch, setfeedbackPageIndex, setfeedbackTableSize, resetFeedback } =
  feedback.actions;
export default feedback.reducer;

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { ArchiveBook, BookSaved, AddCircle, Gift, Book, MedalStar, DocumentText, RefreshSquare } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  icon1: ArchiveBook,
  icon2: Book,
  certificate: MedalStar,
  myCourse: BookSaved,
  addIcon: AddCircle,
  offerIcon: Gift,
  assignmentIcon: DocumentText,
  courseReassignIcon: RefreshSquare
};

// ==============================|| MENU ITEMS - category ||============================== //

const courseSupAdmin: NavItemType = {
  id: 'SuperAdmincourse',
  //   title: <FormattedMessage id="Add Organization" />,
  type: 'group',
  //   name: 'Add Organization',
  icon: icons.icon1,
  children: [
    {
      icon: icons.icon1,
      id: 'SuperAdminCourse',
      title: <FormattedMessage id="Course" />,
      type: 'item',
      url: '/superadmin/course/list'
    }
  ]
};

export default courseSupAdmin;

import { createSlice } from '@reduxjs/toolkit';
import StatusCode from '../../utils/StatusCode';
const initialState: any = {
  data: [],
  selectedCourse: [],
  pageIndex: 1,
  learnerSearch: '',
  pageLimit: 10,
  count: 0,
  wishlistRelatedCourse: [],
  uplodedFiles: [],
  search: '',
  sortCourse: [
    {
      id: 'name',
      sort: true,
      desc: false
    }
  ],
  facultyCourse: [],
  selectedCourseId: '',
  status: StatusCode.IDLE
};
const clearState = (state: any) => {
  return { data: [], selectedCourse: [], facultyCourse: [], selectedCourseId: state.selectedCourseId, status: StatusCode.IDLE };
};

const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    resetCourseState() {
      return initialState
    },
    getCourses(state, action) {
      return {
        ...state,
        data: action.payload
      };
    },
    setLernerSearch(state, action) {
      state.learnerSearch = action.payload;
    },
    setUploadedFiles(state, action) {
      state.uplodedFiles = action.payload;
    },
    getWishlistRelatedCourses(state, action) {
      state.wishlistRelatedCourse = action.payload;
    },
    setAddFilesUploadedFiles(state: any, action) {
      state.uplodedFiles = [...state.uplodedFiles, ...action.payload];
    },
    deleteUploadedFiles(state, action) {
      state.uplodedFiles = state.uplodedFiles.filter((val: any) => val?._id !== action.payload);
    },
    setCourseSorting(state, action) {
      state.sortCourse = action.payload;
    },
    delCourse(state, action) {
      return {
        ...state,
        data: state.data.filter((item: { _id: any }) => item._id !== action.payload)
      };
    },
    getSelectedCourse(state, action) {
      state.selectedCourse = action.payload;
      state.selectedCourseId = action.payload?._id;
    },
    delSelectedSection(state, action) {
      const arr = state.selectedCourse.section.filter((section: any) => section._id !== action.payload);
      state.selectedCourse.section = arr;
    },
    setCourseTablePageIndex(state, action) {
      state.pageIndex = action.payload;
    },
    setCourseTableCount(state, action) {
      state.count = action.payload;
    },
    setCourseTableSearch(state, action) {
      state.search = action.payload;
    },
    setCourseTablePageLimit(state, action) {
      state.pageLimit = action.payload;
    },
    updateSectionName(state, action) {
      const arr = state.selectedCourse.section.map((section: any) => {
        if (section._id === action.payload.sectionId) {
          section.name = action.payload.name;
          section.sectionType = action.payload.sectionType;
          return section;
        }
        return section;
      });
      state.selectedCourse.section = arr;
    },
    delSelectedContent(state, action) {
      const arr = state.selectedCourse.section.filter((section: any) => {
        if (section.contentId.length !== 0) {
          const content = section.contentId.filter((content: any) => content._id !== action.payload);
          section.contentId = content;
        }
        if (section?.assessmentId?._id === action.payload) {
          delete section.assessmentId;
        }
        return section;
      });
      state.selectedCourse.section = arr;
    },
    addSection(state, action) {
      state.selectedCourse.section = [...state.selectedCourse.section, action.payload];
    },
    clearBlogpost(state: any) {
      return clearState(state);
    },
    setFacultyCourses(state, action) {
      return {
        ...state,
        facultyCourse: action.payload
      };
    }
  }
});

export const {
  getCourses,
  delCourse,
  getSelectedCourse,
  deleteUploadedFiles,
  setUploadedFiles,
  setCourseTablePageLimit,
  setCourseTableCount,
  setAddFilesUploadedFiles,
  setCourseTableSearch,
  setCourseTablePageIndex,
  getWishlistRelatedCourses,
  setLernerSearch,
  delSelectedSection,
  setCourseSorting,
  updateSectionName,
  delSelectedContent,
  addSection,
  clearBlogpost,
  setFacultyCourses,
  resetCourseState
} = courseSlice.actions;
export default courseSlice.reducer;

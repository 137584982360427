// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Setting2, StatusUp } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  subscription: StatusUp
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const Subscription: NavItemType = {
  id: 'group-Subscription',
  // title: <FormattedMessage id="Settings" />,
  icon: icons.subscription,
  type: 'group',
  name: 'Subscription',
  children: [
    {
      id: 'Subscription',
      title: <FormattedMessage id="Subscription" />,
      type: 'item',
      url: '/subscription',
      icon: icons.subscription
    }
  ]
};

export default Subscription;

import { Avatar, Box } from '@mui/material';
import { Grid, Stack, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import { Book } from 'iconsax-react';

interface MessageCardProps {
  title: string;
  message: string;
  src: string | null;
  name: string;
  courseName: string;
}

const MessageCard = ({ title, message, src, name, courseName }: MessageCardProps) => {
  const parser = new DOMParser();
  return (
    <MainCard>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={1}>
            {src ? (
              <Avatar variant="rounded" alt="course" src={src} />
            ) : (
              <Avatar alt="course" variant="rounded">
                <Book />
              </Avatar>
            )}
            <Typography variant="body2" color="secondary" sx={{
              fontWeight: '700', overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
            }}>
              {courseName}
            </Typography>
          </Stack>
          <Box sx={{ textAlign: 'end' }}>
            <Typography
              sx={{ fontSize: '10px' }}
              variant="caption"
              color="secondary"
            >
              {'~ ' + name}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
          <Typography variant="subtitle1">{title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>{parser.parseFromString(message, 'text/html').documentElement.textContent}</Typography>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default MessageCard;

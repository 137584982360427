// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { UserTick } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = { UserTick };

// ==============================|| MENU ITEMS - category ||============================== //

const SuperAdminUser: NavItemType = {
    id: 'SuperAdminUser',
    //   title: <FormattedMessage id="Add Organization" />,
    type: 'group',
    //   name: 'Add Organization',
    icon: icons.UserTick,
    children: [
        {
            icon: icons.UserTick,
            id: 'SuperAdminUser',
            title: <FormattedMessage id="Users" />,
            type: 'item',
            url: '/superadmin/user/list'
        }
    ]
};

export default SuperAdminUser;

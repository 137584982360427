import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  packagelist: [],
  pageIndex: 1,
  size: 10,
  count: 0,
  search: undefined
};

const packagelist = createSlice({
  name: 'packagelist',
  initialState,
  reducers: {
    updatepackagelist(state, action) {
      state.packagelist = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload;
    },
    setpackagelistPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    setpackagelistSearch(state, action) {
      state.search = action.payload;
    },
    delpackagelist(state: any, action) {
      state.packagelist.data = state.packagelist.data.filter((item: { _id: any }) => item._id !== action.payload);
    },

    setpackagelistTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    },
    resetStatepackagelist: (state) => {
      return initialState;
    }
  }
});

export const {
  updatepackagelist,
  setCount,
  setpackagelistSearch,
  setpackagelistPageIndex,
  setpackagelistTableSize,
  delpackagelist,
  resetStatepackagelist
} = packagelist.actions;
export default packagelist.reducer;

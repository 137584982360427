import { createSlice } from '@reduxjs/toolkit';
const initialState: any = {
  data: [],
  count: 0,
  pageIndex: 1,
  size: 10,
  search: ''
};

const notifyfaculty = createSlice({
  name: 'notifyfaculty',
  initialState,
  reducers: {
    setnotifyfaculty(state, action) {
      return {
        ...state,
        data: action.payload
      };
    },
    delnotifyfaculty(state, action) {
      return {
        ...state,
        data: state.data.filter((item: { _id: any }) => item._id !== action.payload)
      };
    },
    setnotifyfacultySearch(state, action) {
      state.search = action.payload;
    },
    setnotifyfacultyTableSize(state, action) {
      state.size = action.payload;
    },
    setnotifyfacultyPageIndex(state, action) {
      state.pageIndex = action.payload;
    },
    setnotifyfacultyCount(state, action) {
      state.count = action.payload;
    }
  }
});

export const {
  setnotifyfaculty,
  delnotifyfaculty,
  setnotifyfacultySearch,
  setnotifyfacultyTableSize,
  setnotifyfacultyPageIndex,
  setnotifyfacultyCount
} = notifyfaculty.actions;
export default notifyfaculty.reducer;

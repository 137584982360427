import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  groupsview: [],
  pageIndex: 1,
  size: 10,
  deleted: false,
  search: undefined,
  groupsviewSorting: []
};

const groupsview = createSlice({
  name: 'groupsview',
  initialState,
  reducers: {
    updategroupsview(state, action) {
      state.groupsview = action.payload;
    },
    setgroupsviewPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    setgroupsviewstatus(state, action) {
      state.deleted = action.payload;
    },
    setgroupsviewSearch(state, action) {
      state.search = action.payload;
    },
    setgroupsviewSorting(state, action) {
      state.groupsviewSorting = action.payload;
    },
    delgroupsview(state: any, action) {
      state.groupsview.groupData = state.groupsview.groupData.filter((item: { _id: any }) => item._id !== action.payload);
    },
    setgroupsviewTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    },
    resetStategroupsview: (state) => {
      return initialState;
    },
  }
});

export const {
  updategroupsview,
  setgroupsviewSearch,
  setgroupsviewstatus,
  setgroupsviewSorting,
  setgroupsviewPageIndex,
  delgroupsview,
  setgroupsviewTableSize,
  resetStategroupsview
} = groupsview.actions;
export default groupsview.reducer;

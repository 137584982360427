import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  assessmentreport: [],
  pageIndex: 1,
  assesmentReportSort: [],
  size: 10,
  search: undefined,
  deleted: false
};

const assessmentreport = createSlice({
  name: 'assessmentreport',
  initialState,
  reducers: {
    updateassessmentreport(state, action) {
      state.assessmentreport = action.payload;
    },
    setassesmentsearch(state, action) {
      state.search = action.payload;
    },
    setassesmentstatus(state, action) {
      state.deleted = action.payload;
    },
    setAssesmentReportSort(state, action) {
      state.assesmentReportSort = action.payload;
    },
    setAssessmentreportPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    delassessmentreport(state: any, action) {
      state.assessmentreport.orgAssessments.assessmentData = state.assessmentreport.orgAssessments.assessmentData.filter(
        (item: { _id: any }) => item._id !== action.payload
      );
    },
    setAssessmentreportTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    },
    resetStateAssessmentreport: (state) => {
      return initialState;
    },
  }
});

export const {
  updateassessmentreport,
  setassesmentsearch,
  setAssessmentreportPageIndex,
  setassesmentstatus,
  setAssesmentReportSort,
  delassessmentreport,
  setAssessmentreportTableSize,
  resetStateAssessmentreport
} = assessmentreport.actions;
export default assessmentreport.reducer;

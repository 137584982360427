import useConfig from 'hooks/useConfig';
import { useEffect, useState } from 'react';
import { dispatch } from 'store';
import { closeSnackbar, openSnackbar } from 'store/reducers/snackbar';
import { getLocaleName } from './helper';

// const getTranslatedMessage = (originalMessage: any, placeValues: any) => {
//   return placeValues?.[originalMessage] || originalMessage;
// };

const Snackbar = (message: String, color: String) => {

  // console.log("Snackbar Messsage is:", message)
  // const { i18n } = useConfig();
  // const [PlaceValues, setPlaceValues] = useState<any>();
  // useEffect(() => {
  //   getLocaleName(i18n)
  //     .then((result) => {
  //       setPlaceValues(result);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [i18n]);

  dispatch(
    openSnackbar({
      open: true,
      message: message,
      // getTranslatedMessage(message, PlaceValues),
      variant: 'alert',
      alert: {
        color: color
      },
      close: true
    })
  );
  setTimeout(() => {
    dispatch(closeSnackbar());
  }, 3000);
};

export default Snackbar;

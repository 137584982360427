import { FormattedMessage } from 'react-intl';
import { Ticket2 } from 'iconsax-react';
import { NavItemType } from 'types/menu';

// icons
const icons = { Ticket2 };

const Tickets: NavItemType = {
  id: 'Tickets',
  type: 'group',
  name: 'Tickets',
  icon: icons.Ticket2,
  children: [
    {
      icon: icons.Ticket2,
      id: 'tickets',
      title: <FormattedMessage id="Tickets" />,
      type: 'item',
      url: '/ticket'
    }
  ]
};

export default Tickets;

import { createSlice } from '@reduxjs/toolkit';
import StatusCode from '../../utils/StatusCode';
const initialState: { notes: Array<any>; announcement: Array<any>; faqs: Array<any>; status: any } = {
  notes: [],
  announcement: [],
  faqs: [],
  status: StatusCode.IDLE
};

const notes: any = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    setAllNotes(state: any, action: any) {
      action.payload.sort((a: any, b: any) => {
        if (a.commentOnTime && b.commentOnTime) {
          const timeA = a.commentOnTime.split(':');
          const timeB = b.commentOnTime.split(':');
          if (timeA[0] !== timeB[0]) {
            return timeA[0] - timeB[0];
          } else {
            return timeA[1] - timeB[1];
          }
        }
        return 0;
      });
      return { ...state, notes: action.payload };
    },
    updateNotes(state: any, action: any) {
      const arr = state.notes.filter((item: any) => item._id !== action.payload._id);
      state.notes = [...arr, action.payload];
    },
    deleteNote(state: any, action: any) {
      const arr = state.notes.filter((item: any) => item._id !== action.payload);
      state.notes = arr;
    },
    setNote(state: any, action: any) {
      const notesData = [...state.notes, action.payload];
      notesData.sort((a, b) => {
        if (a.commentOnTime && b.commentOnTime) {
          const timeA = a.commentOnTime.split(':');
          const timeB = b.commentOnTime.split(':');
          if (timeA[0] !== timeB[0]) {
            return timeA[0] - timeB[0];
          } else {
            return timeA[1] - timeB[1];
          }
        }
        return 0;
      });
      return { ...state, notes: notesData };
    },
    setAllAnnouncement(state: any, action: any) {
      state.announcement = action.payload;
    },
    setReduxAnnouncement(state: any, action: any) {
      const arr = state.announcement.map((item: any) => {
        if (item._id === action.payload.announcement._id) {
          return action.payload.announcement;
        }
        return item;
      });
      state.announcement = arr;
    },
    deleteAnnouncementComment(state: any, action: any) {
      const arr = state.announcement.map((item: any) => {
        if (item._id === action.payload.announcementId) {
          const comments = item.comments.filter((item: any) => item._id !== action.payload.commentId);
          item.comments = comments;
          return item;
        }
        return item;
      });
      state.announcement = arr;
    },
    updateAnnouncementComment(state: any, action: any) {
      const arr = state.announcement.map((item: any) => {
        if (item._id === action.payload.announcementId) {
          for (let i = 0; i <= item.comments.length; i++) {
            if (item.comments[i]._id === action.payload.commentId) {
              item.comments[i].comment = action.payload.comment;
              return item;
            }
          }
          return item;
        }
        return item;
      });
      state.announcement = arr;
    },
    setAllFAQ(state: any, action: any) {
      state.faqs = action.payload;
    },
    addFAQ(state: any, action: any) {
      const arr = [action.payload, ...state.faqs];
      state.faqs = arr;
    },
    updateFAQ(state: any, action: any) {
      const arr = state.faqs.filter((item: any) => item._id !== action.payload._id);
      state.faqs = [action.payload, ...arr];
    },
    deleteFAQ(state, action) {
      const arr = state.faqs.filter((item: any) => item._id !== action.payload);
      state.faqs = arr;
    }
  }
});

export const {
  setAllNotes,
  setNote,
  updateNotes,
  deleteNote,
  setAllAnnouncement,
  setReduxAnnouncement,
  deleteAnnouncementComment,
  updateAnnouncementComment,
  setAllFAQ,
  addFAQ,
  updateFAQ,
  deleteFAQ
} = notes.actions;
export default notes.reducer;

import { useMemo, useState } from 'react';

// material-ui
import { Theme } from '@mui/material/styles';
import { Box, useMediaQuery, Stack, Grid, TextField, OutlinedInput } from '@mui/material';

// project-imports
import Message from 'layout/MainLayout/Header/HeaderContent/Message';
import Profile from 'layout/MainLayout/Header/HeaderContent/Profile';
import Localization from 'layout/MainLayout/Header/HeaderContent/Localization';
import Notification from 'layout/MainLayout/Header/HeaderContent/Notification';
import MobileSection from 'layout/MainLayout/Header/HeaderContent/MobileSection';
// import MegaMenuSection from 'layout/MainLayout/Header/HeaderContent/MegaMenuSection';

import useConfig from 'hooks/useConfig';
import Logo from 'components/logo';
// type
// import { Logout } from 'iconsax-react';
import useAuth from 'hooks/useAuth';
import HeaderOption from './HeaderOption';
import Cart from 'layout/MainLayout/Header/HeaderContent/cart';
import WishList from 'layout/MainLayout/Header/HeaderContent/wishList';
import { SearchNormal1 } from 'iconsax-react';
import { useNavigate } from 'react-router-dom';
import { dispatch } from 'store';
import { setLernerSearch } from 'store/reducers/courseSlice';

// ==============================|| HEADER - CONTENT ||============================== //

const StudentHeaderContent = ({ hideDrawer, isStudent }: { hideDrawer: boolean; isStudent: boolean }) => {
  const { i18n } = useConfig();

  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down(1072));
  const downMD = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const localization = useMemo(() => <Localization />, [i18n]);

  const { user } = useAuth();
  const permission = user?.userTypeId?.permission;
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      dispatch(setLernerSearch(searchText));
      if (location.pathname !== '/learner/course' && searchText !== '') {
        navigate('/learner/course', { state: { searchText: searchText } });
      }
    }
  };

  return (
    <>
      <Grid container direction="column">
        <Grid item>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '#13AC9E',
              width: '100%',
              px: '16px'
            }}
          >
            <Box className="logo-welcome">
              {hideDrawer && <Logo to={'/learner'} />}
            </Box>
            {/* {!downLG && megaMenu} */}

            <Box sx={{ display: { lg: 'block', md: 'none', sm: 'none', xs: 'none' }, px: 2 }} className="search-box">
              <OutlinedInput
                startAdornment={<SearchNormal1 />}
                placeholder="Search course here..."
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  if (e.target.value === '') {
                    dispatch(setLernerSearch(e.target.value));
                  }
                }}
                onKeyPress={handleKeyPress}
                // onKeyPress={(event) => {
                //   if (event.key === 'Enter' && searchText.trim() !== '') {
                //     navigate('/learner/course', { state: searchText });
                //   }
                // }}
                sx={{
                  width: '480px',
                  height: '45px',
                  ml: 2,
                  borderRadius: '12px',
                  backgroundColor: 'white',
                  '& input::placeholder': {
                    color: '#9E9E9E'
                  }
                }}
              />
            </Box>
            <Stack display="flex" direction="row" alignItems="center" spacing={2} sx={{ ml: 2 }}>
              {localization}
              {!downLG && user?.type !== 'superadmin' && permission?.Courses?.View && <Message />}

              {!downLG && user?.type !== 'superadmin' && permission?.Courses?.View && <WishList />}
              {!downLG && permission?.UserCourses?.Purchase && user?.type !== 'superadmin' && <Cart />}
              {downLG && <div style={{ width: '100%', marginLeft: 1 }} />}
              <Notification />
              <Box className="profile-control" >
                <Profile />
              </Box>
              {downLG && <MobileSection isStudent={isStudent} />}
            </Stack>
          </Box>
        </Grid>
        <Grid item>
          <div style={{ display: 'flex', backgroundColor: '#FFF', flexDirection: 'column-reverse', alignItems: 'flex-end' }}>
            {!downLG && isStudent && <HeaderOption />}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default StudentHeaderContent;

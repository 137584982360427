import { FormattedMessage } from 'react-intl';

import { NavItemType } from 'types/menu';

import { Home3, HomeTrendUp, Box1 } from 'iconsax-react';

const icons = {
  navigation: Home3,
  dashboard: HomeTrendUp,
  components: Box1
};
export const generateDashboardMenu: (user: any) => NavItemType = (user) => {
  return {
    id: 'group-dashboard',
    type: 'group',
    name: 'Dashboard',
    icon: icons.dashboard,
    children: [
      {
        icon: icons.dashboard,
        id: 'dashboard',
        title: <FormattedMessage id='Dashboard' />, // replace with actual title or use FormattedMessage
        type: 'item',
        url: user?.type === 'faculty' ? '/faculty' : '/dashboard'
      }
    ]
  };
};

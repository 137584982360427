import axios from 'axios';

const API_URL = process.env.REACT_APP_BASEURL + 'classRoom';

export const addClassRoom = (reqData: Object) => {
  return axios.post(API_URL, reqData);
};

export const getAllClassRoom = (reqData: Object) => {
  return axios.get(API_URL + '/all', { params: reqData });
};

export const updateClassRoom = (reqData: Object) => {
  return axios.put(API_URL, reqData);
};
export const deleteClassRoom = (reqData: Object) => {
  return axios.delete(API_URL, { params: reqData });
};
export const statusChange = (ReqData: Object) => {
  return axios.patch(API_URL + "/activeStatus", ReqData);
};
import axios from 'axios';

const API_URL = process.env.REACT_APP_BASEURL;

export const getAllExams = (reqData: Object) => {
  return axios.get(API_URL + 'exam/all', { params: reqData });
};

export const AllExamsWithName = (reqData: Object) => {
  return axios.get(API_URL + 'exam/getAll', { params: reqData });
};

export const addExam = (reqData: Object) => {
  return axios.post(API_URL + 'exam/add', reqData);
};

export const updateExam = (reqData: Object) => {
  return axios.put(API_URL + 'exam/', reqData);
};

export const deleteExam = (reqData: Object) => {
  return axios.delete(API_URL + 'exam/', { params: reqData });
};

export const addExamSection = (reqData: Object) => {
  return axios.put(API_URL + 'exam/addSection', reqData);
};

export const getExamSingle = (reqData: Object) => {
  return axios.get(API_URL + 'exam', { params: reqData });
};
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  usertype: [],
  dashboard: '',
  pageIndex: 1,
  search: '',
  size: 10
};

const usertype = createSlice({
  name: 'usertype',
  initialState,
  reducers: {
    updateusertype(state, action) {
      state.usertype = action.payload;
    },
    setUserTypeSearch(state, action) {
      state.search = action.payload;
    },
    setusertypePageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    setusertypeTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    },
    delusertype(state, action) {
      return {
        ...state,
        usertype: state.usertype.filter((item: { _id: any }) => item._id !== action.payload)
      };
    },
    settypeDashboard(state, action) {
      return {
        ...state,
        dashboard: action.payload
      };
    },
    resetStateusertype: (state) => {
      return initialState;
    }
  }
});

export const {
  updateusertype,
  setUserTypeSearch,
  settypeDashboard,
  setusertypePageIndex,
  setusertypeTableSize,
  delusertype,
  resetStateusertype
} = usertype.actions;
export default usertype.reducer;

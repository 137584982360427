import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: []
};

const assignment = createSlice({
  name: 'assessmentreport',
  initialState,
  reducers: {
    setAssignment(state, action) {
      state.data = action.payload;
    },
    updateOneAssignment(state:any, action) {
      const existingIndex = state.data.findIndex((item: any) => item._id === action.payload._id);
      if (existingIndex !== -1) {
        state.data[existingIndex] = action.payload;
      } else {
        state.data = [...state.data, action.payload];
      }
    },
    removeAssignment(state: any, action) {
      state.data = state.data.filter((item: any) => item._id !== action.payload);
    }
  }
});

export const { setAssignment, updateOneAssignment, removeAssignment } = assignment.actions;
export default assignment.reducer;

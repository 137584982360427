import { Badge } from '@mui/base';
import { Tooltip, IconButton } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { Heart } from 'iconsax-react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router';

const WishList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user }: any = useAuth();
  return (
    <Tooltip title={<FormattedMessage id="Wishlist" />}>
      <IconButton
        sx={{
          width: user?.type === 'student' ? '32px' : '40px',
          height: user?.type === 'student' ? '32px' : '40px',
          border: user?.type === 'student' ? '1px solid white' : 'none',
          color: 'secondary.main',
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: user?.type === 'student' ? '#0f8a7e' : 'transparent'
          },
          p: 0.75
        }}
        onClick={() => {
          if (user?.type === 'student') {
            navigate('/learner/wishlist');
          } else {
            navigate('/course/wishlist');
          }
        }}
      >
        {/* <Badge color="success" style={{ '& .MuiBadge-badge': { top: 2, right: 4 } } as React.CSSProperties}> */}
          <Heart
            className="mainlogoicon"
            color={user?.type === 'student' ? 'white' : undefined}
            variant={location.pathname === '/learner/wishlist' || location.pathname === '/course/wishlist' ? 'Bulk' : 'Outline'}
          />
        {/* </Badge> */}
      </IconButton>
    </Tooltip>
  );
};

export default WishList;

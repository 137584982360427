// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Messages } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  icon1: Messages
};
// ==============================|| MENU ITEMS - CHARTS & MAPS ||============================== //

const chats: NavItemType = {
  id: 'group-chats',
  type: 'group',
  icon: icons.icon1,
  name: 'Chats',
  children: [
    {
      icon: icons.icon1,
      id: 'Chats',
      title: <FormattedMessage id="Chats" />,
      type: 'item',
      url: '/chat'
    }
  ]
};

export default chats;

import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  custompagebanners: [],
  custompagefeatures: [],
  custompagefaqs: [],
  custompagesocialmedia: {}
};

const custompage = createSlice({
  name: 'custompage',
  initialState,
  reducers: {
    updatecustompagebanners(state, action) {
      state.custompagebanners = action.payload;
    },
    addcustompagebanners(state, action) {
      return {
        ...state,
        custompagebanners: [...state.custompagebanners, action.payload]
      };
    },
    editcustompagebanners(state, action) {
      const { bannerId, ...updatedBannerData } = action.payload;
      const updatedBanner = { ...updatedBannerData, _id: bannerId };
      return {
        ...state,
        custompagebanners: state.custompagebanners.map((item: any) => (item._id === bannerId ? { ...item, ...updatedBanner } : item))
      };
    },
    delcustompagebanners(state, action) {
      return {
        ...state,
        custompagebanners: state.custompagebanners.filter((item: any) => item._id !== action.payload)
      };
    },
    editcustompagebannersstatus(state, action) {
      const { status, id } = action.payload;
      const index = state.custompagebanners.findIndex((item: any) => item._id === id);
      const updatedCustomPageBanners = [...state.custompagebanners];
      updatedCustomPageBanners[index] = { ...updatedCustomPageBanners[index], isActive:status };
      return {
        ...state,
        custompagebanners: updatedCustomPageBanners
      };
    },
    updatecustompagefaqs(state, action) {
      state.custompagefaqs = action.payload;
    },
    addcustompagefaqs(state, action) {
      return {
        ...state,
        custompagefaqs: [...state.custompagefaqs, action.payload]
      };
    },
    editcustompagefaqs(state, action) {
      const { faqOrgId, ...updatedFaqData } = action.payload;
      const updatedFaq = { ...updatedFaqData, _id: faqOrgId };
      return {
        ...state,
        custompagefaqs: state.custompagefaqs.map((item: any) => (item._id === faqOrgId ? { ...item, ...updatedFaq } : item))
      };
    },
    delcustompagefaqs(state, action) {
      return {
        ...state,
        custompagefaqs: state.custompagefaqs.filter((item: any) => item._id !== action.payload)
      };
    },
    updatecustompagesocialmedia(state, action) {
      state.custompagesocialmedia = action.payload;
    },
    updatecustompagefeatures(state, action) {
      state.custompagefeatures = action.payload;
    },
    addcustompagefeatures(state, action) {
      return {
        ...state,
        custompagefeatures: [...state.custompagefeatures, action.payload]
      };
    },
    editcustompagefeatures(state, action) {
      const { _id, ...updatedFeatureData } = action.payload;
      const updatedFeature = { ...updatedFeatureData, _id: _id };
      return {
        ...state,
        custompagefeatures: state.custompagefeatures.map((item: any) => (item._id === _id ? { ...item, ...updatedFeature } : item))
      };
    },
    delcustompagefeatures(state, action) {
      return {
        ...state,
        custompagefeatures: state.custompagefeatures.filter((item: any) => item._id !== action.payload)
      };
    }
  }
});

export const {
  updatecustompagebanners,
  addcustompagebanners,
  editcustompagebanners,
  delcustompagebanners,
  editcustompagebannersstatus,
  updatecustompagefeatures,
  updatecustompagefaqs,
  addcustompagefaqs,
  editcustompagefaqs,
  delcustompagefaqs,
  updatecustompagesocialmedia,
  addcustompagefeatures,
  editcustompagefeatures,
  delcustompagefeatures
} = custompage.actions;
export default custompage.reducer;

import axios from 'axios';

const API_URL = process.env.REACT_APP_BASEURL + 'learner';
// const API_URL1 = 'http://10.10.1.120:3000/api/learner';

export const AddCourseLimited = async (reqData: any) => {
  return axios.get(`${API_URL}/lastUpdatedCourse`, { params: reqData });
};

export const getFreeCourse = async (reqData: any) => {
  return axios.get(`${API_URL}/freeCourses`, { params: reqData });
};

export const AddAllCourses = async (userId: any) => {
  return axios.get(`${API_URL}/totalCount`, { params: { userId } });
};

export const getDashboardData = async (reqData: any) => {
  return axios.get(`${API_URL}/mainDashboard`, { params: reqData });
};

export const getTimeTableData = async (reqData: any) => {
  return axios.get(`${API_URL}/getTimeTable`, { params: reqData });
};

export const getAssignmentData = async (reqData: any) => {
  return axios.get(`${API_URL}/getAssignMents`, { params: reqData });
};

// export const getSingleCourse = (reqData: object) => {
//   return axios.get(`${API_URL}/getCourse`, { params: reqData });
// };

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Key, UserSquare } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  icon1: Key
};
// ==============================|| MENU ITEMS - CHARTS & MAPS ||============================== //

const access: NavItemType = {
  id: 'Access',
  type: 'group',
  name: 'UserTypes',
  icon: icons.icon1,
  children: [
    {
      icon: icons.icon1,
      id: 'Access',
      title: <FormattedMessage id="Access" />,
      type: 'item',
      url: '/permissions'
    }
  ]
};

export default access;

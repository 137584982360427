import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allorgrevenue: [],
  pageIndex: 1,
  size: 10
};

const allorgrevenue = createSlice({
  name: 'allorgrevenue',
  initialState,
  reducers: {
    updateallorgrevenue(state, { payload }) {
      state.allorgrevenue = payload;
    },
    setallorgrevenuePageIndex(state, { payload }) {
      return {
        ...state,
        pageIndex: payload
      };
    },
    setallorgrevenueTableSize(state, { payload }) {
      return {
        ...state,
        size: payload
      };
    },
    resetStateallorgrevenue: (state) => {
      return initialState;
    }
  }
});

export const { updateallorgrevenue, setallorgrevenuePageIndex, setallorgrevenueTableSize, resetStateallorgrevenue } = allorgrevenue.actions;
export default allorgrevenue.reducer;

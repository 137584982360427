import axios from 'axios';
const API_URL = process.env.REACT_APP_BASEURL;
export const getAllBadge = (reqData: Object) => {
  return axios.get(`${API_URL}badge/all`, { params: reqData });
};

export const getBadgeDetail = (reqData: Object) => {
  return axios.get(`${API_URL}user/userBadges`, { params: reqData });
};

export const getBadge = (reqData: Object) => {
  return axios.get(`${API_URL}analysis/getUserBadgesList`, { params: reqData });
};

export const setBadge = (reqData: Object) => {
  return axios.post(`${API_URL}badge/add`, reqData);
};

export const putBadge = (reqData: Object) => {
  return axios.put(`${API_URL}badge/`, reqData);
};

export const updateBadge = (reqData: Object) => {
  return axios.put(`${API_URL}badge/add`, reqData);
};

export const getSingleBadge = (reqData: Object) => {
  return axios.get(`${API_URL}badge/add`, reqData);
};

export const getBadgeTypeName = (reqData: Object) => {
  return axios.get(`${API_URL}badgeType/all`, { params: reqData });
};

export const getBadges = (reqData: Object) => {
  return axios.get(`${API_URL}badge/all`, { params: reqData });
};

export const deleteType = (reqData: object) => {
  return axios.delete(`${API_URL}badgeType`, { params: reqData });
};

export const addBadgeType = (reqData: object) => {
  return axios.post(`${API_URL}badgeType/add`, reqData);
};

export const deleteSingleBadge = (reqData: object) => {
  return axios.delete(`${API_URL}badge`, { params: reqData });
};

export const getLevels = (reqData: object) => {
  return axios.get(`${API_URL}badge/getUnlockAchievement`, { params: reqData });
};

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { People, AddCircle } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = { icon1: People, addIcon: AddCircle };

// ==============================|| MENU ITEMS - category ||============================== //

const group: NavItemType = {
  id: 'group-groups',
  //   title: <FormattedMessage id="group" />,
  type: 'group',
  icon: icons.icon1,
  name: 'Groups',
  children: [
    {
      icon: icons.icon1,
      id: 'group',
      title: <FormattedMessage id="Groups" />,
      type: 'item',
      url: '/group'
    }
  ]
};

export default group;

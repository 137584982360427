// material-ui

import { Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import Avatar from 'components/@extended/Avatar';
import { useSelector } from 'store';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  const theme = useTheme()
  const setting: any = useSelector((state) => state.organization)?.logo;
  // const img = `data:${setting?.mimetype};base64,${setting?.file}`;

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoIconDark : logoIcon} alt="icon logo" width="100" />
     *
     */
    <Typography variant="h5" className="mainlogo">
      <Avatar
        sx={{
          border: '1px solid',
              borderColor: theme.palette.primary.main,
              bgcolor: 'transparent',
          objectFit: 'contain'
        }}
        src={
          setting?.[0]?.path
            ? process.env.REACT_APP_FILE_URL + setting?.[0]?.path
            : `data:${setting?.[0]?.mimetype};base64,${setting?.[0]?.file}`
        }
      />
    </Typography>
  );
};

export default LogoIcon;

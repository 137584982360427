import { Typography, useTheme } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { EmojiSad } from 'iconsax-react';

export default function Data404(props: any) {
  const { organization, user } = useAuth();
  const theme = useTheme();
  const primaryColor = user?.type === 'student' ? "#13AC9E" : theme.palette.primary.main;

  const { title = 'Data not found', color = primaryColor, icon: CustomIcon } = props;

  return (
    <>
      <div
        style={{
          width: '100%',
          height: '100%',
          marginTop: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <>
        </>
        {CustomIcon ? (
          <CustomIcon size="100" color={color} />
        ) : (
          <EmojiSad size="100" color={color} />
        )}
        <Typography variant="h3" sx={{ mt: 1, textAlign: 'center' }}>
          {title}
        </Typography>
      </div>
    </>
  );
}

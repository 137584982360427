import { Button } from '@mui/material';
import { useEffect, useRef } from 'react';

const DummyButton = ({ handleLength, children }: { handleLength: any; children: any }) => {
  const buttonRef: any = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const isButtonVisible = entries[0].isIntersecting;
      if (isButtonVisible) {
        handleLength();
      }
    });

    observer.observe(buttonRef.current);

    return () => {
      observer.disconnect();
    };
  }, [handleLength]);

  return <Button ref={buttonRef}>{children}</Button>;
};

export default DummyButton;

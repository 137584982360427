import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  eventnotificationview: [],
  pageIndex: 1,
  size: 10,
  deleted: false,
  search: undefined
};

const eventnotificationview = createSlice({
  name: 'eventnotificationview',
  initialState,
  reducers: {
    updateeventnotificationview(state, action) {
      state.eventnotificationview = action.payload;
    },
    seteventnotificationviewPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    seteventnotificationviewstatus(state, action) {
      state.deleted = action.payload;
    },
    seteventnotificationviewSearch(state, action) {
      state.search = action.payload;
    },
    deleventnotificationview(state: any, action) {
      state.eventnotificationview.eventNotificationData = state.eventnotificationview.eventNotificationData.filter(
        (item: { _id: any }) => item._id !== action.payload
      );
    },
    seteventnotificationviewTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    },
    resetStateeventnotificationview: (state) => {
      return initialState;
    },
  }
});

export const {
  updateeventnotificationview,
  seteventnotificationviewSearch,
  seteventnotificationviewstatus,
  seteventnotificationviewPageIndex,
  deleventnotificationview,
  seteventnotificationviewTableSize,
  resetStateeventnotificationview
} = eventnotificationview.actions;
export default eventnotificationview.reducer;

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { UserSquare } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';
import useAuth from 'hooks/useAuth';

// icons
const icons = {
  icon1: UserSquare
};
// ==============================|| MENU ITEMS - CHARTS & MAPS ||============================== //

// const AdminUsers: NavItemType = {
//   id: 'group-users',
//   type: 'group',
//   icon: icons.icon1,
//   name: 'Users',
//   children: [
//     {
//       id: 'users',
//       title: <FormattedMessage id="Users" />,
//       type: 'collapse',
//       icon: icons.icon1,
//       // type: 'item',
//       // url: '/users'
//       children: [
//         {
//           id: 'Admin',
//           title: <FormattedMessage id="Admin List" />,
//           type: 'item',
//           url: '/users/admin'
//         },
//         {
//           id: 'faculty',
//           title: <FormattedMessage id="Faculty List" />,
//           type: 'item',
//           url: '/users/faculty'
//         },
//         {
//           id: 'Learner',
//           title: <FormattedMessage id="Learner List" />,
//           type: 'item',
//           url: '/users/learner'
//         },
//         {
//           id: 'Applied_Learner',
//           title: <FormattedMessage id="Applied Learner List" />,
//           type: 'item',
//           url: '/users/applied_Learner'
//         }
//       ]
//     }
//   ]
// };

const AdminUsers: NavItemType = {
  id: 'group-users',
  type: 'group',
  icon: icons.icon1,
  name: 'Users',
  children: [
    {
      id: 'users',
      title: <FormattedMessage id="Users" />,
      type: 'collapse',
      icon: icons.icon1,
      // type: 'item',
      // url: '/users'
      children: [
        {
          id: 'Admin',
          title: <FormattedMessage id="Admin List" />,
          type: 'item',
          url: '/users/admin'
        },
        {
          id: 'faculty',
          title: <FormattedMessage id="Faculty List" />,
          type: 'item',
          url: '/users/faculty'
        },
        {
          id: 'Learner',
          title: <FormattedMessage id="Learner List" />,
          type: 'item',
          url: '/users/learner'
        }
      ]
    }
  ]
};

const FacultyUsers: NavItemType = {
  id: 'group-users',
  type: 'group',
  icon: icons.icon1,
  name: 'Users',
  children: [
    {
      id: 'users',
      title: <FormattedMessage id="Users" />,
      type: 'collapse',
      icon: icons.icon1,
      // type: 'item',
      // url: '/users'
      children: [
        {
          id: 'Learner',
          title: <FormattedMessage id="Learner List" />,
          type: 'item',
          url: '/users/learner'
        }
      ]
    }
  ]
};

export default AdminUsers;
export { FacultyUsers};

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Notepad2 } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = { Notepad2 };

// ==============================|| MENU ITEMS - category ||============================== //

const Packages: NavItemType = {
  id: 'Packages',
  //   title: <FormattedMessage id="Add Organization" />,
  type: 'group',
  //   name: 'Add Organization',
  icon: icons.Notepad2,
  children: [
    {
      icon: icons.Notepad2,
      id: 'Packages',
      title: <FormattedMessage id="Package List" />,
      type: 'item',
      url: '/superadmin/package/list'
    }
  ]
};

export default Packages;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  pageIndex: 1,
  size: 10
};

const EmailTemplateSlice = createSlice({
  name: 'emailTemplate',
  initialState,
  reducers: {
    getEmailTemplates(state, action) {
      return {
        ...state,
        data: action.payload
      };
    },
    getEmailTemplatesPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    getEmailTemplatesTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    }
  }
});

export const { getEmailTemplates, getEmailTemplatesPageIndex, getEmailTemplatesTableSize } = EmailTemplateSlice.actions;

export default EmailTemplateSlice.reducer;
import axios from 'axios';

const API_URL = process.env.REACT_APP_BASEURL + 'usertype';

export const addUserType = async (reqData: any) => {
  return axios.post(`${API_URL}/add`, reqData);
};

export const updateUserType = async (reqData: any) => {
  return axios.put(API_URL, reqData);
};

export const deleteUserType = async (reqData: any) => {
  return axios.delete(API_URL, { params: reqData });
};

export const getAllUserType = async (reqData: any) => {
  return axios.get(`${API_URL}/all`, { params: reqData });
};
export const getUserType = async (reqData: any) => {
  return axios.get(API_URL, { params: reqData });
};

import { createSlice } from '@reduxjs/toolkit';

const initialState:any = {
  announcementForm: [],
  pageIndex: 1,
  size: 10,
  count: 0
};

const announcementForm = createSlice({
  name: 'announcementForm',
  initialState,
  reducers: {
    updateannouncementForm(state, { payload }) {
      state.announcementForm = payload;
    },
    deleteOneAnnouncement(state, action) {
      const arr = state.announcementForm.filter((item: any) => item._id !== action.payload._id);
      state.announcementForm = arr;
    },
    setCount(state, { payload }) {
      state.count = payload;
    },
    updateAnnouncement(state, action) {
      const arr = state.announcementForm.filter((item: any) => item._id !== action.payload._id);
      state.announcementForm = [action.payload, ...arr];
    },
    setannouncementFormPageIndex(state, { payload }) {
      return {
        ...state,
        pageIndex: payload
      };
    },
    addOneAnnouncement(state, action) {
      const arr: any = [action.payload, ...state.announcementForm];
      state.announcementForm = arr;
    },
    setannouncementFormTableSize(state, { payload }) {
      return {
        ...state,
        size: payload
      };
    }
  }
});

export const {
  updateannouncementForm,
  updateAnnouncement,
  addOneAnnouncement,
  deleteOneAnnouncement,
  setCount,
  setannouncementFormPageIndex,
  setannouncementFormTableSize
} = announcementForm.actions;
export default announcementForm.reducer;

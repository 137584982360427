// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { DirectboxSend } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = { DirectboxSend };

// ==============================|| MENU ITEMS - category ||============================== //

const AssignPackage: NavItemType = {
    id: 'AssignPackage',
    //   title: <FormattedMessage id="Add Organization" />,
    type: 'group',
    //   name: 'Add Organization',
    icon: icons.DirectboxSend,
    children: [
        {
            icon: icons.DirectboxSend,
            id: 'AssignPackage',
            title: <FormattedMessage id="Assign Package" />,
            type: 'item',
            url: '/superadmin/package/assign'
        }
    ]
};

export default AssignPackage;

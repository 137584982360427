import axios from 'axios';

const API_URL = process.env.REACT_APP_BASEURL + 'auth';
const API_URL2 = process.env.REACT_APP_BASEURL;


export const registerService = async (reqData: object) => {
  return await axios.post(API_URL + '/signup', reqData);
};

export const loginService = async (reqData: object) => {
  return await axios.post(API_URL + '/login', reqData);
};

export const logoutService = (reqData: object) => {
  return axios.post(API_URL + '/logout', reqData);
};

export const forgotPasswordService = (reqData: object) => {
  return axios.put(API_URL + '/forgotPassword', reqData);
};

export const verifyTokenService = (reqData: object) => {
  return axios.put(API_URL + '/verifyToken', reqData);
};

export const resetPasswordService = (reqData: object) => {
  return axios.put(API_URL + '/resetPassword', reqData);
};

export const registerStudentService = async (reqData: object) => {
  return await axios.post(API_URL + '/registerStudent', reqData);
};


export const getDemoVideo = (reqData:  object ) => {
  return axios.get(`${API_URL2}cloud/sign-url`, { params: reqData });
};

// export {
//   registerService,
//   loginService,
// };

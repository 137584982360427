import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  subscriptions: [],
  pageIndex: 1,
  size: 10
};

const subscriptions = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    updatesubscriptions(state, action) {
      state.subscriptions = action.payload;
    },
    setsubscriptionsPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    setsubscriptionsTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    }
  }
});

export const { updatesubscriptions, setsubscriptionsPageIndex, setsubscriptionsTableSize } = subscriptions.actions;
export default subscriptions.reducer;

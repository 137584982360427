// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Setting2 } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = { Setting2 };

// ==============================|| MENU ITEMS - category ||============================== //

const superAdminCMS: NavItemType = {
  id: 'superAdminCMS',
//   title: <FormattedMessage id="superAdminCMS" />,
  type: 'group',
  //   name: 'superAdminCMS',
  icon: icons.Setting2,
  children: [
    {
      icon: icons.Setting2,
      id: 'superAdminCMS',
      title: <FormattedMessage id="CMS" />,
      type: 'item',
      url: '/superadmin/contentmanagement'
    }
  ]
};

export default superAdminCMS;

import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Divider,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Switch
} from '@mui/material';
import MainCard from 'components/MainCard';
import useConfig from 'hooks/useConfig';
import {
  setsubjectlistPageIndex,
  setsubjectlistSearch,
  setsubjectlistTableSize,
  updatesubjectlist,
  setOrganizationTotalCount,
  resetStatesubjectlist,
  delsubjectlist,
  // setsubjectliststatus,
  setSwitchStatus
} from 'store/reducers/subjectlist';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { AddCircle, Bag, Book, Edit, SearchNormal1 } from 'iconsax-react';
import IconButton from 'components/@extended/IconButton';
import { dispatch } from 'store';
import { useSelector } from 'react-redux';
import Snackbar from 'utils/Snackbar';
import SkeletonTable from 'components/SkeletonTable';
import useAuth from 'hooks/useAuth';
import { FormattedMessage } from 'react-intl';
import CustomTable from 'components/@extended/CustomTable';
import { deleteSubject, getAllSubjects, statusChangeSubject } from 'services/subject.service';
import useContemp from 'hooks/useContemp';
import { formatDateBasedOnOrgSettings } from 'utils/helper';

const SubjectList = () => {
  const { user, organization }: any = useAuth();
  const subjectPermission = user?.userTypeId?.permission?.Subjects;

  const { i18n } = useConfig();
  const { pathname } = useLocation();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [value, setValue] = useState('');
  const pageIndex = useSelector((state: any) => state?.subjectlist)?.pageIndex;
  const size = useSelector((state: any) => state?.subjectlist)?.size;
  const search = useSelector((state: any) => state?.subjectlist)?.search;
  const subjectData = useSelector((state: any) => state?.subjectlist)?.subjectlist;
  const count = useSelector((state: any) => state?.subjectlist)?.count;
  const [isLoading, setLoading] = useState(subjectData && subjectData.length > 0 ? false : true);
  const [selectedFilter, setSelectedFilter] = useState(undefined);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [subjectId, setSubjectId] = useState<string | null>(null);
  const [disabled, setDisabled] = useState(false);
  const { setData, prevStoredData } = useContemp();

  const fetchContacts = async () => {
    setLoading(true);
    let reqData: any = {
      organizationId: organization?._id,
      limit: size,
      skip: pageIndex,
      type: selectedFilter
    };
    if (value) reqData.search = search;
    try {
      getAllSubjects(reqData)
        .then((result: any) => {
          // console.log(result, "result")
          setLoading(false);
          dispatch(updatesubjectlist(result?.data?.data?.data));
          dispatch(setOrganizationTotalCount(result?.data?.data?.count));
        })
        .catch((error) => {
          Snackbar(error.message, 'error');
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchContacts();
  }, [currentPage, pageSize, search, selectedFilter]);

  useEffect(() => {
    if (typeof prevStoredData?.[pathname]?.pageIndex === 'number') dispatch(setsubjectlistPageIndex(prevStoredData?.[pathname]?.pageIndex));
    if (typeof prevStoredData?.[pathname]?.size === 'number') dispatch(setsubjectlistTableSize(prevStoredData?.[pathname]?.size));
    return () => {
      dispatch(resetStatesubjectlist());
    };
  }, [dispatch]);

  deleteSubject;

  const navigate = useNavigate();
  function checkEnable(createdBy: String) {
    if (user?.type === 'admin') return false;
    if (user?.type === 'student') return true;
    if (user?.type === 'faculty') {
      if (user?._id !== createdBy) return true;
      if (user?._id === createdBy) return false;
    }
  }
  function checkPermission(createdBy: any, permission: any) {

    return permission ? (checkEnable(createdBy) ? true : false) : true;
  }
  const columns: any = useMemo(
    () => [
      {
        Header: 'Subject Name',
        accessor: 'subjectName'
      },
      {
        Header: 'Created At',
        Cell: ({ row }: any) => <div>{formatDateBasedOnOrgSettings(row.original.created_at, organization)}</div>
      },
      {
        Header: 'Status',
        Cell: ({ row }: any) => {
          const handleSubjectChange = async () => {
            const activeData = { _id: row.original._id };
            const response = await statusChangeSubject(activeData);
            if (response?.status === 200) {
              Snackbar('Status Changed Successfully.', 'success');
              dispatch(setSwitchStatus({ id: row.original._id, status: !row?.original?.active }));
            } else {
              Snackbar('Something went wrong', 'error');
            }
          };
          return (
            <Switch
              checked={row?.original?.active}
              onChange={handleSubjectChange}
              disabled={checkPermission(row?.original?.createdBy, subjectPermission?.Update)}
            />
          );
        }
      },
      {
        Header: 'Options',
        Cell: ({ row }: any) => {
          const navigate = useNavigate();
          return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0 }}>
              <Stack direction="row" spacing={0}>
                <Tooltip title="Edit">
                  <span>
                    <IconButton
                      color={'primary'}
                      disabled={checkPermission(row?.original?.createdBy, subjectPermission?.Update)}
                      onClick={(e) => {
                        if (row?.original?._id) {
                          setData({
                            [pathname]: { size, pageIndex, gotoUrl: '/subject/edit' }
                          });
                          navigate('/subject/edit', { state: { subject: row?.original } });
                        }
                      }}
                      sx={{ display: 'block' }}
                    >
                      <Edit size="32" />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Delete">
                  <span>
                    <IconButton
                      color={'error'}
                      disabled={checkPermission(row?.original?.createdBy, subjectPermission?.Delete)}
                      onClick={() => {
                        setSubjectId(row.original?._id);
                        setDialogOpen(true);
                      }}
                    >
                      <Bag size="32" />
                    </IconButton>
                  </span>
                </Tooltip>
              </Stack>
            </div>
          );
        }
      }
    ],
    []
  );
  const handleDialogClose = () => {
    setDialogOpen(false);
    setSubjectId(null);
  };

  const handleDelete = async () => {
    setDisabled(true);
    await deleteSubject({ subjectId })
      .then((result) => {
        if (result.status === 200) {
          dispatch(delsubjectlist(subjectId));
          Snackbar('Subject deleted successfully', 'success');
          handleDialogClose();
        } else {
          Snackbar(result.data.message, 'error');
        }
        setDisabled(false);
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
          Snackbar(errorMessage, 'warning');
        } else {
          const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
          Snackbar(errorMessage, 'error');
        }
      });
  };

  const handleChange = (event: SelectChangeEvent<number>) => {
    setPageSize(+event.target.value);
    setCurrentPage(1);
    dispatch(setsubjectlistPageIndex(1));
    dispatch(setsubjectlistTableSize(+event.target.value));
  };
  const handlePageChange = (event: any, page: number) => {
    setCurrentPage(page);
    dispatch(setsubjectlistPageIndex(page));
  };
  const setSearchOption = () => {
    if (pageIndex !== 1 && currentPage !== 1) {
      setCurrentPage(1);
    } else {
      dispatch(setsubjectlistSearch(value));
    }
    dispatch(setsubjectlistPageIndex(1));
  };
  return (
    <MainCard
      title={
        <span style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h5">
            <FormattedMessage id="All Subjects" />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage id="View the list of subjects along with their details. You can add new subjects, edit existing ones, and delete any as needed." />
          </Typography>
        </span>
      }
      content={false}
      secondary={
        <>
          <Tooltip title={<FormattedMessage id="Add Subject" />}>
            <span>
              <IconButton
                variant="dashed"
                color="primary"
                onClick={() => {
                  navigate('/subject/add');
                }}
              >
                <AddCircle />
              </IconButton>
            </span>
          </Tooltip>
        </>
      }
    >
      <Box>
        <Stack alignItems="center" direction="row-reverse" sx={{ py: 1, mr: 2 }}>
          <Stack>
            <Tooltip title={<FormattedMessage id="Search" />}>
              <span>
                <IconButton variant="outlined" color="primary" sx={{ ml: 0.5 }} onClick={setSearchOption} disabled={!value || value === ''}>
                  <SearchNormal1 />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
          <Stack>
            <TextField
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                if (e.target.value === '') {
                  dispatch(setsubjectlistSearch(undefined));
                  dispatch(setsubjectlistPageIndex(1));
                }
              }}
              placeholder={
                i18n === 'fr'
                  ? `recherche à partir de ${count || 0} enregistrements`
                  : i18n === 'ro'
                    ? `cauta din ${count || 0} inregistrari`
                    : i18n === 'zh'
                      ? `从 ${count || 0} 条记录中搜索`
                      : i18n === 'ar'
                        ? 'بحث في السجلات...'
                        : i18n === 'hindi'
                          ? '${count || 0}रिकॉर्ड खोजें...'
                          : `Search ${count || 0} records...`
              }
            />
          </Stack>
        </Stack>
        <Divider />
        {isLoading ? (
          <SkeletonTable columns={columns} />
        ) : (
          <CustomTable
            icon={Book}
            text={<FormattedMessage id="No Subjects Available, Add Subjects Now !! " />}
            link={'/subject/add'}
            notFound={true}
            columns={columns}
            data={subjectData?.length ? subjectData : []}
            sortArray={[]}
            sort={undefined}
            setSortingData={undefined}
            count={count}
            size={size}
            pageIndex={pageIndex}
            handlePageChange={handlePageChange}
            handleSizeChange={handleChange}
          />
        )}
      </Box>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ p: 1, pb: 1.2, textAlign: 'center' }}>
          <DialogTitle id="alert-dialog-title" sx={{ fontSize: '20px' }}>
            <b>Delete Subject</b>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Are you sure you want to delete this Subject ?</DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button variant="outlined" onClick={handleDialogClose}>
              Cancel
            </Button>
            <Button variant="contained" color="error" onClick={handleDelete} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </MainCard>
  );
};

export default SubjectList;

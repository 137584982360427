// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { LikeDislike } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = { icon1: LikeDislike };

// ==============================|| MENU ITEMS - category ||============================== //

const feedback: NavItemType = {
    id: 'group-feedback',
    //   title: <FormattedMessage id="feedback" />,
    type: 'group',
    name: 'Feedback',
    icon: icons.icon1,
    children: [
        {
            icon: icons.icon1,
            id: 'feedback',
            title: <FormattedMessage id="Feedback" />,
            type: 'item',
            url: '/feedback/feedbackTable'
        }
    ]
};

export default feedback;

import axios from 'axios';

const API_URL = process.env.REACT_APP_BASEURL + 'announcements';

export const addAnnouncement = (reqData: Object) => {
  return axios.post(API_URL + '/add', reqData);
};

export const getAllAnnouncements = (reqData: Object) => {
  return axios.get(API_URL + '/all', { params: reqData });
};
export const editAnnouncements = (reqData: Object) => {
  return axios.put(API_URL + '/', reqData);
};

export const deleteAnnouncement = (deleteReq: Object) => {
  return axios.delete(API_URL + '/', { params: deleteReq });
};
export const addComment = (reqData: Object) => {
  return axios.put(API_URL + '/addComment', reqData);
};
export const deleteComment = (reqData: Object) => {
  return axios.delete(API_URL + '/deleteComment', { params: reqData });
};

export const updateComment = (reqData: Object) => {
  return axios.put(API_URL + '/updateComment', { params: reqData });
};

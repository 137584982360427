import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { CloseCircle } from 'iconsax-react';
import PermissionDetection from 'pages/screenRecoder/PermissionDetection';
import { useState } from 'react';

const DemoExam = () => {
  const [openPermissions, setOpenPermissions] = useState(true);
  const handleClose = () => {
    setOpenPermissions(false);
  };
  return (
    <Dialog maxWidth={'sm'} fullWidth open={openPermissions}>
      <DialogTitle sx={{ fontSize: { xs: '12px', sm: '16px', md: '18px' } }}>
        Exam permission detection
        <IconButton
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            cursor: 'pointer',
            background: 'transparent',
            border: 'none'
          }}
          onClick={handleClose}
        >
          <CloseCircle />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ px: 1 }} dividers>
        <PermissionDetection />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={handleClose}>
          Close Exam
        </Button>
        <Button variant="contained" color="success" onClick={handleClose}>
          Start Exam
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DemoExam;

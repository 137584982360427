// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Buliding } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = { Buliding };

// ==============================|| MENU ITEMS - category ||============================== //

const Organization: NavItemType = {
  id: 'organization',
//   title: <FormattedMessage id="organization" />,
  type: 'group',
  //   name: 'organization',
  icon: icons.Buliding,
  children: [
    {
      icon: icons.Buliding,
      id: 'organization',
      title: <FormattedMessage id="Organization" />,
      type: 'item',
      url: '/superadmin/organization'
    }
  ]
};

export default Organization;

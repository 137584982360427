import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  contactlist: [],
  pageIndex: 1,
  deleted: false,
  count: 0,
  size: 10,
  search: undefined
};

const contactlist = createSlice({
  name: 'contactlist',
  initialState,
  reducers: {
    updatecontactlist(state, action) {
      state.contactlist = action.payload;
    },
    setcontactlistSearch(state, action) {
      state.search = action.payload;
    },
    setOrganizationTotalCount(state, action) {
      state.count = action.payload;
    },
    setcontactliststatus(state, action) {
      state.deleted = action.payload;
    },
    setcontactlistPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    setcontactlistTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    },
    editcontactliststatus(state: any, action) {
      const { status, id } = action.payload;
      const index = state.contactlist.data.findIndex((item: any) => item._id === id);
      const updatedCustomPageBanners: any = [...state.contactlist.data];
      updatedCustomPageBanners[index] = { ...updatedCustomPageBanners[index], status };
      return {
        ...state,
        contactlist: {
          ...state.contactlist,
          data: updatedCustomPageBanners
        }
      };
    },
    resetStateContactList: (state) => {
      return initialState;
    },
  }
});

export const {
  updatecontactlist,
  setcontactlistSearch,
  setOrganizationTotalCount,
  setcontactliststatus,
  setcontactlistPageIndex,
  setcontactlistTableSize,
  editcontactliststatus,
  resetStateContactList
} = contactlist.actions;
export default contactlist.reducer;

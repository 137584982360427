// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Notification } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = { icon1: Notification };

// ==============================|| MENU ITEMS - category ||============================== //

const notification: NavItemType = {
  id: 'group-notification',
  name: 'EventNotification',
  type: 'group',
  icon: icons.icon1,
  children: [
    {
      icon: icons.icon1,
      id: 'notification',
      title: <FormattedMessage id="Notification" />,
      type: 'item',
      url: '/notification'
    }
  ]
};

export default notification;

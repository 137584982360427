import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  superadminuser: [],
  pageIndex: 1,
  size: 10,
  search: undefined,
  count: 10
};

const superadminuser = createSlice({
  name: 'superadminuser',
  initialState,
  reducers: {
    updatesuperadminuser(state, action) {
      state.superadminuser = action.payload;
    },
    setsuperadminuserPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    setTotalCount(state, action) {
      state.count = action.payload;
    },
    setsuperadminuserSearch(state, action) {
      state.search = action.payload;
    },
    delsuperadminuser(state: any, action) {
      state.superadminuser.userList = state.superadminuser.userList.filter((item: { _id: any }) => item._id !== action.payload);
    },
    setsuperadminuserTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    },
    resetStatesuperadminuser: (state) => {
      return initialState;
    },
  }
});

export const {
  updatesuperadminuser,
  setTotalCount,
  setsuperadminuserSearch,
  setsuperadminuserPageIndex,
  setsuperadminuserTableSize,
  delsuperadminuser,
  resetStatesuperadminuser
} = superadminuser.actions;
export default superadminuser.reducer;

// store/reducers/offerSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  offers: [],
  pageIndex: 1,
  pageSize: 10,
  sortingData: [],
  search: ''
};

const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    addOffers: (state, action) => {
      state.offers.push(action.payload);
    },
    setOfferPageIndex(state, action) {
      state.pageIndex = action.payload;
    },
    setOfferPageSize(state, action) {
      state.pageSize = action.payload;
    },
    setOfferSortingValue(state, action) {
      state.sortingData = action.payload;
    },
    setOfferSearch(state, action) {
      state.search = action.payload;
    },
    editOffers: (state, action) => {
      const { offerId } = action.payload;
      return { offers: state.offers.filter((element: any) => element._id !== offerId) };
    },
    deleteOffers: (state, action) => {
      const offerId = action.payload;
      state.offers = state.offers.filter((offer: any) => offer?._id !== offerId);
    },
    setOffers: (state, action) => {
      state.offers = action.payload;
    },
    resetStateOffers: (state) => {
      return initialState;
    }
  }
});

export const {
  addOffers,
  setOfferSortingValue,
  setOfferPageIndex,
  setOfferPageSize,
  setOfferSearch,
  editOffers,
  deleteOffers,
  setOffers,
  resetStateOffers
} = offerSlice.actions;

export default offerSlice.reducer;

import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { useSelector } from 'react-redux';
import { dispatch } from 'store';
import useAuth from 'hooks/useAuth';
import { setexamSearch, setexamPageIndex, setexamTableSize, updateexam, delexam } from 'store/reducers/exam';
import { AddCircle, Edit, ReceiptEdit, SearchNormal1, Send, Task, Trash } from 'iconsax-react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { OutlinedInput } from '@mui/material';
import Snackbar from 'utils/Snackbar';
import MainCard from 'components/MainCard';
import SkeletonTable from 'components/SkeletonTable';
import { useNavigate } from 'react-router-dom';
import { deleteExam, getAllExams } from 'services/exam.service';
import CustomTable from 'components/@extended/CustomTable';
import IconButton from 'components/@extended/IconButton';
import { FormattedMessage } from 'react-intl';

const ExamTable = () => {
  const { user }: any = useAuth();
  const examPermission = user?.userTypeId?.permission?.Exam;
  const navigate = useNavigate();
  const [value, setValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [examIdToDelete, setExamIdToDelete] = useState<string | null>(null);
  const pageIndex = useSelector((state: any) => state?.exam)?.pageIndex;
  const size = useSelector((state: any) => state?.exam)?.size;
  const exam = useSelector((state: any) => state?.exam)?.exam?.data;
  const search = useSelector((state: any) => state?.exam)?.search;
  const [notFound, setNotFound] = useState(true);

  const openDialogBox = (id: string) => {
    setExamIdToDelete(id);
    setOpenDialog(true);
  };

  const dialogClose = () => {
    setOpenDialog(false);
  };

  const totalCount = useSelector((state: any) => state?.exam)?.exam?.count;

  const fetchexam = async () => {
    setIsLoading(true);
    let reqData: any = {
      orgId: user?.organizationId?._id,
      userId: user?._id,
      skip: pageIndex,
      limit: size
    };
    if (value) reqData.search = value;
    getAllExams(reqData)
      .then((result) => {
        setIsLoading(false);
        dispatch(updateexam(result?.data?.data));
        if (result?.data?.data?.length > 0) setNotFound(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err?.response?.status === 403) {
          const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
          Snackbar(errorMessage, 'warning');
        } else {
          const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
          Snackbar(errorMessage, 'error');
        }
      });
  };

  useEffect(() => {
    fetchexam();
    // eslint-disable-next-line
  }, [pageIndex, size, search]);

  const examDelete = () => {
    if (examIdToDelete) {
      deleteExam({ examId: examIdToDelete })
        .then((res: any) => {
          if (res.status === 200) {
            Snackbar('Exam Deleted Successfully!', 'success');
            dispatch(delexam(examIdToDelete));
          } else {
            Snackbar('There Was An Error Deleting This Exam!', 'error');
          }
        })
        .catch((err) => {
          if (err?.response?.status === 403) {
            const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
            Snackbar(errorMessage, 'warning');
          } else {
            const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
            Snackbar(errorMessage, 'error');
          }
        });
    }
    setOpenDialog(false);
  };

  const columns: any = useMemo(
    () => [
      {
        Header: 'Exam Name',
        accessor: 'examName'
      },
      {
        Header: 'Subject Name',
        accessor: 'subjectName',
        Cell: ({ row }: any) => <Typography>{row?.original?.subjectName?.subjectName}</Typography>
      },
      {
        Header: 'Total Marks',
        accessor: 'totalMarks'
      },
      {
        Header: 'Passing Marks',
        accessor: 'passingMarks'
      },
      {
        Header: 'Batch (Year)',
        accessor: 'batchYear'
      },
      // {
      //   Header: 'Class',
      //   accessor: 'className'
      // },
      // {
      //   Header: 'Division',
      //   accessor: 'divisionName'
      // },
      // {
      //   Header: 'Exam Start Date-Time',
      //   accessor: 'startDate',
      //   Cell: ({ value }: any) => moment(value).format('LLL')
      // },
      // {
      //   Header: 'Exam End Date-Time',
      //   accessor: 'endDate',
      //   Cell: ({ value }: any) => moment(value).format('LLL')
      // },
      {
        Header: 'Created By',
        Cell: ({ row }: any) => <>{`${row.original.createdBy.firstName} ${row.original.createdBy.lastName}`}</>
      },
      {
        Header: 'Options',
        accessor: 'progress',
        Cell: ({ row }: any) => (
          <>
            <Stack direction="row" spacing={0}>
              <Tooltip title="Assess">
                <span>
                  <IconButton
                    color={'warning'}
                    onClick={(e) => {
                      navigate('/exam/assess', {
                        state: { id: row.original._id }
                      });
                    }}
                  >
                    <ReceiptEdit />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Edit">
                <span>
                  <IconButton
                    color={'primary'}
                    disabled={!examPermission?.Update}
                    onClick={(e) => {
                      navigate('/exam/edit', {
                        state: { rowData: row.original }
                      });
                    }}
                  >
                    <Edit />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Add Sections">
                <span>
                  <IconButton
                    color={'info'}
                    disabled={!examPermission?.Update}
                    onClick={(e) => {
                      navigate('/exam/section', {
                        state: { id: row.original._id }
                      });
                    }}
                  >
                    <Send />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Delete">
                <span>
                  <IconButton
                    color={'error'}
                    disabled={!examPermission?.Delete}
                    onClick={() => {
                      openDialogBox(row?.original?._id);
                    }}
                  >
                    <Trash />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          </>
        )
      }
    ],
    // eslint-disable-next-line
    []
  );
  const handleChange = (event: SelectChangeEvent<number>) => {
    setCurrentPage(1);
    dispatch(setexamPageIndex(1));
    dispatch(setexamTableSize(+event.target.value));
  };
  const handlePageChange = (event: any, page: number) => {
    setCurrentPage(page);
    dispatch(setexamPageIndex(page));
  };
  const setSearchOption = () => {
    if (pageIndex !== 1 && currentPage !== 1) {
      setCurrentPage(1);
      dispatch(setexamPageIndex(1));
    } else {
      dispatch(setexamSearch(value));
    }
  };
  return (
    <>
      <Dialog open={openDialog} onClose={dialogClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <Box sx={{ p: 1, pb: 1.2, textAlign: 'center' }}>
          <DialogTitle id="alert-dialog-title" sx={{ fontSize: '20px' }}>
            <b>Delete Exam</b>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Are you sure you want to delete this Exam ?</DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button variant="outlined" onClick={dialogClose}>
              Cancel
            </Button>
            <Button variant="contained" color="error" onClick={examDelete} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <MainCard
        content={false}
        title={'Exam Table'}
        secondary={
          <>
            <Tooltip title="Add Exam" sx={{ mr: 2 }}>
              <span>
                <IconButton
                  variant="dashed"
                  color="primary"
                  disabled={!examPermission?.Create}
                  onClick={() => {
                    navigate('/exam/add');
                  }}
                >
                  <AddCircle />
                </IconButton>
              </span>
            </Tooltip>
          </>
        }
      >
        <DndProvider backend={HTML5Backend}>
          <Stack direction="row-reverse" spacing={1} sx={{ py: 1, mr: 2 }} alignItems="center">
            <Stack>
              <Tooltip title="Search">
                <span>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={!value || value === ''}
                    onClick={setSearchOption}
                    sx={{ p: '9px', minWidth: 'auto' }}
                  >
                    <SearchNormal1 />
                  </Button>
                </span>
              </Tooltip>
            </Stack>
            <Stack>
              <OutlinedInput
                value={value}
                onChange={(e: any) => {
                  setValue(e.target.value);
                  if (e.target.value === '') {
                    dispatch(setexamSearch(undefined));
                    dispatch(setexamPageIndex(1));
                  }
                }}
                placeholder={`Search ${totalCount || 0} records...`}
                id="start-adornment-email"
              />
            </Stack>
          </Stack>
          {isLoading ? (
            <SkeletonTable columns={columns} />
          ) : (
            <CustomTable
              icon={Task}
              text={<FormattedMessage id="No Exam available, Add Exam now !" />}
              link="/exam/add"
              columns={columns}
              data={exam || []}
              sortArray={[]}
              sort={undefined}
              setSortingData={undefined}
              notFound={notFound}
              search={search}
              count={totalCount}
              size={size}
              pageIndex={pageIndex}
              handlePageChange={handlePageChange}
              handleSizeChange={handleChange}
            />
          )}
        </DndProvider>
      </MainCard>
    </>
  );
};

export default ExamTable;

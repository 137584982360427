import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useQuill } from 'react-quilljs';
import BlotFormatter from 'quill-blot-formatter';
import 'quill/dist/quill.snow.css';

const CustomTextEditor = ({ index, body, handleChange }: any) => {
  const newsForEdit = '';
  //   const [contents, setContents] = useState<any>('');
  const { quill, quillRef, Quill } = useQuill({
    theme: 'snow',
    modules: {
      blotFormatter: {},
      toolbar: [
        [{ header: [1, 2, 3, 4, 5] }],
        ['bold', 'italic', 'underline'],
        [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ color: [] }, { background: [] }],
        ['image']
        // ['image', 'link'],
      ]
    }
  });
  if (Quill && !quill) {
    Quill.register('modules/blotFormatter', BlotFormatter);
  }
  useEffect(() => {
    if (quill) {
      quill.on('editor-change', (eventName: any, ...args: any) => {
        if (eventName === 'text-change' || eventName === 'selection-change') {
          const element: HTMLElement | null = document.querySelector(`.ql-editor-${index} .ql-editor`);
          const innerHTML = element ? element.innerHTML : '';
          handleChange(index, innerHTML === '<p></p>' || innerHTML === '<p><br></p>' ? '' : innerHTML);
        }
      });
      if (newsForEdit !== '') {
        const value = newsForEdit;
        const element: HTMLElement = document.querySelector(`.ql-editor-${index} .ql-editor`) as HTMLElement;
        element.innerHTML = '' + value;
      }
    }
    // eslint-disable-next-line
  }, [quill, Quill]);
  useEffect(() => {
    if (quill) {
      const formattedBody = body !== undefined ? body : '';
      const currentRange = quill.getSelection();
      if (formattedBody !== quill.root.innerHTML) {
        const delta = quill.clipboard.convert(formattedBody);
        quill.setContents(delta, 'silent');
      }
      if (currentRange) {
        quill.setSelection(currentRange.index, currentRange.length, 'silent');
      }
    }
    // eslint-disable-next-line
  }, [quill, body]);
  return (
    <Box sx={{ marginTop: '10px' }}>
      <Box className={`ql-editor-${index}`} ref={quillRef} />
    </Box>
  );
};
export default CustomTextEditor;

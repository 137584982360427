// material-ui
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';

// third-party
import { useDropzone } from 'react-dropzone';

// project-imports
import RejectionFiles from './RejectionFiles';

// assets
import { Camera } from 'iconsax-react';

// types
import { CustomFile, UploadProps } from 'types/dropzone';
import { useState } from 'react';

const RootWrapper = styled('div')(({ theme }) => ({
  width: 124,
  height: 124,
  borderRadius: '50%',
  border: `1px dashed ${theme.palette.primary.main}`,
  background: theme.palette.primary.lighter
}));

const DropzoneWrapper = styled('div')({
  zIndex: 0,
  width: '100%',
  height: '100%',
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  borderRadius: '50%',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  '& > *': { width: '100%', height: '100%' },
  '&:hover': {
    cursor: 'pointer',
    '& .placeholder': {
      zIndex: 9
    }
  }
});

const PlaceholderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.secondary,
  backgroundColor: alpha(theme.palette.primary.lighter, 0.75),
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&:hover': { opacity: 0.85 }
}));

// ==============================|| UPLOAD - AVATAR ||============================== //

const AvatarUpload = ({ error, file, setFieldValue, name, sx, ...other }: UploadProps) => {
  const [image, setimage] = useState<any>(null);
  const theme = useTheme();

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    accept: {
      'image/*': []
    },
    multiple: false,
    onDrop: (acceptedFiles: CustomFile[]) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = (reader.result as string).split(',')[1];
        // Type assertion
        // setimage({ ...image, name: acceptedFiles[0].name, type: acceptedFiles[0].type, image: base64Data });
        setimage(reader.result);
        // setimage(acceptedFiles[0]);
        if (name) {
          setFieldValue(name, acceptedFiles[0]);
        } else {
          setFieldValue('files', acceptedFiles[0]);
        }
      };
      reader.readAsDataURL(acceptedFiles[0]);
      return image;
    }
  });

  const thumbs = image ? (
    // file?.length > 0 &&
    // file?.map((item: CustomFile) => (
    <img
      // key={image?.name}
      alt={'image'}
      src={image}
      // onLoad={() => {
      //   URL.revokeObjectURL(image?.image!);
      // }}
    />
  ) : (
    // ))
    <>
      {file == undefined ? (
        <Typography textAlign={'center'} mt={3}>
          Upload
        </Typography>
      ) : (
        <img
          src={file}
          alt="update"
          // onLoad={() => {
          //   URL.revokeObjectURL(`data:${file?.mimetype};base64,${file?.file}`);
          // }}
        />
      )}
    </>
  );

  return (
    <>
      <input type="hidden" accept="image/jpeg, image/png, image/gif" id="base64image" value={JSON.stringify(image)} />
      <RootWrapper
        sx={{
          ...((isDragReject || error) && {
            borderColor: 'error.light'
          }),
          ...sx
        }}
      >
        <DropzoneWrapper {...getRootProps()} sx={{ ...(isDragActive && { opacity: 0.6 }) }}>
          <input {...getInputProps()} />
          {thumbs}
          <PlaceholderWrapper
            className="placeholder"
            sx={{
              ...(thumbs && {
                opacity: 0,
                color: 'common.white',
                bgcolor: 'secondary.darker'
              }),
              ...((isDragReject || error) && {
                bgcolor: 'error.lighter'
              })
            }}
          >
            <Stack spacing={0.5} alignItems="center">
              <Camera style={{ color: theme.palette.secondary.main, fontSize: '2rem' }} />
              <Typography color="secondary">{file ? 'Update' : 'Upload'}</Typography>
            </Stack>
          </PlaceholderWrapper>
        </DropzoneWrapper>
      </RootWrapper>
      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}
    </>
  );
};

export default AvatarUpload;

import { useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { AddCircle, Bag, DocumentText1, Edit, ExportCurve, SearchNormal1 } from 'iconsax-react';
import IconButton from 'components/@extended/IconButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { dispatch } from 'store';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { OutlinedInput } from '@mui/material';
import Snackbar from 'utils/Snackbar';
import MainCard from 'components/MainCard';
import SkeletonTable from 'components/SkeletonTable';
import Filters from 'pages/superAdmin/Filters';
import useAuth from 'hooks/useAuth';
import { deleteQuestions, getAllQuestions, importQuiz } from 'services/quiz.service';
import {
  delquestionbank,
  delquestionbankchecked,
  setquestionbankPageIndex,
  setquestionbankTableSize,
  setquestionbanksort,
  updatequestionbank,
  updatequestionbankchecked,
  updatequestionbankcount,
  updatequestionbankcreatedby,
  updatequestionbankcreatedbysearch,
  updatequestionbanksearch
} from 'store/reducers/questionbank';
import Avatar from 'components/@extended/Avatar';
import CustomTable from 'components/@extended/CustomTable';
import { delSectionQuestionDataDetails, resetExamState, updateSectionQuestionDetails } from 'store/reducers/exam';
import { FormattedMessage } from 'react-intl';
import useContemp from 'hooks/useContemp';
import { addPreferences, editQuestion } from 'store/reducers/addQuestionsBank';

const QuestionBank = ({ title, createdBys, section, usesFor }: any) => {
  const navigate = useNavigate();
  const { user }: any = useAuth();
  const { pathname } = useLocation();
  let reqData = { organizationId: user?.organizationId?._id };
  const [value, setValue] = useState();
  // const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const QuestionPermissions = user?.userTypeId?.permission?.Questions;
  const pageIndex = useSelector((state: any) => state?.questionbank)?.pageIndex;
  const sortData = useSelector((state: any) => state?.questionbank)?.questionbankSort;
  const size = useSelector((state: any) => state?.questionbank)?.size;
  const questionBank = useSelector((state: any) => state?.questionbank)?.questionbank;
  const totalCount = useSelector((state: any) => state?.questionbank)?.count;
  const createdBy = useSelector((state: any) => state?.questionbank)?.questionCreatedBy;
  const questionsearch = useSelector((state: any) => state?.questionbank)?.questionsearch;
  const questionCreatedBy = useSelector((state: any) => state?.questionbank)?.createdbysearch;
  const [questionToDelete, setQuestionToDelete] = useState(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [notFound, setNotFound] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState(undefined);
  const { setData, prevStoredData } = useContemp();

  // const [questionsCount, setQuestionsCount] = useState(0);
  const [fileError, setFileError] = useState('');
  const handleSelectFilter = (filter: any) => {
    setSelectedFilter(filter);
    dispatch(setquestionbankPageIndex(1));
  };
  const options = createdBy;
  const openDeleteDialog = (questionId: any) => {
    setQuestionToDelete(questionId);
    setDeleteDialogOpen(true);
  };
  function checkPermission(createdBy: any, permission: any) {
    return permission ? (checkEnable(createdBy) ? true : false) : true;
  }
  function checkEnable(createdBy: String) {
    if (user?.type === 'admin') return false;
    if (user?.type === 'student') return true;
    if (user?.type === 'faculty') {
      if (user?._id !== createdBy) return true;
      if (user?._id === createdBy) return false;
    }
  }
  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setQuestionToDelete(null);
  };
  const deleteQuestionsReq = (questionId: any) => {
    deleteQuestions({ questionId: questionId }).then((res) => {
      if (res.status === 200) {
        Snackbar('Question deleted successfully!', 'success');
        dispatch(delquestionbank(questionId));
        dispatch(updatequestionbankcount(totalCount - 1));
      } else {
        Snackbar('There was an error deleting this question!', 'error');
      }
    });
  };
  useEffect(() => {
    if (typeof prevStoredData?.[pathname]?.pageIndex === 'number')
      dispatch(setquestionbankPageIndex(prevStoredData?.[pathname]?.pageIndex));
    if (typeof prevStoredData?.[pathname]?.size === 'number') dispatch(setquestionbankTableSize(prevStoredData?.[pathname]?.size));
    return () => {
      dispatch(resetExamState());
    };
  }, []);
  const setSortingData = (data: any) => {
    const index1 = sortData && sortData.findIndex((item: any) => item.id === data.name);
    let arr = sortData ? sortData : [];
    if (sortData?.[index1]?.sort === true && sortData?.[index1]?.desc === true) {
      let arrCopy = [...sortData];
      arrCopy.splice(index1, 1);
      dispatch(setquestionbanksort(arrCopy));
    } else if (sortData?.[index1]?.sort === true) {
      let data = arr[index1];
      const newData = { ...data, desc: true };
      const newArr = [...arr];
      newArr[index1] = newData;
      dispatch(setquestionbanksort(newArr));
    } else {
      arr = [...arr, { id: data.name, sort: true, desc: false }];
      dispatch(setquestionbanksort(arr));
    }
  };
  const fetchcoursereport = async (signal?: any) => {
    setIsLoading(true);
    let sort: any = {};
    sortData &&
      sortData.length > 0 &&
      sortData.forEach((val: any) => {
        if (val.sort && val.desc) {
          sort[val.id] = -1;
        } else if (val.sort) {
          sort[val.id] = 1;
        } else {
          delete sort[val.id];
        }
      });
    let request: any = {
      organizationId: reqData?.organizationId,
      skip: pageIndex,
      limit: size,
      sort: sort,
      isExam: usesFor === 'exam' || !usesFor
    };
    if (questionsearch) request.search = questionsearch;
    if (createdBys || questionCreatedBy) request.createdBy = createdBys ? createdBys : questionCreatedBy;
    if (selectedFilter) request.type = selectedFilter;
    getAllQuestions(request, signal)
      .then((data: any) => {
        setIsLoading(false);
        if (data?.status === 200) {
          dispatch(updatequestionbank(data?.data?.data?.questionsData));
          dispatch(updatequestionbankcount(data?.data?.data?.count));
          dispatch(updatequestionbankcreatedby(data?.data?.data?.createdBy));
          if (data?.data?.data?.questionsData?.length > 0) setNotFound(false);
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setIsButtonDisabled(true);
      if (file.name.endsWith('.csv')) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const base64Data = e.target.result.split(',')[1];
          const mimetype = file.type;
          let reqBody = {
            file: base64Data,
            type: mimetype,
            name: file.name
          };
          importQuiz(reqBody)
            .then((res: any) => {
              if (res?.data?.status === 200) {
                Snackbar(res?.data?.message, 'success');
                closeDialog();
                setIsButtonDisabled(false);
              } else {
                Snackbar('CSV import failed. Please try again later.', 'error');
                setIsButtonDisabled(false);
              }
            })
            .catch((err) => {
              setIsButtonDisabled(false);
              if (err?.response?.status === 403) {
                const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
                Snackbar(errorMessage, 'warning');
              } else {
                const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
                Snackbar(errorMessage, 'error');
              }
            });
        };
        reader.readAsDataURL(file);
        setFileError('');
      } else {
        setFileError('Please select a valid CSV file.');
        Snackbar('Please select a valid CSV file.', 'error');
      }
    }
  };
  useEffect(() => {
    const localDataSetString: any = localStorage.getItem('AssessmentData');
    const localDataSet = localDataSetString && localDataSetString?.userId === user?._id ? JSON.parse(localDataSetString) : {};
    if (localDataSet?.userId && localDataSet?.userId === user?._id) {
      dispatch(updatequestionbankchecked(localDataSet.assessmentData));
    }
    // setQuestionsCount(localDataSet?.assessmentData?.length);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    fetchcoursereport(signal);
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, [pageIndex, size, sortData, questionCreatedBy, createdBys, questionsearch, selectedFilter]);
  const selectionColumn = {
    Header: 'Select',
    Cell: ({ row }: { row: any }) => {
      const checked = useSelector((state: any) => state?.questionbank)?.checked;
      const [change, setChange] = useState('');
      if (change === 'Yes') {
        localStorage.setItem('AssessmentData', JSON.stringify({ userId: user?._id, assessmentData: checked }));
        // setQuestionsCount(checked.length);
      }
      const isChecked = checked && checked.some((item: any) => item?._id === row?.original?._id);
      return (
        <Tooltip title="Select For Assessment">
          <span>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Checkbox
                checked={isChecked}
                onClick={() => {
                  setChange('Yes');
                  isChecked
                    ? dispatch(delquestionbankchecked(row?.original?._id))
                    : dispatch(
                        updatequestionbankchecked([
                          ...checked,
                          {
                            _id: row?.original?._id,
                            question: row?.original?.question,
                            type: row?.original?.type
                          }
                        ])
                      );
                  section >= 0 && isChecked
                    ? dispatch(delSectionQuestionDataDetails(row?.original?._id))
                    : dispatch(
                        updateSectionQuestionDetails({
                          index: section,
                          question: {
                            questionId: row?.original?._id,
                            question: row?.original?.question,
                            type: row?.original?.type
                          }
                        })
                      );
                }}
              />
            </div>
          </span>
        </Tooltip>
      );
    }
  };
  const columnData = [
    {
      Header: 'Question',
      accessor: 'question'
    },
    {
      Header: 'Type',
      Cell: ({ row }: { row: any }) => {
        let name = row.original.type;
        const getTypeColor = (type: any) => {
          switch (type) {
            case 'mcq':
              return 'warning';
            case 'fillInTheBlanks':
              name = 'FillUps';
              return 'info';
            case 'simple':
              name = 'Text';
              return 'primary';
            case 'code':
              return 'success';
            default:
              return 'secondary';
          }
        };
        const color = getTypeColor(name);
        return <Chip label={name} color={color} sx={{ textTransform: 'uppercase' }} />;
      }
    }
  ];
  const avatarColumn = {
    Header: 'CreatedBy',
    Cell: ({ row }: { row: any }) => (
      <Tooltip title={`${row?.original?.createdBy?.firstName} ${row?.original?.createdBy?.lastName}`}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Avatar
            alt={row?.original?.createdBy?.firstName}
            size="sm"
            src={
              row?.original?.createdBy?.userProfilePicId?.path
                ? process.env.REACT_APP_FILE_URL + row?.original?.createdBy?.userProfilePicId?.path
                : row?.original?.createdBy?.userProfilePicId?.mimetype && row?.original?.createdBy?.userProfilePicId?.file
                ? `data:${row?.original?.createdBy?.userProfilePicId?.mimetype};base64,${row?.original?.createdBy?.userProfilePicId?.file}`
                : ''
            }
          />
        </div>
      </Tooltip>
    )
  };
  const optionColumn = {
    Header: 'Options',
    Cell: ({ row }: any) => {
      const navigate = useNavigate();
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0 }}>
          <Stack direction="row" spacing={0}>
            <Tooltip title="Edit">
              <span>
                <IconButton
                  color={'primary'}
                  disabled={checkPermission(row?.original?.createdBy?._id, QuestionPermissions?.Update)}
                  onClick={(e) => {
                    if (row?.original?._id) {
                      setData({
                        [pathname]: { size, pageIndex, gotoUrl: '/quiz/questions/edit' }
                      });
                      dispatch(
                        addPreferences({
                          subject: row?.original?.subject,
                          classType: row?.original?.classType,
                          classRoomId: row?.original?.classType === 'class' ? row?.original?.classRoomId?._id : undefined,
                          semester: row?.original?.classType === 'semester' ? row?.original?.classRoomId?._id : undefined,
                          streamName: row?.original?.classType === 'semester' ? row?.original?.classRoomId?._id : undefined,
                          otherName: row?.original?.classType === 'other' ? row?.original?.classRoomId?._id : undefined,
                          count: 1,
                          options: row?.original?.option?.length || 0,
                          type: row?.original?.type,
                          multiOptions: row?.original?.multiOptions,
                          optionType: row?.original?.optionType,
                          selectedLanguage: row?.original?.selectedLanguage,
                          levels: row?.original?.levels,
                          classes: row?.original?.classes
                        })
                      );
                      dispatch(editQuestion(true));
                      navigate('/quiz/questions/edit', {
                        state: {
                          questionId: row?.original?._id
                        }
                      });
                    }
                  }}
                  sx={{ display: 'block' }}
                >
                  <Edit size="32" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete">
              <span>
                <IconButton
                  color={'error'}
                  disabled={checkPermission(row?.original?.createdBy?._id, QuestionPermissions?.Delete)}
                  onClick={() => {
                    openDeleteDialog(row.original?._id);
                  }}
                >
                  <Bag size="32" />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </div>
      );
    }
  };
  if (!title) {
    if (!createdBys) {
      columnData.push(avatarColumn);
    }
    columnData.push(optionColumn);
  } else {
    if (!createdBys) {
      columnData.push(avatarColumn);
    }
    columnData.unshift(selectionColumn);
  }
  const columns = useMemo(
    () => columnData,
    // eslint-disable-next-line
    []
  );
  const handleChange = (event: SelectChangeEvent<number>) => {
    dispatch(setquestionbankPageIndex(1));
    dispatch(setquestionbankTableSize(+event.target.value));
  };
  const handlePageChange = (event: any, page: number) => {
    dispatch(setquestionbankPageIndex(page));
  };
  const setSearchOption = () => {
    if (pageIndex !== 1) {
      dispatch(setquestionbankPageIndex(1));
    }
    dispatch(updatequestionbanksearch(value));
  };
  const openDialog = () => {
    setDialogOpen(true);
  };
  const closeDialog = () => {
    setDialogOpen(false);
  };

  const [selectedValues, setSelectedValues] = useState<any>([]);

  const handleAutocompleteChange = (event: any, value: any) => {
    // Ensure that each value is unique
    const uniqueValues = [...new Set(value.map((data: any) => data?._id))];
    setSelectedValues(uniqueValues);

    // Your other logic here
    const _id = value.map((data: any) => data?._id).join(',');
    // setUsers(_id);
    dispatch(updatequestionbankcreatedbysearch(_id));
    if (_id.length === 0) {
      dispatch(updatequestionbankcreatedbysearch(undefined));
    }
    dispatch(setquestionbankPageIndex(1));
  };

  return (
    <MainCard
      // title={title ? String(title) : 'Question Bank'}
      title={
        <span style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h5">
            <FormattedMessage id={title ? String(title) : 'Question Bank'} />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage id="List of questions" />
          </Typography>
        </span>
      }
      content={false}
      sx={{ textTransform: 'capitalize' }}
      secondary={
        <Stack direction={'row-reverse'} spacing={2}>
          <Stack>
            {!title && (
              <Tooltip title="Add Questions">
                <span>
                  <IconButton
                    disabled={!QuestionPermissions?.Create}
                    variant="dashed"
                    color="primary"
                    onClick={() => {
                      navigate('/quiz/questions/add');
                      dispatch(editQuestion(false));
                    }}
                  >
                    <AddCircle />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </Stack>
          <Stack>
            {!title && (
              <Tooltip title="Upload CSV">
                <span>
                  <Button variant="outlined" color="primary" onClick={openDialog} sx={{ p: '9px', minWidth: 'auto' }}>
                    <ExportCurve />
                  </Button>
                </span>
              </Tooltip>
            )}
          </Stack>{' '}
          <Stack sx={{ alignItems: 'center' }}>
            <Filters
              Filters={usesFor === 'exam' || !usesFor ? ['mcq', 'fillintheblanks', 'simple', 'code'] : ['mcq', 'fillintheblanks']}
              Fields={
                usesFor === 'exam' || !usesFor ? ['mcq based', 'fillup based', 'text based', 'code based'] : ['mcq based', 'fillup based']
              }
              handleSelect={handleSelectFilter}
            />
          </Stack>
        </Stack>
      }
    >
      <Dialog
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ p: 1, pb: 1.2, textAlign: 'center' }}>
          <DialogTitle id="alert-dialog-title" sx={{ fontSize: '20px' }}>
            <b>Delete Question</b>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Are you sure you want to delete this Question ?</DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button variant="outlined" onClick={closeDeleteDialog}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                deleteQuestionsReq(questionToDelete);
                closeDeleteDialog();
              }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogTitle textAlign={'center'}>Upload CSV File</DialogTitle>
        <Divider />
        <DialogContent>
          <Box sx={{ overflow: 'hidden' }}>
            <Box>
              <strong>Instruction</strong>
            </Box>
            <Box
              sx={{
                overflowY: 'auto',
                maxHeight: '300px',
                overflow: 'auto',
                position: 'relative',
                scrollbarWidth: 'thin',
                scrollbarColor: 'transparent transparent',
                '&::-webkit-scrollbar': { width: '4px', borderRadius: '3px', marginRight: '8px' },
                '&::-webkit-scrollbar-track': { background: 'transparent' },
                '&::-webkit-scrollbar-thumb': {
                  background: 'transparent',
                  transition: 'background-color 0.3s ease-in-out',
                  borderRadius: '3px'
                },
                '&::-webkit-scrollbar-thumb:hover': { background: '#888' },
                '&::-webkit-scrollbar-corner': { backgroundColor: 'transparent' },
                '&:hover::-webkit-scrollbar-thumb': { background: '#888' }
              }}
            >
              <ol>
                <li>
                  <strong>Add Questions by uploading CSV file from here.</strong>
                </li>
                <li>
                  <strong>The file must be in .csv format.</strong>
                </li>
                <li>
                  <strong>The file will be uploaded immediately after selecting it.</strong>
                </li>
                <li>
                  <strong>Require fields</strong>
                  <ul>
                    <li>question</li>
                    <li>type</li>
                    <li>option</li>
                    <li>selectedLanguage</li>
                    <li>optionType</li>
                    <li>answerText</li>
                    <li>correctAnswer</li>
                  </ul>
                </li>
              </ol>
            </Box>
          </Box>
          {fileError && (
            <Typography variant="body2" color="error">
              {fileError}
            </Typography>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={closeDialog} variant="outlined" color="error" style={{ marginRight: '5px' }}>
            Cancel
          </Button>
          <input
            type="file"
            id="csv-file-input"
            name="originalname"
            accept=".csv"
            style={{ display: 'none', marginRight: '5px' }}
            onChange={handleFileChange}
            disabled={isButtonDisabled}
          />
          <label htmlFor="csv-file-input">
            <Button variant="contained" component="span" color="primary" disabled={isButtonDisabled}>
              Choose File
            </Button>
          </label>
        </DialogActions>
      </Dialog>

      <DndProvider backend={HTML5Backend}>
        <Stack
          direction={{
            xs: 'column-reverse',
            sm: 'row-reverse',
            md: 'row-reverse',
            lg: 'row-reverse'
          }}
          spacing={1}
          alignItems="center"
          sx={{ py: 1, flexWrap: 'wrap', mr: { sm: '8px', md: '8px', lg: '8px' } }}
        >
          <Stack>
            <Tooltip title="Search">
              <span>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={setSearchOption}
                  sx={{ p: '9px', minWidth: 'auto' }}
                  disabled={!value || value === ''}
                >
                  <SearchNormal1 />
                </Button>
              </span>
            </Tooltip>
          </Stack>
          <Stack>
            <OutlinedInput
              value={value}
              onChange={(e: any) => {
                setValue(e.target.value);
                if (e.target.value === '') {
                  dispatch(updatequestionbanksearch(undefined));
                  dispatch(setquestionbankPageIndex(1));
                }
              }}
              placeholder={`Search ${totalCount || 0} records...`}
              id="start-adornment-email"
            />
          </Stack>
          <Stack sx={{ alignItems: 'center' }}>
            <Autocomplete
              multiple
              options={options}
              getOptionLabel={(option) => option?._id}
              disableCloseOnSelect
              renderOption={(props, option) => (
                <li {...props}>
                  {option?.firstName} {option?.lastName}
                </li>
              )}
              renderInput={(params: any) => <TextField {...params} placeholder="Search Added By.." sx={{ width: 180 }} />}
              onChange={handleAutocompleteChange}
              sx={{ '& .MuiOutlinedInput-root': { p: 0.8 }, ml: 1 }}
              fullWidth
              value={options.filter((option: any) => selectedValues.includes(option._id))}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    avatar={
                      <Avatar
                        alt={option?.firstName}
                        src={
                          option?.userProfilePicId?.path
                            ? process.env.REACT_APP_FILE_URL + option?.userProfilePicId?.path
                            : option?.userProfilePicId?.mimetype && option?.userProfilePicId?.file
                            ? `data:${option?.userProfilePicId?.mimetype};base64,${option?.userProfilePicId?.file}`
                            : ''
                        }
                      />
                    }
                    label={option?.firstName}
                    {...getTagProps({ index })}
                  />
                ))
              }
            />
          </Stack>
        </Stack>
        {isLoading ? (
          <SkeletonTable columns={columns} />
        ) : (
          <CustomTable
            icon={DocumentText1}
            sortArray={['question']}
            text={<FormattedMessage id="No Questions available, Add Question now !" />}
            link="/quiz/questions/add"
            columns={columns}
            data={questionBank || []}
            setSortingData={setSortingData}
            sort={sortData}
            notFound={notFound}
            search={questionsearch || createdBys || questionCreatedBy || selectedFilter}
            count={totalCount}
            size={size}
            pageIndex={pageIndex}
            handlePageChange={handlePageChange}
            handleSizeChange={handleChange}
          />
        )}
      </DndProvider>
    </MainCard>
  );
};

export default QuestionBank;

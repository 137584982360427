// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Messages1 } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = { icon1: Messages1 };

// ==============================|| MENU ITEMS - DISCUSSIONS ||============================== //
const discussion: NavItemType = {
  id: 'discussion',
  icon: icons.icon1,
  name: 'Discussions',
  type: 'group',
  children: [
    {
      id: 'discussion',
      title: <FormattedMessage id="Discussions" />,
      type: 'item',
      url: '/discussion',
      icon: icons.icon1
    }
  ]
};

export default discussion;
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  timeTable: [],
  pageIndex: 1,
  size: 10,
  totalCount: 0,
  deleted: false,
  search: undefined,
  timeTableSorting: []
};

const timeTable = createSlice({
  name: 'time-table',
  initialState,
  reducers: {
    updateTimeTable(state, action) {
      state.timeTable = action.payload;
    },
    setTimeTablePageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    setTimeTableSearch(state, action) {
      state.search = action.payload;
    },
    setTimeTableTotalCount(state, action) {
      state.totalCount = action.payload;
    },
    setTimeTableSorting(state, action) {
      state.timeTableSorting = action.payload;
    },
    delTimeTable(state: any, action) {
      state.timeTable = state.timeTable.filter((item: { _id: any }) => item._id !== action.payload);
    },
    completeChecking(state: any, action) {
      let checkingIndex = state.timeTable.findIndex((item: any) => item._id === action.payload);
      if (checkingIndex !== -1) {
        state.timeTable[checkingIndex].completeChecking = true;
      }
    },
    setTimeTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    },
    resetTimeTable: (state) => {
      return initialState;
    },
  }
});

export const {
  updateTimeTable,
  setTimeTablePageIndex,
  setTimeTableSearch,
  setTimeTableSize,
  setTimeTableTotalCount,
  setTimeTableSorting,
  delTimeTable,
  completeChecking,
  resetTimeTable,
} = timeTable.actions;
export default timeTable.reducer;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  users: [],
  sortingData: [],
  pageIndex: 1,
  count: 0,
  type: '',
  size: 10,
  search: ''
};

const superadmin = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setSortingValue(state, action) {
      state.sortingData = action.payload;
    },
    setUsersType(state, action) {
      state.type = action.payload;
    },
    getUsersDetails(state, action) {
      return {
        ...state,
        users: action.payload
      };
    },
    setUserPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    setOrganizationPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    setUserCount(state, action) {
      return {
        ...state,
        count: action.payload
      };
    },
    setUserTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    },
    setUserTableSearch(state, action) {
      return {
        ...state,
        search: action.payload
      };
    }
  }
});

export const { getUsersDetails, setUserPageIndex, setUserCount, setSortingValue, setUserTableSize, setUserTableSearch, setUsersType } =
  superadmin.actions;
export default superadmin.reducer;

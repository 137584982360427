import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sections: [],
  exam: [],
  pageIndex: 1,
  size: 10,
  deleted: false,
  search: undefined,
  examSorting: []
};

const exam = createSlice({
  name: 'exam',
  initialState,
  reducers: {
    updateexam(state, action) {
      state.exam = action.payload;
    },
    updatesections(state, action) {
      state.sections = action.payload;
    },
    updateSectionDetails(state: any, action) {
      const newSection = { title: action.payload.title, totalMark: 0, questionDetails: [] };
      state.sections = [...state.sections, newSection];
    },
    updateSectionQuestionDetails(state: any, action) {
      const { index, question } = action.payload;
      const totalMark = state.sections[index]?.totalMark || 1;
      question.mark = totalMark;
      state?.sections?.[index]?.questionDetails?.push(question);
    },
    delSectionQuestionDataDetails(state: any, action) {
      state.sections.forEach((section: any) => {
        const indexInOtherSection = section.questionDetails.findIndex((qd: any) => qd.questionId === action.payload);
        if (indexInOtherSection !== -1) {
          section.questionDetails.splice(indexInOtherSection, 1);
        }
      });
    },
    updateSectionData(state: any, action) {
      const { index, title, mark } = action.payload;
      if (state.sections[index]) {
        state.sections[index].title = title;
        state.sections[index].totalMark = mark;
        state.sections[index].questionDetails.forEach((question: any) => {
          question.mark = mark === 0 ? 1 : mark;
        });
      }
    },
    updateQuestionMarkDetails(state: any, action) {
      const { index, questionsIndex, mark } = action.payload;
      state.sections[index].questionDetails[questionsIndex].mark = mark;
    },
    setexamPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    deleteSectionData(state: any, action) {
      state.sections.splice(action.payload, 1);
    },
    deleteQuestionData(state: any, action) {
      const { index, _id } = action.payload;
      state.sections[index].questionDetails = state.sections[index].questionDetails.filter(
        (item: { questionId: any }) => item.questionId !== _id
      );
    },
    setexamstatus(state, action) {
      state.deleted = action.payload;
    },
    setexamSearch(state, action) {
      state.search = action.payload;
    },
    setexamSorting(state, action) {
      state.examSorting = action.payload;
    },
    delexam(state: any, action) {
      state.exam.data = state.exam.data.filter((item: { _id: any }) => item._id !== action.payload);
    },
    setexamTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    },
    resetExamState: (state) => {
       return {
        ...initialState, 
        sections: state.sections 
      };
    },
  }
});

export const {
  updateexam,
  updatesections,
  updateSectionDetails,
  updateSectionQuestionDetails,
  updateQuestionMarkDetails,
  delSectionQuestionDataDetails,
  deleteSectionData,
  deleteQuestionData,
  updateSectionData,
  setexamSearch,
  setexamstatus,
  setexamSorting,
  setexamPageIndex,
  delexam,
  setexamTableSize,
  resetExamState,
} = exam.actions;
export default exam.reducer;

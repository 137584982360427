import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  subjectlist: [],
  pageIndex: 1,
  deleted: false,
  count: 0,
  size: 10,
  search: undefined
};

const subjectlist = createSlice({
  name: 'subjectlist',
  initialState,
  reducers: {
    updatesubjectlist(state, action) {
      state.subjectlist = action.payload;
    },
    setsubjectlistSearch(state, action) {
      state.search = action.payload;
    },
    setOrganizationTotalCount(state, action) {
      state.count = action.payload;
    },
    setsubjectliststatus(state, action) {
      state.deleted = action.payload;
    },
    setsubjectlistPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    setsubjectlistTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    },
    editsubjectliststatus(state: any, action) {
      const { status, id } = action.payload;
      const index = state.subjectlist.data.findIndex((item: any) => item._id === id);
      const updatedCustomPageBanners: any = [...state.subjectlist.data];
      updatedCustomPageBanners[index] = { ...updatedCustomPageBanners[index], status };
      return {
        ...state,
        subjectlist: {
          ...state.subjectlist,
          data: updatedCustomPageBanners
        }
      };
    },
    resetStatesubjectlist: (state) => {
      return initialState;
    },
    delsubjectlist(state, action) {
      const arr = state.subjectlist.filter((item: { _id: any }) => item._id !== action.payload);
      return {
        ...state,
        subjectlist: arr
      };
    },
    setSwitchStatus(state: any, action) {
      const { id, status } = action.payload;

      const updatedList = state.subjectlist.map((item: { _id: any; status?: any }) => {
        if (item._id === id) {
          return {
            ...item,
            active: status
          };
        }
        return item;
      });

      return {
        ...state,
        subjectlist: updatedList
      };
    }
  }
});

export const {
  updatesubjectlist,
  setsubjectlistSearch,
  setOrganizationTotalCount,
  setsubjectliststatus,
  setsubjectlistPageIndex,
  setsubjectlistTableSize,
  editsubjectliststatus,
  resetStatesubjectlist,
  delsubjectlist,
  setSwitchStatus
} = subjectlist.actions;
export default subjectlist.reducer;

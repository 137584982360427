import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  questionsList: [],
  sectionId: '',
  currentIndex: 0
};

const examattend = createSlice({
  name: 'examattend',
  initialState,
  reducers: {
    clearExam(state) {
      state.questionsList = [];
      state.sectionId = '';
      state.currentIndex = 0;
    },
    examQuestionsList(state, action) {
      let questionsData = action.payload || [];
      let count = 0;
      state.questionsList = []?.concat(
        ...questionsData.map((section: any) =>
          section?.questionDetails.map((question: any, index: any) => {
            // eslint-disable-next-line
            const { _id: questionId, correctAnswer, answerText, ...rest }: any = question.questionId;
            return {
              sectionIndex: index,
              questionIndex: count++,
              sectionId: section._id,
              title: section.title,
              questionId,
              ...rest,
              mark: question?.mark || 0
            };
          })
        )
      );
      state.sectionId = state.questionsList[0]?.sectionId;
    },
    findQuestionIndex(state, action) {
      const { sectionId, questionId } = action.payload;
      state.questionsList.map((question: any, index: any) => {
        if (question.sectionId === sectionId && question.questionId === questionId) {
          state.currentIndex = index;
          state.sectionId = sectionId;
        }
        return null;
      });
    },
    AddAnswerSingle(state, action) {
      const { index, value } = action.payload;
      state.questionsList[index].correctAnswer = [value];
    },
    AddAnswerMultiple(state, action) {
      const { index, value } = action.payload;
      const question = state.questionsList[index];
      if (!question.hasOwnProperty('correctAnswer') || !Array.isArray(question.correctAnswer)) {
        question.correctAnswer = [];
      }
      const valueIndex = question.correctAnswer.indexOf(value);
      if (valueIndex !== -1) {
        question.correctAnswer.splice(valueIndex, 1);
      } else {
        question.correctAnswer.push(value);
      }
    },
    AddFillUps(state, action) {
      const { index, optionIndex, value } = action.payload;
      const question = state.questionsList[index];
      if (!question.hasOwnProperty('correctAnswer') || !Array.isArray(question.correctAnswer)) {
        question.correctAnswer = [];
      }
      state.questionsList[index].correctAnswer[optionIndex] = value;
    },
    AddAnswerText(state, action) {
      const { index, value } = action.payload;
      state.questionsList[index].answerText = value;
    },
    increaseQuestionIndex(state) {
      state.currentIndex += 1;
      state.sectionId = state.questionsList[state.currentIndex]?.sectionId;
    },
    decreaseQuestionIndex(state) {
      state.currentIndex -= 1;
      state.sectionId = state.questionsList[state.currentIndex]?.sectionId;
    },
    resetQuestionIndex(state, action) {
      const question = state.questionsList[action.payload];
      if (question) {
        delete question.correctAnswer;
        delete question.answerText;
      }
    },
    AnswerSubmitted(state, action) {
      const { index, status } = action.payload;
      state.questionsList[index].submit = status;
    },
    markAsReview(state, action) {
      const { index, status } = action.payload;
      state.questionsList[index].review = status;
    }
  }
});

export const {
  clearExam,
  examQuestionsList,
  findQuestionIndex,
  AddAnswerSingle,
  AddAnswerMultiple,
  AddFillUps,
  AddAnswerText,
  increaseQuestionIndex,
  decreaseQuestionIndex,
  resetQuestionIndex,
  AnswerSubmitted,
  markAsReview
} = examattend.actions;
export default examattend.reducer;

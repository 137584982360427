import { Button, Typography, Grid, useTheme } from '@mui/material';
import { ArchiveBook } from 'iconsax-react';
import { Link } from 'react-router-dom';
// import { useNavigate } from 'react-router';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';

interface NotFoundProps {
  icon: React.ComponentType<any> | null;
  text: string | any;
  link: string | null;
  categoryData?: Array<any>;
  add?: boolean;
  color?: string

}
function NotFound({ icon: IconComponent, text, link, categoryData }: NotFoundProps) {
  // const navigate = useNavigate();
  const { organization, user } = useAuth();
  const [canAdd, setCanAdd] = useState(link ? true : false);
  const theme = useTheme();
  const primaryColor = user?.type === 'student' ? "#13AC9E" : theme.palette.primary.main;

  useEffect(() => {
    const areConditionsMet =
      categoryData?.length !== 0 &&
      organization?.tags?.length !== 0 &&
      organization?.language?.length !== 0 &&
      organization?.whatWeLearn?.length !== 0;

    if (areConditionsMet) {
      // Do something if all conditions are met
      setCanAdd(true);
    }
  }, [categoryData, organization]);
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      textAlign="center"
      alignItems="center"
      style={{ height: '100%', paddingTop: '50px', paddingBottom: '50px' }}
    >
      <Grid item xs={12}>
        {IconComponent && <IconComponent size="200" color={primaryColor} variant="Bold" />}
      </Grid>
      <Grid item xs={12} margin={1}>
        <Typography sx={{ fontWeight: 'bold' }}>{text}</Typography>
        {link && canAdd ? (
          <Link to={user?.type === 'student' ? `/learner${link}` : link}>
            <>
              {
                console.log("In Link")
              }</>
            <Button sx={{ margin: 1, px: 3, backgroundColor: primaryColor, pointerEvents: 'none' }} variant="contained">
              + Add
            </Button>
          </Link>
        ) : null}
        {link === '/course/add' && IconComponent === ArchiveBook ? (
          <div>
            {!canAdd ? <Typography>*The Below fields Require to Add new Course !!</Typography> : null}
            {categoryData?.length === 0 && (
              <Link to="/category/add" state={{}}>
                <Button sx={{ margin: 1, px: 3, backgroundColor: primaryColor, pointerEvents: 'none' }} variant="contained">
                  + Add Category
                </Button>
              </Link>
            )}
            {organization?.tags.length === 0 && (
              <Link to="/settings/accountSettings" state={{ tab: 2, from: 'tags' }}>
                <Button sx={{ margin: 1, px: 3, backgroundColor: primaryColor, pointerEvents: 'none' }} variant="contained">
                  + Add Custom Tag
                </Button>
              </Link>
            )}
            {organization?.language.length === 0 && (
              <Link to="/settings/accountSettings" state={{ tab: 3, from: 'categorys' }}>
                <Button sx={{ margin: 1, px: 3, backgroundColor: primaryColor, pointerEvents: 'none' }} variant="contained">
                  + Add Language
                </Button>
              </Link>
            )}
            {organization?.whatWeLearn.length === 0 && (
              <Link to="/settings/accountSettings" state={{ tab: 2, from: 'learn' }}>
                <Button sx={{ margin: 1, px: 3, backgroundColor: primaryColor, pointerEvents: 'none' }} variant="contained">
                  + Add Custom Learn
                </Button>
              </Link>
            )}
          </div>
        ) : null}
      </Grid>
    </Grid>
  );
}

export default NotFound;

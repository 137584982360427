import { ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import BackToggle from 'components/third-party/dropzone/BackToggle';
import LandingPageTable from './LandingPageTable';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

// ==============================|| TABS - BASIC ||============================== //

const LandingPageTabs = (type: any) => {
  const location = useLocation();

  const types = ['banner', 'whyUs', 'features', 'tools', 'experience', 'faq', 'useCase', 'ourClients'];

  const [value, setValue] = useState(0);
  console.log('Tabb Value', value);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleTabChangeFromChild = (newValue: any) => {
    const index = types.indexOf(newValue);
    setValue(index);
    console.log('Tab changed to index:', index);
  };

  return (
    <MainCard
      title={
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <BackToggle />
          <span style={{ display: 'flex', flexDirection: 'column', marginLeft: '8px' }}>
            <Typography variant="h5">
              <FormattedMessage id="Landing Page Content Management" />
            </Typography>
          </span>
        </div>
      }
      content={false}
    >
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable">
            {types.map((type, index) => (
              <Tab key={index} label={type} iconPosition="end" {...a11yProps(index)} />
            ))}
          </Tabs>
        </Box>
        {types.map((type, index) => (
          <TabPanel key={index} value={value} index={index}>
            <LandingPageTable data={types[value]} onTabChange={handleTabChangeFromChild} />
          </TabPanel>
        ))}
      </Box>
    </MainCard>
  );
};

export default LandingPageTabs;

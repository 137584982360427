import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  blogs: [],
  ViewBlogs: [],
  search: '',
  pageIndex: 1,
  pageSize: 10
};

const blogSlice = createSlice({
  name: 'blogs',
  initialState,
  reducers: {
    getBlogs(state, action) {
      return {
        ...state,
        blogs: action.payload
      };
    },
    setBlogPageIndex(state, action) {
      state.pageIndex = action.payload;
    },
    setBlogPageSize(state, action) {
      state.pageSize = action.payload;
    },
    setBlogSearch(state, action) {
      state.search = action.payload;
    },
    setBlogStatus(state: any, action: any) {
      const blogData = state.blogs.map((blog: any) => {
        if (blog._id === action.payload) return { ...blog, isPublished: !blog.isPublished };
        return blog;
      });
      state.blogs = blogData;
    },
    delBlogs(state, action) {
      return {
        ...state,
        blogs: state.blogs.filter((item: { _id: any }) => item._id !== action.payload)
      };
    },
    setViewBlogs(state, action) {
      return {
        ...state,
        ViewBlogs: action.payload
      };
    },
    resetStateBlogs: (state) => {
      return initialState;
    }
  }
});

export const { getBlogs, setBlogPageIndex, setBlogPageSize, setBlogSearch, setBlogStatus, delBlogs, setViewBlogs, resetStateBlogs } =
  blogSlice.actions;

export default blogSlice.reducer;

import { useEffect, useState } from 'react';

// material-ui
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Typography,
  List,
  FormGroup,
  Divider,
  Tooltip,
  OutlinedInput,
  Stack,
  FormControlLabel,
  Checkbox,
  Radio,
  IconButton,
  Chip,
  InputLabel,
  TextField,
  InputAdornment
} from '@mui/material';
import { Calendar, CloseCircle, FilterAdd, SearchNormal1, TagCross } from 'iconsax-react';
import useConfig from 'hooks/useConfig';
import { FormattedMessage } from 'react-intl';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import useAuth from 'hooks/useAuth';
// ==============================|| DIALOG - SIZES ||============================== //
interface FilterProps {
  type: { val1: Array<string>; val2: string };
  // data?: Array<{ label: string; value: boolean }>;
  title: string;
  count?: number;
  setFilterKey: any;
  value: any;
  handleClose: any;
  selectedEndDate?: any;
  selectedStartDate?: any;
}

const FilterComponent = ({ type, title, count, setFilterKey, value, handleClose, selectedStartDate, selectedEndDate }: FilterProps) => {
  const { i18n } = useConfig();
  // console.log(value);
  const [search, setSearch] = useState('');
  const [range, setRange] = useState(selectedStartDate || selectedEndDate ? true : false);

  function capitalizeFirstLetter(str: any) {
    return str
      .split(' ')
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  const [startDate, setStartDate] = useState(selectedStartDate);
  const [endDate, setEndDate] = useState(selectedEndDate);

  // Handler for date change
  const handleDateChange = (date: any, type: any) => {
    if (type === 'start') {
      setStartDate(date);
    } else {
      setEndDate(date);
      // handleClose();
    }
  };

  useEffect(() => {
    setFilterKey('endDate', endDate);
    setFilterKey('startDate', startDate);
  }, [endDate, startDate]);

  return (
    <>
      <Divider sx={{ my: 2 }} />
      <Typography variant="h5">{capitalizeFirstLetter(title)}</Typography>
      {typeof type === 'string' && (
        <Stack direction="row" spacing={1} sx={{ py: 1, mr: 2, justifyContent: 'left', alignItems: 'center' }} alignItems="center">
          <Stack>
            <OutlinedInput
              // value={value || search || ''}
              value={search || value || ''}
              onChange={(e) => {
                setSearch(e.target.value);
                if (e.target.value === '') {
                  setFilterKey('search', '');
                }
              }}
              placeholder={
                i18n === 'fr'
                  ? `recherche à partir de ${count} enregistrements`
                  : i18n === 'ro'
                  ? `cauta din ${count} inregistrari`
                  : i18n === 'zh'
                  ? `从 ${count} 条记录中搜索`
                  : i18n === 'ar'
                  ? 'بحث في السجلات...'
                  : i18n === 'hindi'
                  ? `${count} रिकॉर्ड खोजें...`
                  : `Search ${count} records...`
              }
              id="start-adornment-email"
            />
          </Stack>
          <Stack>
            <Tooltip title={<FormattedMessage id="Search" />}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  if (search) {
                    setFilterKey('search', search);
                    handleClose();
                  }
                }}
                sx={{ p: '9px', minWidth: 'auto' }}
              >
                <SearchNormal1 />
              </Button>
            </Tooltip>
          </Stack>
          {range ? (
            <></>
          ) : (
            <Stack>
              <Tooltip title={<FormattedMessage id="Select Range" />}>
                <Button variant="outlined" color="primary" onClick={() => setRange(true)} sx={{ p: '9px', minWidth: 'auto' }}>
                  <Calendar />
                </Button>
              </Tooltip>
            </Stack>
          )}
        </Stack>
      )}

      {typeof type === 'string' && (
        <>
          {range ? (
            <>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h5">Range</Typography>
              <Stack direction={'row'} spacing={1} mt={1}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack>
                    <InputLabel htmlFor="cal-startDate-date">Start Date</InputLabel>
                    <MobileDatePicker
                      value={startDate}
                      inputFormat="dd/MM/yyyy"
                      onChange={(date) => handleDateChange(date, 'start')}
                      // disabled={disabled}
                      closeOnSelect={true}
                      // maxDate={endDate}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          id="cal-startDate-date"
                          placeholder="Start date"
                          fullWidth
                          // error={Boolean(touched.startDate && errors.startDate)}
                          // helperText={touched.startDate && (errors.startDate as String)}

                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Calendar />
                              </InputAdornment>
                            )
                          }}
                        />
                      )}
                    />
                  </Stack>
                  <Stack>
                    <InputLabel htmlFor="cal-endDate-date">End Date</InputLabel>
                    <MobileDatePicker
                      value={endDate}
                      inputFormat="dd/MM/yyyy"
                      onChange={(date) => handleDateChange(date, 'end')}
                      closeOnSelect={true}
                      minDate={startDate}
                      disabled={!startDate}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          id="cal-endDate-date"
                          placeholder="End date"
                          fullWidth
                          // error={Boolean(formik.touched.endDate && formik.errors.endDate)}
                          // helperText={formik.touched.endDate && (formik.errors.endDate as String)}

                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Calendar />
                              </InputAdornment>
                            )
                          }}
                        />
                      )}
                    />
                  </Stack>

                  <Stack>
                    <IconButton
                      sx={{ mt: 2.5 }}
                      onClick={() => {
                        setRange(false);
                        setStartDate(null);
                        setEndDate(null);
                        setFilterKey('startDate', null);
                        setFilterKey('endDate', null);
                      }}
                      size="large"
                      color="error"
                    >
                      <TagCross size={44} />
                    </IconButton>
                  </Stack>
                </LocalizationProvider>
              </Stack>
            </>
          ) : (
            <></>
          )}
        </>
      )}

      <List disablePadding component="nav" aria-label="main mailbox folders" sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {type &&
          typeof type === 'object' &&
          Array.isArray(type?.val1) &&
          type?.val1 &&
          type?.val1?.length > 0 &&
          type?.val1.map((item: any, index) =>
            typeof item === 'object' ? (
              <Box key={index}>
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      key={item?._id}
                      name={title}
                      value={JSON.stringify(item)}
                      control={type?.val2 === 'checkbox' ? <Checkbox /> : <Radio />}
                      label={capitalizeFirstLetter(item?.className?.toString())}
                      sx={{ ml: 1 }}
                      checked={type?.val2 === 'checkbox' ? value?.includes(item) : value?._id === item?._id}
                      onChange={(e: any) => {
                        setFilterKey(title, JSON.parse(e.target.value));
                      }}
                    />
                  </FormGroup>
                </FormControl>
              </Box>
            ) : (
              <Box key={index}>
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      key={item}
                      name={title}
                      value={item}
                      control={type?.val2 === 'checkbox' ? <Checkbox /> : <Radio />}
                      label={capitalizeFirstLetter(item)}
                      sx={{ ml: 1 }}
                      checked={type?.val2 === 'checkbox' ? value?.includes(item) : value === item}
                      onChange={(e: any) => {
                        setFilterKey(title, e.target.value);
                      }}
                    />
                  </FormGroup>
                </FormControl>
              </Box>
            )
          )}
      </List>
    </>
  );
};

export default function ModalFilter({
  filterKeywords,
  onFilterKeywordChange,
  values,
  count
}: {
  filterKeywords?: any;
  onFilterKeywordChange: any;
  values: any;
  count: number;
}) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { user } = useAuth();
  return (
    <>
      <Box sx={{ mr: 2, ml: 2, justifyContent: 'center', display: 'flex' }}>
        {/* <Tooltip title={<FormattedMessage id="Filters" />}> */}
          <Chip
            color="primary"
            variant={user?.type === 'student' ? 'filled' : 'light'}
            icon={<FilterAdd variant="Bold" />}
            onClick={handleClickOpen}
            label="Filter"
            sx={{ py: 1, cursor: 'pointer' }}
          />
          {/* <Button variant="outlined" color="primary" onClick={handleClickOpen} sx={{ p: '9px', minWidth: 'auto' }}>
            <Filter size="32" />
          </Button> */}
        {/* </Tooltip> */}
      </Box>
      {/* <Button sx={{ mr: 2, ml: 2, mt: 1 }} variant="contained" onClick={handleClickOpen}></Button> */}
      <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose}>
        <IconButton
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            cursor: 'pointer',
            background: 'transparent',
            border: 'none'
          }}
          onClick={handleClose}
        >
          <CloseCircle />
        </IconButton>
        <Box sx={{ p: 1, py: 1.5 }}>
          <DialogTitle>Filters</DialogTitle>

          <DialogContent>
            {Object.entries(filterKeywords).map(([key, value]: any) => (
              <FilterComponent
                key={key} // Make sure to add a unique key for each component in the array
                type={value}
                title={key}
                value={values[key]}
                count={count}
                setFilterKey={onFilterKeywordChange}
                handleClose={handleClose}
                selectedStartDate={values?.startDate}
                selectedEndDate={values?.endDate}
              />
            ))}

            {/* <FilterComponent
              type={'category'}
              title={'Search from category'}
              count={count}
              setFilterKey={onFilterKeywordChange}
              filterKeywords={filterKeywords}
            /> */}
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="error" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  groupUsers: [],
  search: '',
  count: 0,
  sortingData: []
};

const groupUser = createSlice({
  name: 'groupUser',
  initialState,
  reducers: {
    setgroupUser(state, action) {
      return {
        ...state,
        groupUsers: action.payload
      };
    },
    setgroupUserTableCount(state, action) {
      state.count = action.payload;
    },
    searchgroupUserTable(state, action) {
      state.search = action.payload;
    },
    setSortingValue(state, action) {
      state.sortingData = action.payload;
    },
    delgroupUser(state, action) {
      const arr = state.groupUsers.filter((item: { _id: any }) => item._id !== action.payload);
      return {
        ...state,
        groupUsers: arr
      };
    },
    setSinglegroupUser(state, action) {
      const updatedgroupUser = state.groupUsers.map((item: any) => {
        if (item._id === action.payload._id) return action.payload;
        return item;
      });
      state.groupUsers = updatedgroupUser;
    }
  }
});

export const {
  setgroupUser,
  setSortingValue,
  setgroupUserTableCount,
  delgroupUser,
  setSinglegroupUser,
  searchgroupUserTable
} = groupUser.actions;

export default groupUser.reducer;

import { lazy } from 'react';

// project-imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const Home = Loadable(lazy(() => import('pages/CustomizeLandingPage/index')));
const ViewBlog = Loadable(lazy(() => import('pages/LandingPage/ViewBlog')));
const AuthRegister = Loadable(lazy(() => import('pages/auth/register')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
const AuthCheckMail = Loadable(lazy(() => import('pages/auth/check-mail')));
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));
const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/code-verification')));
const BlogView = Loadable(lazy(() => import('pages/Blogs/blogView')));
const AuthStudentRegister = Loadable(lazy(() => import('pages/auth/student-register')));
const FeedbackForm = Loadable(lazy(() => import('pages/feedback/FeedbackForm')));
// ==============================|| AUTH ROUTES ||============================== //

const LoginRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <GuestGuard>
          <CommonLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: '',
          element: <Home />
        },
        {
          path: 'viewBlog',
          element: <BlogView />
        },
        {
          path: 'blogView',
          element: <ViewBlog />
        },
        {
          path: 'login',
          element: <AuthLogin />
        },
        {
          path: 'register',
          children: [
            { path: '', element: <AuthRegister /> },
            { path: 'student', element: <AuthStudentRegister /> }
          ]
        },
        {
          path: 'forgot-password',
          element: <AuthForgotPassword />
        },
        {
          path: 'check-mail',
          element: <AuthCheckMail />
        },
        {
          path: 'reset-password',
          element: <AuthResetPassword />
        },
        {
          path: 'code-verification',
          element: <AuthCodeVerification />
        },
        {
          path: 'learner/feedback',
          children: [
            {
              path: '',
              element: <FeedbackForm />
            }
            // {
            //   path: '/feedback/feedbackTable',
            //   element: <PrivateRoute component={FeedbackTable} permission={permission?.Feedback?.View} />
            // }
          ]
        }
      ]
    }
  ]
};

export default LoginRoutes;

import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  certificateData: [],
  // courseKeyFeatures: [],
  certificateSorting: [],
  certificateSearch: '',
  // tags: [],
  // languages: [],
  // themes: {},
  // degree: [],
  // batches: [],
  // classes: [],
  // semesters: [],
  // whatWeLearn: [],
  pageIndex: 1,
  pageSize: 10
};

const certificateSlice = createSlice({
  name: 'certificate',
  initialState,
  reducers: {
    getCertificate(state, { payload }) {
      return {
        ...state,
        certificateData: payload
      };
    },
    setCertificateSorting(state, { payload }) {
      state.certificateSorting = payload;
    },
    setCertificatePageIndex(state, { payload }) {
      state.pageIndex = payload;
    },
    setCertificatePageSize(state, { payload }) {
      state.pageSize = payload;
    },
    delCertificate(state, { payload }) {
      // return state.data.filter((item: { _id: any }) => {
      //   return item._id !== payload;
      // });
      return {
        ...state,
        certificateData: state.certificateData.filter((item: { _id: any }) => item._id !== payload)
      };
    },
    // setkeyfeatures(state, { payload }) {
    //   return {
    //     ...state,
    //     courseKeyFeatures: payload
    //   };
    // },
    setCertificateSearch(state, { payload }) {
      state.certificateSearch = payload;
    },
    setCertificateStatus(state: any, { payload }) {
      const certificateData = state.certificateData.map((certificate: any) => {
        if (certificate._id === payload) return { ...certificate, isPublish: !certificate.isPublish };
        return certificate;
      });
      state.certificateData = certificateData;
    },
    resetStateCertificate: (state) => {
      return initialState;
    },
    // setCourseTag(state, { payload }) {
    //   return {
    //     ...state,
    //     tags: payload
    //   };
    // },
    // setThemeSetting(state, { payload }) {
    //   return {
    //     ...state,
    //     themes: payload
    //   };
    // },
    // setBatches(state, { payload }) {
    //   state.batches = payload;
    // },
    // setClasses(state, { payload }) {
    //   state.classes = payload;
    // },
    // setSemesters(state, { payload }) {
    //   state.semesters = payload;
    // },
    // setlanguages(state, { payload }) {
    //   return {
    //     ...state,
    //     languages: payload
    //   };
    // },
    // setdegree(state, { payload }) {
    //   return {
    //     ...state,
    //     degree: payload
    //   };
    // },
    // setWhatWillLearn(state, { payload }) {
    //   return {
    //     ...state,
    //     whatWeLearn: payload
    //   };
    // }
  }
});

export const {
  getCertificate,
  setCertificateSorting,
  delCertificate,
  setCertificatePageIndex,
  setCertificatePageSize,
  setCertificateSearch,
  setCertificateStatus,
  resetStateCertificate,
  // setThemeSetting,
  // setkeyfeatures,
  // setCourseTag,
  // setClasses,
  // setSemesters,
  // setBatches,
  // setlanguages,
  // setdegree,
  // setWhatWillLearn
} = certificateSlice.actions;
export default certificateSlice.reducer;

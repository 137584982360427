import StatusCode from '../../utils/StatusCode';
import { createSlice } from '@reduxjs/toolkit';

const initialState: {
  discussions: Array<any>;
  comments: Array<any>;
  commentsData: Array<any>;
  count: Number;
  pageIndex: Number;
  size: Number;
  commentsCount: Number;
  commentPageIndex: Number;
  commentSize: Number;
  search: String;
  status: any;
  sortDiscussion: any;
} = {
  discussions: [],
  comments: [],
  commentsData: [],
  count: 0,
  pageIndex: 1,
  size: 10,
  search: '',
  commentSize: 10,
  commentPageIndex: 1,
  commentsCount: 0,
  status: StatusCode.IDLE,
  sortDiscussion: []
};

const discussionSlice: any = createSlice({
  name: 'discussion',
  initialState,
  reducers: {
    getDiscussion(state: any, action: any) {
      return {
        ...state,
        discussions: action.payload
      };
    },

    addNewDiscussion(state: any, action: any) {
      return {
        ...state,
        discussions: [action.payload, ...state.discussions]
      };
    },

    delTopic(state: any, action: any) {
      return {
        ...state,
        discussions: state.discussions.filter((item: { _id: any }) => item._id !== action.payload)
      };
    },
    updateDiscussion(state: any, action: any) {
      const UpdatedTopic = action.payload;
      const DisUpdate = state.discussions.map((item: any) => {
        if (item._id === UpdatedTopic._id) {
          return UpdatedTopic;
        }
        return item;
      });
      state.discussions = DisUpdate;
    },

    getSingleData(state: any, action: any) {
      return {
        ...state,
        comments: action.payload
      };
    },

    setCommetsData(state: any, action: any) {
      state.commentsData = action.payload;
    },
    addNewComment(state: any, action: any) {
      state.commentsData = action.payload;
    },
    delComment(state: any, action: any) {
      const newComment = state?.commentsData?.filter((item: { _id: any }) => item._id !== action.payload);
      state.commentsData = newComment;
    },
    updateComment(state: any, action: any) {
      state.commentsData = action.payload;
    },
    setDiscussionSearch(state: any, action: any) {
      state.search = action.payload;
    },
    setDiscussionTableSize(state: any, action: any) {
      state.size = action.payload;
    },
    setCommentTableSize(state: any, action: any) {
      state.size = action.payload;
    },
    setDiscussionPageIndex(state: any, action: any) {
      state.pageIndex = action.payload;
    },
    setCommentPageIndex(state: any, action: any) {
      state.pageIndex = action.payload;
    },
    setDiscussionCount(state: any, action: any) {
      state.count = action.payload;
    },
    setCommentCount(state: any, action: any) {
      state.count = action.payload;
    },
    setDiscussionSorting(state: any, action: any) {
      state.sortDiscussion = action.payload;
    },
    resetStateDiscussion: (state) => {
      return initialState;
    },

  }
});

export const {
  delTopic,
  getDiscussion,
  addNewDiscussion,
  updateDiscussion,
  getSingleData,
  setCommetsData,
  addNewComment,
  delComment,
  updateComment,
  setDiscussionSearch,
  setDiscussionTableSize,
  setDiscussionPageIndex,
  setDiscussionCount,
  setDiscussionSorting,
  setCommentTableSize,
  setCommentPageIndex,
  setCommentCount,
  resetStateDiscussion
} = discussionSlice.actions;

export default discussionSlice.reducer;

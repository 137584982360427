import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sections: [],
  resulttable: [],
  pageIndex: 1,
  size: 10,
  deleted: false,
  search: undefined,
  resulttableSorting: []
};

const resulttable = createSlice({
  name: 'resulttable',
  initialState,
  reducers: {
    updateresulttable(state, action) {
      state.resulttable = action.payload;
    },
    updatesections(state, action) {
      state.sections = action.payload;
    },
    updateSectionDetails(state: any, action) {
      const newSection = { title: action.payload.title, totalMark: 0, questionDetails: [] };
      state.sections = [...state.sections, newSection];
    },
    updateSectionQuestionDetails(state: any, action) {
      const { index, question } = action.payload;
      const totalMark = state.sections[index]?.totalMark || 1;
      question.mark = totalMark;
      state?.sections?.[index]?.questionDetails?.push(question);
    },
    delSectionQuestionDataDetails(state: any, action) {
      state.sections.forEach((section: any) => {
        const indexInOtherSection = section.questionDetails.findIndex((qd: any) => qd.questionId === action.payload);
        if (indexInOtherSection !== -1) {
          section.questionDetails.splice(indexInOtherSection, 1);
        }
      });
    },
    updateSectionData(state: any, action) {
      const { index, title, mark } = action.payload;
      if (state.sections[index]) {
        state.sections[index].title = title;
        state.sections[index].totalMark = mark;
        state.sections[index].questionDetails.forEach((question: any) => {
          question.mark = mark === 0 ? 1 : mark;
        });
      }
    },
    updateQuestionMarkDetails(state: any, action) {
      const { index, questionsIndex, mark } = action.payload;
      state.sections[index].questionDetails[questionsIndex].mark = mark;
    },
    setresulttablePageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    deleteSectionData(state: any, action) {
      state.sections.splice(action.payload, 1);
    },
    deleteQuestionData(state: any, action) {
      const { index, _id } = action.payload;
      state.sections[index].questionDetails = state.sections[index].questionDetails.filter(
        (item: { questionId: any }) => item.questionId !== _id
      );
    },
    setresulttablestatus(state, action) {
      state.deleted = action.payload;
    },
    setresulttableSearch(state, action) {
      state.search = action.payload;
    },
    setresulttableSorting(state, action) {
      state.resulttableSorting = action.payload;
    },
    delresulttable(state: any, action) {
      state.resulttable.data = state.resulttable.data.filter((item: { questionId: any }) => item.questionId !== action.payload);
    },
    setresulttableTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    }
  }
});

export const {
  updateresulttable,
  updatesections,
  updateSectionDetails,
  updateSectionQuestionDetails,
  updateQuestionMarkDetails,
  delSectionQuestionDataDetails,
  deleteSectionData,
  deleteQuestionData,
  updateSectionData,
  setresulttableSearch,
  setresulttablestatus,
  setresulttableSorting,
  setresulttablePageIndex,
  delresulttable,
  setresulttableTableSize
} = resulttable.actions;
export default resulttable.reducer;

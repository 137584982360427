import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  contentmanagement: [],
  pageIndex: 1,
  size: 10,
  search: undefined
};

const contentmanagement = createSlice({
  name: 'contentmanagement',
  initialState,
  reducers: {
    updatecontentmanagement(state, action) {
      state.contentmanagement = action.payload;
    },
    setcontentmanagementPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    setcontentmanagementSearch(state, action) {
      state.search = action.payload;
    },
    delcontentmanagement(state: any, action) {
      state.contentmanagement.data = state.contentmanagement.data.filter((item: { _id: any }) => item._id !== action.payload);
    },

    setcontentmanagementTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    }
  }
});

export const { updatecontentmanagement, setcontentmanagementSearch, setcontentmanagementPageIndex, setcontentmanagementTableSize, delcontentmanagement } =
  contentmanagement.actions;
export default contentmanagement.reducer;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  verification: {
    webcam: undefined,
    webcamPermission: undefined,
    microphone: undefined,
    microphonePermission: undefined,
    screenSharing: undefined,
    screenSharingTest: undefined,
    device: undefined,
    keyboard: undefined,
    keyboardKey: 0,
    mouse: undefined,
    profile: undefined,
    idCard: undefined
  }
};

const examDeviceTest = createSlice({
  name: 'examDeviceTest',
  initialState,
  reducers: {
    verificationItem(state: any, action) {
      const { key, value } = action.payload;
      state.verification[key] = value;
    }
  }
});

export const { verificationItem } = examDeviceTest.actions;
export default examDeviceTest.reducer;

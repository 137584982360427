import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  data: {},
  currentContentState: {},
  currentContentData: {}
};

const currentContentSlice = createSlice({
  name: 'currentContent',
  initialState,
  reducers: {
    setCurrentContent(state, action) {
      state.data = action.payload;
    },
    setCurrentContentData(state, action) {
      state.currentContentData = action.payload;
    },
    setCompletedContentArr(state: any, action: any) {
      state.data.completedContent = [...state.data.completedContent, action.payload];
    },
    resetContentData(state) {
      return initialState;
    }
  }
  // ,
  // extraReducers: (builder) => {
  //   builder.addCase(setCurrentContent.fulfilled, (state, action) => {
  //     state.data = action.payload;
  //   });
  // }
});

export const { setCurrentContent, resetContentData, setCurrentContentData, setCompletedContentArr } = currentContentSlice.actions;
export default currentContentSlice.reducer;

// export const setCurrentContent = createAsyncThunk('content/set', async (currentContent: any) => {
//   return currentContent;
// });

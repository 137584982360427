import axios from 'axios';

const API_URL = process.env.REACT_APP_BASEURL + 'reviews';

export const getFeedback = (reqData: Object) => {
  return axios.get(API_URL + '/feedback', { params: reqData });
};

export const addReview = (reqData: Object) => {
  return axios.post(API_URL + '/add', reqData);
};

export const editReviews = (reqData: object) => {
  return axios.put(API_URL, reqData);
};

export const deleteReview = (reqData: object) => {
  return axios.delete(API_URL, { params: reqData });
};

export const getAllReviews = (reqData: Object) => {
  return axios.get(API_URL + '/all', { params: reqData });
};

export const checkReview = (reqData: Object) => {
  return axios.get(API_URL + '/checkReview', { params: reqData });
};

export const addExamFeedback = (reqData: Object) => {
  return axios.post(API_URL + '/addExamFeedBack', reqData);
};
// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Home3 } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = { icon1: Home3 };

// ==============================|| MENU ITEMS - category ||============================== //

const Classroom: NavItemType = {
  id: 'Classroom-Classrooms',
  //   title: <FormattedMessage id="Classroom" />,
  type: 'group',
  icon: icons.icon1,
  name: 'ClassRoom',
  children: [
    {
      icon: icons.icon1,
      id: 'Classroom',
      title: <FormattedMessage id="Classroom" />,
      type: 'item',
      url: '/classroom'
    }
  ]
};

export default Classroom;

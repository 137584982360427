import { Typography } from '@mui/material';
import { useState, useEffect } from 'react';

const CountdownTimer = ({ startTime, endTime, onTimerEnd, aboveText, textPosition }: any) => {
  const [duration, setDuration] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [timerEnded, setTimerEnded] = useState(false);

  useEffect(() => {
    const calculateDuration = () => {
      const currentDate: any = new Date();
      const startTimeDate: any = new Date(startTime);
      const endTimeDate: any = new Date(endTime);

      if (currentDate < startTimeDate) {
        setDuration({ hours: 0, minutes: 0, seconds: 0 });
        return;
      }

      if (currentDate >= endTimeDate) {
        setTimerEnded(true);
        clearInterval(intervalId);
        onTimerEnd();
        return;
      }

      let timeDifference = Math.abs(endTimeDate - currentDate) / 1000;
      const hours = Math.floor(timeDifference / 3600);
      timeDifference %= 3600;
      const minutes = Math.floor(timeDifference / 60);
      const seconds = Math.floor(timeDifference % 60);
      setDuration({ hours, minutes, seconds });
    };

    const intervalId = setInterval(calculateDuration, 1000);

    return () => clearInterval(intervalId);
  }, [startTime, endTime, onTimerEnd]);

  return (
    <div style={{ marginRight: '10px', textAlign: textPosition ? textPosition : 'right' }}>
      <Typography variant="body1" sx={{ fontWeight: 'bolder', color: '#BCBCBC' }}>
        {timerEnded ? "Time's up!" : aboveText}
      </Typography>
      <Typography
        variant="h4"
        sx={{
          color: timerEnded ? '#ff0000' : '#EE5A4C',
          fontWeight: 'bolder',
          fontSize: { xs: '18px', sm: '18px', md: '22px', lg: '22px' }
        }}
      >
        {`${duration.hours.toString().padStart(2, '0') || 0}h:${duration.minutes.toString().padStart(2, '0') || 0}m:${
          duration.seconds.toString().padStart(2, '0') || 0
        }s`}
      </Typography>
    </div>
  );
};

export default CountdownTimer;

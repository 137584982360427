// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  KyberNetwork,
  Messages2,
  MessageEdit,
  Message2,
  Calendar1,
  Kanban,
  Profile2User,
  Bill,
  UserSquare,
  ShoppingBag,
  Setting2
} from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  applications: KyberNetwork,
  chat: Messages2,
  calendar: Calendar1,
  kanban: Kanban,
  customer: Profile2User,
  invoice: Bill,
  profile: UserSquare,
  ecommerce: ShoppingBag,
  Setting: Setting2,
  CreateBlog: Message2,
  Blog: MessageEdit
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //
const Blogs: NavItemType = {
  id: 'CreateBlogs',
  // title: <FormattedMessage id="Settings" />,
  icon: icons.applications,
  type: 'group',
  name: 'Blogs',
  children: [
    {
      id: 'CreateBlogs',
      title: <FormattedMessage id="Blogs" />,
      type: 'item',
      url: '/blog/list',
      icon: icons.Blog,
      breadcrumbs: false
    }
  ]
};

export default Blogs;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userList: [],
  pageIndex: 1,
  size: 10,
  totalCount: 0,
  deleted: false,
  search: undefined
};

const examUserList = createSlice({
  name: 'time-table',
  initialState,
  reducers: {
    updateExamUserList(state, action) {
      state.userList = action.payload;
    },
    setExamUserListPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    setExamUserListSearch(state, action) {
      state.search = action.payload;
    },
    setExamUserListTotalCount(state, action) {
      state.totalCount = action.payload;
    },
    delExamUserList(state: any, action) {
      state.userList = state.userList.filter((item: { _id: any }) => item._id !== action.payload);
    },
    setExamUserListSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    }
  }
});

export const {
  updateExamUserList,
  setExamUserListPageIndex,
  setExamUserListSearch,
  setExamUserListSize,
  setExamUserListTotalCount,
  delExamUserList
} = examUserList.actions;
export default examUserList.reducer;

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Call } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = { icon1: Call };

// ==============================|| MENU ITEMS - category ||============================== //

const contactlist: NavItemType = {
    id: 'group-contactlist',
    //   title: <FormattedMessage id="contactlist" />,
    type: 'group',
    name: 'Contact',
    icon: icons.icon1,
    children: [
        {
            icon: icons.icon1,
            id: 'Contact',
            title: <FormattedMessage id="Contact List" />,
            type: 'item',
            url: '/contact/contactTable'
        }
    ]
};

export default contactlist;

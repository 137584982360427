import { useEffect, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import { getAllBadge, getBadge } from 'services/badges.service';
import useAuth from 'hooks/useAuth';
import Data404 from 'components/third-party/Data404';
import Loader2 from 'components/Loader2';
import NewSampleBadge from './NewSampleBadge';
import BackToggle from 'components/third-party/dropzone/BackToggle';

const BadgesAchieved = ({ orgId, id }: any) => {
  const { user }: any = useAuth();
  const [badges, setBadges] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userBadgeData, setUserBadgeData] = useState<any>([]);
  const userId = user?.type == 'student' ? user?._id : id;

  const userBadgeDetail = () => {
    getBadge({ orgId: user?.type === 'superadmin' ? orgId : user?.organizationId?._id, userId: userId }).then((res: any) => {
      setUserBadgeData(res?.data?.data?.badges);
    });
  };
  useEffect(() => {
    userBadgeDetail();
  }, []);

  useEffect(() => {
    getAllBadge({ orgId: user?.type === 'superadmin' ? orgId : user?.organizationId?._id })
      .then((res: any) => {
        if (res.status === 200) {
          setBadges(res.data.data.data);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [orgId, user?.organizationId?._id, user?.type]);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <MainCard
      title={
        <span style={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}>
          <BackToggle show={true} />
          <span style={{ marginLeft: 10 }}>Badges</span>
        </span>
      }
    >
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Loader2 />
        </div>
      ) : badges.length > 0 ? (
        badges.map((badge: any, index: number) => (
          <Box key={index}>
            <Typography variant="h4" mb={2} mt={1}>
              {badge._id}
            </Typography>
            <Grid container spacing={3}>
              {badge.result.map((newbadge: any, idx: number) => {
                const isIdMatched = userBadgeData.some((item: any) => item._id === newbadge._id);
                return (
                  <Grid item key={newbadge._id} xs={8} sm={4} md={3} lg={2.2} sx={{ mb: 2 }}>
                    <Box onMouseEnter={() => handleMouseEnter(newbadge._id)} onMouseLeave={handleMouseLeave}>
                      <NewSampleBadge
                        badgeTitle={newbadge.BadgeLevel}
                        badgeDesc={newbadge.unlockCount + ' ' + newbadge.BadgeTitle}
                        lowerBackground={
                          isIdMatched
                            ? newbadge.lowerBackgroundColor
                            : user.badges.includes(newbadge._id) || hoveredIndex === newbadge._id
                            ? newbadge.lowerBackgroundColor
                            : '#000000'
                        }
                        upperBackground={
                          isIdMatched
                            ? newbadge.upperBackgroundColor
                            : user.badges.includes(newbadge._id) || hoveredIndex === newbadge._id
                            ? newbadge.upperBackgroundColor
                            : '#ffffff'
                        }
                        icon={newbadge.icon}
                      />
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
            {index !== badges.length - 1 && <Divider />}
          </Box>
        ))
      ) : (
        <Data404 title="No Badges Found" />
      )}
    </MainCard>
  );
};

export default BadgesAchieved;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  usercertificate: [],
  pageIndex: 1,
  size: 10
};

const usercertificate = createSlice({
  name: 'usercertificate',
  initialState,
  reducers: {
    updateusercertificate(state, action) {
      state.usercertificate = action.payload;
    },
    setusercertificatePageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    setusercertificateTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    }
  }
});

export const { updateusercertificate, setusercertificatePageIndex, setusercertificateTableSize } = usercertificate.actions;
export default usercertificate.reducer;
import { useRoutes } from 'react-router-dom';

// project-imports

import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import useConfig from 'hooks/useConfig';
import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';
import { setSettings } from 'store/reducers/organizationSettings';
import { chatEvents, socket } from 'utils/socket';
import useChat from 'hooks/useChat';
import { useDispatch } from 'react-redux';
import Snackbar from 'utils/Snackbar';
import { addChatDrawerCount, getRoomUser, getUserChats, increaseUserChats, setActiveUser, setIsTyping } from 'store/reducers/chat';

// ==============================|| ROUTES RENDER ||============================== //

export default function ThemeRoutes() {
  const dispatch = useDispatch();
  const { mode } = useConfig();
  const { user, organization } = useAuth();
  const { roomUsers, readChat, userChats, userRoomId, currentUser, sendIsTyping } = useChat();
  const themeSettings = organization?.themes;

  const handleNewMessage = (data: any) => {
    // console.log('chatEvents.RECEIVE_NEW_MESSAGE--->', userRoomId, data?.newMessage?.result?.roomId);
    // if (!userRoomId) return;
    // console.log(localStorage.getItem('currentRoomId'),data?.newMessage?.result?.roomId)
    if (localStorage.getItem('currentRoomId') !== data?.newMessage?.result?.roomId) {
      dispatch(addChatDrawerCount(data?.newMessage?.result?.roomId));
      // return;
    } else {
      readChat([data?.newMessage?.result?._id]);
      dispatch(increaseUserChats({ ...data?.newMessage?.result, isRead: true }));
    }
    // console.log(data, 'this is new message data');
    Snackbar(
      `You received a message from ${data?.newMessage?.result?.senderId?.firstName} ${data?.newMessage?.result?.senderId?.lastName}`,
      'success'
    );

    // const newChatData = [...userChats, data?.newMessage?.result];
  };

  socket?.on(chatEvents.RECEIVE_UPDATE_MESSAGE, (data: any) => {
    // console.log('chatEvents.RECEIVE_UPDATE_MESSAGE--->', data?.result);
    Snackbar(`${data?.result[0]?.senderId?.firstName} ${data?.result[0]?.senderId?.lastName} Updated a message`, 'success');

    if (userRoomId === data?.result[0]?.roomId) {
      if (Array.isArray(data?.result)) {
        const updatedChatsMap = new Map(data.result.map((updatedChat: any) => [updatedChat._id, updatedChat]));
        const updatedMessages = userChats.map((chat: any) => updatedChatsMap.get(chat._id) || chat);
        if (updatedMessages.length > 0) {
          dispatch(getUserChats(updatedMessages));
        }
      }
    }
  });

  socket?.on(chatEvents.RECEIVE_DELETE_MESSAGE, (data: any) => {
    // console.log('chatEvents.RECEIVE_DELETE_MESSAGE--->', data?.result);
    const chatId = data?.result?._id;

    Snackbar(`${data?.result?.senderId?.firstName} ${data?.result?.senderId?.lastName} deleted a message`, 'error');

    const updatedData = userChats.map((chat: { _id: string }) => {
      if (chat._id === chatId) {
        return { ...chat, isDeleted: true, file: [], text: 'This message is deleted' };
      }
      return chat;
    });
    dispatch(getUserChats(updatedData));
  });

  socket?.on(chatEvents.RECEIVE_NEW_ROOM, (data: any) => {
    // console.log('chatEvents.RECEIVE_NEW_ROOM--->', data?.result);
    const updatedData = roomUsers?.length > 0 ? [...roomUsers, data?.result] : [data?.result];
    updatedData?.length > 0 && dispatch(getRoomUser(updatedData));
  });

  socket?.on(chatEvents.RECEIVE_ACTIVE_USERS, (data: any) => {
    // console.log('RECEIVE_ACTIVE_USERS', data?.result);
    dispatch(setActiveUser(data?.result));
  });

  socket?.on(chatEvents.IS_TYPING, (data: any) => {
    dispatch(setIsTyping(data?.result));
  });

  socket?.on(chatEvents.READ_MESSAGES, (data: any) => {
    // console.log('RECEIVE_ACTIVE_USERS', data?.result);
    let updateIds = data?.result.map((val: { _id: string }) => val?._id);
    const updatedChats = userChats.map((chat: { _id: string; isRead: boolean }) => {
      if (updateIds.includes(chat._id)) {
        return { ...chat, isRead: true };
      }
      return chat;
    });
    dispatch(getUserChats(updatedChats));
    // dispatch(setActiveUser(data?.result));
  });

  useEffect(() => {
    localStorage.setItem('currentRoomId', userRoomId);
    socket?.on(chatEvents.RECEIVE_NEW_MESSAGE, handleNewMessage);
    return () => {
      if (user?._id && user?.type) sendIsTyping(user._id, currentUser?._id, user?.type, currentUser?.type, false);
      socket?.off(chatEvents.RECEIVE_NEW_MESSAGE, handleNewMessage);
      // console.log('triggered off event');
    };
  }, [userRoomId]);

  useEffect(() => {
    socket?.emit(chatEvents.CONNECTED_USER_DATA, { userId: user?._id }, (data: any) => {
      // console.log('CONNECTED_USER_DATA', data?.result);
      dispatch(setActiveUser(data?.result));
    });

    if (organization && organization?.themes && Object.keys(organization?.themes).length >= 0 && Object.keys(themeSettings).length === 0) {
      dispatch(setSettings(organization));
    }
  }, [organization]);

  useEffect(() => {
    document.documentElement.style.setProperty('--nav-background-color', themeSettings?.backgroundColor);
    document.documentElement.style.setProperty('--background-color', themeSettings?.Tabs);
    document.documentElement.style.setProperty('--search', themeSettings?.search);
    document.documentElement.style.setProperty(
      '--color',
      themeSettings?.theme === 'default' ? (mode === 'dark' ? '#F8F9FA' : '#5C626A') : themeSettings?.textColor
    );
    document.documentElement.style.setProperty('--main-logo', themeSettings?.mainlogo);
    document.documentElement.style.setProperty(
      '--main-logo-icon',

      themeSettings?.theme === 'default' ? (mode === 'dark' ? '#F8F9FA' : '#5C626A') : themeSettings?.mainLogoIcons
    );
  }, [themeSettings, mode]);

  return useRoutes([LoginRoutes, MainRoutes()]);
}

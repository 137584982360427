// material-ui

// project-imports

import { Box } from '@mui/material';
import Navigation from './Navigation';
import SimpleBar from 'components/third-party/SimpleBar';

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => {
  return (
    <Box id="sidebar-nav" sx={{ overflowY: 'hidden' }}>
      <SimpleBar
        sx={{
          '& .simplebar-content': {
            display: 'flex',
            flexDirection: 'column'
          }
        }}
      >
        <>
          <Navigation />
        </>
      </SimpleBar>
    </Box>
  );
};

export default DrawerContent;

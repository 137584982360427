import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  coursereport: [],
  cardReport: '',
  pageIndex: 1,
  courseReportSort: [],
  size: 10,
  deleted: false,
  userCoursepageIndex: 1,
  userCoursepageSize: 10,
  userCourseTotalCount: 0,
  userCourseSearch: undefined,
  userCourseTable: []
};

const userreport = createSlice({
  name: 'userreport',
  initialState,
  reducers: {
    updatecoursereport(state, action) {
      state.coursereport = action.payload;
    },
    setUserCourseSearch(state, action) {
      state.userCourseSearch = action.payload;
    },
    setSortCourse(state, action) {
      state.courseReportSort = action.payload;
    },
    setUserCourseStatus(state, action) {
      state.deleted = action.payload;
    },
    setCardReport(state, action) {
      state.cardReport = action.payload;
    },
    setuserCourseTotalCount(state, action) {
      state.userCourseTotalCount = action.payload;
    },
    setUserCourseTable(state, action) {
      state.userCourseTable = action.payload;
    },
    setcoursereportPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    setUserCourseListPage(state, action) {
      state.userCoursepageIndex = action.payload;
    },
    setUserCourseListSize(state, action) {
      state.userCoursepageSize = action.payload;
    },
    delcoursereport(state: any, action) {
      state.coursereport.courseList = state.coursereport.courseList.filter((item: { _id: any }) => item._id !== action.payload);
    },
    setcoursereportTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    },
    resetStatecoursereport: (state) => {
      return initialState;
    },
  }
});

export const {
  updatecoursereport,
  setUserCourseTable,
  setuserCourseTotalCount,
  setUserCourseStatus,
  setUserCourseListSize,
  setUserCourseListPage,
  setCardReport,
  setUserCourseSearch,
  setSortCourse,
  delcoursereport,
  setcoursereportPageIndex,
  setcoursereportTableSize,
  resetStatecoursereport
} = userreport.actions;
export default userreport.reducer;

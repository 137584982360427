import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import MainCard from 'components/MainCard';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import Editor from 'components/Editor';
import { useLocation, useNavigate } from 'react-router-dom';
import Snackbar from 'utils/Snackbar';
import { useTheme } from '@mui/material/styles';
import useAuth from 'hooks/useAuth';
import { Add, Calendar } from 'iconsax-react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { addExam, updateExam } from 'services/exam.service';
import BackToggle from 'components/third-party/dropzone/BackToggle';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getAllClassRoom } from 'services/classRoom.service';
import { getLocaleName } from 'utils/helper';
import useConfig from 'hooks/useConfig';
import { getAllSubjects } from 'services/subject.service';

const AddExam = () => {
  const navigate = useNavigate();
  const { i18n } = useConfig();
  const location = useLocation();
  const [PlaceValues, setPlaceValues] = useState<any>();
  const [subject, setSubject] = useState([]);
  useEffect(() => {
    getLocaleName(i18n)
      .then((result) => {
        setPlaceValues(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [i18n]);
  const orgData = useSelector((state: any) => state.organization);
  const theme = useTheme();
  const { user }: any = useAuth();
  const { rowData } = location.state || '';

  // Retrieve the stored data from local storage
  const storedData = localStorage.getItem('examData');
  let parsedData: any = {};

  // Parse the stored data back into an object
  if (storedData) {
    const examData = JSON.parse(storedData);
    // Check if the stored user ID matches the current user's ID
    if (examData.userId === user?._id) {
      parsedData = storedData === undefined ? {} : JSON.parse(storedData);
    }
  }
  localStorage.setItem('examData', JSON.stringify({ ...parsedData, userId: user?._id }));

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const initialValues = {
    examName: rowData?.examName || parsedData?.examName || '',
    subjectName: rowData?.subjectName?._id || parsedData?.subjectName || '',
    classType: user?.organizationId?.allowClassTypes
      ? user?.organizationId?.allowClassTypes
      : rowData?.classType || parsedData?.classType || '',
    classRoomId: rowData?.classRoomId || parsedData?.classRoomId || '',
    semester: rowData?.classRoomId || parsedData?.semester || '',
    batchYear: rowData?.batchYear || parsedData?.batchYear || '',
    otherName: rowData?.classRoomId || parsedData?.otherName || '',
    streamName: rowData?.classRoomId || parsedData?.streamName || '',
    // startDate: rowData?.startDate || parsedData?.startDate || new Date(),
    // endDate: rowData?.endDate || parsedData?.endDate || new Date(),
    examInstructions: rowData?.examInstructions || parsedData?.examInstructions || '',
    totalMarks: rowData?.totalMarks || parsedData?.totalMarks || '',
    passingMarks: rowData?.passingMarks || parsedData?.passingMarks || '',
    organizationId: user?.organizationId?._id
  };
  const [classTypeOption, setClassTypeOption] = useState<Array<any>>([]);

  const formik: any = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      examName: Yup.string().required(PlaceValues?.['Exam Name is required']),
      subjectName: Yup.string().required(PlaceValues?.['Subject Name is required']),
      // className: Yup.string().required('Class Name is required'),
      batchYear: Yup.string().required(PlaceValues?.['Batch Year is required']),
      classType: Yup.string().required(PlaceValues?.['ClassType is required']),
      classRoomId: Yup.string().when('classType', {
        is: 'class',
        then: Yup.string().required(PlaceValues?.['Class field is required']),
        otherwise: Yup.string().nullable()
      }),
      semester: Yup.string().when('classType', {
        is: 'semester',
        then: Yup.string().required(PlaceValues?.['Semester is required']),
        otherwise: Yup.string().nullable()
      }),
      streamName: Yup.string().when('classType', {
        is: 'semester',
        then: Yup.string().required(PlaceValues?.['StreamName is required']),
        otherwise: Yup.string().nullable()
      }),
      otherName: Yup.string().when('classType', {
        is: 'other',
        then: Yup.string().required(PlaceValues?.['Other field is required']),
        otherwise: Yup.string().nullable()
      }),
      // divisionName: Yup.string().required('Division Name is required'),
      // startDate: Yup.date().required('Start Date & Time is required'),
      // endDate: Yup.string().required('Stop Date & Time is required'),
      // examInstructions: Yup.string().required('Instructions is required'),
      totalMarks: Yup.number().required(PlaceValues?.['Total Marks is required']),
      passingMarks: Yup.number().required(PlaceValues?.['Passing Marks is required'])
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setIsButtonDisabled(true);

      const reqData = {
        ...(rowData ? { examId: rowData._id } : {}),
        examName: values.examName,
        subjectName: values.subjectName,
        classType: values.classType,
        classRoomId:
          values.classType === 'class'
            ? values.classRoomId
            : values.classType === 'semester'
              ? values.semester
              : values.classType === 'other'
                ? values.otherName
                : '',
        batchYear: values.batchYear,
        // divisionName: values.divisionName,
        // startDate: values.startDate,
        // endDate: values.endDate,
        examInstructions: values.examInstructions,
        totalMarks: values.totalMarks,
        passingMarks: values.passingMarks,
        organizationId: user?.organizationId?._id
      };

      const submitFunction = rowData ? updateExam : addExam;

      submitFunction(reqData)
        .then((res) => {
          if (res.status === 200) {
            Snackbar('Exam updated successfully', 'success');
          } else if (res.status === 201) {
            localStorage.removeItem('examData');
            Snackbar('Exam created successfully', 'success');
          } else {
            Snackbar('Something went wrong', 'error');
          }

          navigate('/exam');
          resetForm();
          setSubmitting(true);
          setIsButtonDisabled(false);
        })
        .catch((error) => {
          console.error('Error:', error);
          setIsButtonDisabled(false);
        });
    }
  });
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };
  useEffect(() => {
    getAllSubjects({ organizationId: user?.organizationId?._id, limit: 100, skip: 1 })
      .then((res) => {
        if (res.status === 200) {
          setSubject(res?.data?.data?.data);
        } else {
          Snackbar(res.data.message, 'error');
        }
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
          Snackbar(errorMessage, 'warning');
        } else {
          const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
          Snackbar(errorMessage, 'error');
        }
      });
  }, []);
  useEffect(() => {
    getAllClassRoom({
      organizationId: user?.organizationId?._id,
      classType: formik.values.classType ? formik.values.classType : 'class',
      active: true
    }).then((res) => {
      if (res.status === 200)
        setClassTypeOption(
          res.data.data?.classRoomData?.map((item: any) => {
            return formik.values.classType === 'class'
              ? { _id: item?._id, className: item?.className || item?.class, subjects: item?.subjects, classType: item?.classType }
              : formik.values.classType === 'semester'
                ? {
                  _id: item?._id,
                  semester: item?.semester,
                  streamName: item?.streamName || item?.className,
                  subjects: item?.subjects,
                  classType: item?.classType
                }
                : formik.values.classType === 'other'
                  ? { _id: item?._id, className: item?.className, subjects: item?.subjects, classType: item?.classType }
                  : {};
          })
        );
    });
  }, [formik.values.classType]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <form onSubmit={formik.handleSubmit}>
        <MainCard
          title={
            <span style={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}>
              <BackToggle show={true} />
              <span style={{ marginLeft: 10 }}>{rowData ? 'Update Exam' : 'Add Exam'}</span>
            </span>
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="examName">Exam Name</InputLabel>
              <TextField
                fullWidth
                id="examName"
                name="examName"
                value={formik.values.examName}
                onBlur={(e) => {
                  formik.handleBlur(e); // Ensure Formik's onBlur is called
                  // Store the value in local storage
                  localStorage.setItem('examData', JSON.stringify({ ...parsedData, examName: formik.values.examName }));
                }}
                onChange={formik.handleChange}
                placeholder="Enter Exam Name"
                error={formik.touched.examName && Boolean(formik.errors.examName)}
              />
              {formik.touched.examName && formik.errors.examName && <FormHelperText error>{formik.errors.examName}</FormHelperText>}
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="batchYear">Batch Year</InputLabel>
              <Select
                fullWidth
                id="batchYear"
                name="batchYear"
                value={formik.values.batchYear}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  localStorage.setItem('examData', JSON.stringify({ ...parsedData, batchYear: formik.values.batchYear }));
                }}
                onChange={(event) => {
                  formik.handleChange(event);
                  const selectedValues = event.target.value;
                  if (selectedValues.includes('custom')) {
                    navigate('/settings/accountSettings', { state: { tab: user?.type === 'faculty' ? 2 : 3, from: 'batch' } });
                  } else {
                    formik.setFieldValue('batchYear', selectedValues);
                  }
                }}
                error={formik.touched.batchYear && Boolean(formik.errors.batchYear)}
                MenuProps={MenuProps}
              >
                <MenuItem value="custom" sx={{ fontWeight: 'bold' }}>
                  <Add />
                  <FormattedMessage id="Add Classes" />
                </MenuItem>
                {orgData?.batches?.map((value: string, index: number) => (
                  <MenuItem key={index} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.batchYear && formik.errors.batchYear && <FormHelperText error>{formik.errors.batchYear}</FormHelperText>}
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={1.5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <InputLabel>
                    <FormattedMessage id="ClassType" />
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={10.2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <RadioGroup aria-label="size" name="radio-buttons-group" defaultValue={formik.values.classType} row>
                    <FormControlLabel
                      value="class"
                      control={<Radio />}
                      label="class"
                      disabled={user?.organizationId?.allowClassTypes ? user?.organizationId?.allowClassTypes !== 'class' : false}
                      onClick={(e: any) => {
                        if (e.target.value) {
                          formik.setFieldValue('classType', e.target.value);
                          localStorage.setItem('examData', JSON.stringify({ ...parsedData, classType: e.target.value }));
                        }
                      }}
                    />
                    <FormControlLabel
                      value="semester"
                      control={<Radio />}
                      label="semester"
                      disabled={user?.organizationId?.allowClassTypes ? user?.organizationId?.allowClassTypes !== 'semester' : false}
                      onClick={(e: any) => {
                        if (e.target.value) {
                          formik.setFieldValue('classType', e.target.value);
                          localStorage.setItem('examData', JSON.stringify({ ...parsedData, classType: e.target.value }));
                        }
                      }}
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label="Other"
                      disabled={user?.organizationId?.allowClassTypes ? user?.organizationId?.allowClassTypes !== 'other' : false}
                      onClick={(e: any) => {
                        if (e.target.value) {
                          formik.setFieldValue('classType', e.target.value);
                          localStorage.setItem('examData', JSON.stringify({ ...parsedData, classType: e.target.value }));
                        }
                      }}
                    />
                  </RadioGroup>
                  {formik.touched.classType && formik.errors.classType && <FormHelperText error>{formik.errors.classType}</FormHelperText>}
                </Grid>
              </Grid>
            </Grid>
            {formik.values.classType === 'class' && (
              <Grid item xs={12} sm={4}>
                <InputLabel htmlFor="classRoomId">Classroom Name</InputLabel>
                <Select
                  error={formik.touched.classRoomId && Boolean(formik.errors.classRoomId)}
                  fullWidth
                  id="classRoomId"
                  value={formik.values.classRoomId}
                  name="classRoomId"
                  onBlur={(e: any) => {
                    formik.handleBlur(e);
                    localStorage.setItem('examData', JSON.stringify({ ...parsedData, classRoomId: e.target.value }));
                  }}
                  onChange={(event: SelectChangeEvent<any>) => {
                    const selectedValues = event.target.value;
                    if (selectedValues.includes('custom')) {
                      navigate('/classroom/add');
                    } else {
                      formik.setFieldValue('classRoomId', selectedValues);
                    }
                  }}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="custom" sx={{ fontWeight: 'bold' }}>
                    <Add />
                    <FormattedMessage id="Add Class" />
                  </MenuItem>
                  {classTypeOption?.map((value: any, index: number) => {
                    return (
                      <MenuItem key={index} value={value._id}>
                        {value.className}
                      </MenuItem>
                    );
                  })}
                </Select>
                {formik.touched.classRoomId && formik.errors.classRoomId && (
                  <FormHelperText error>{formik.errors.classRoomId}</FormHelperText>
                )}
              </Grid>
            )}
            {formik.values.classType === 'semester' && (
              <Grid item xs={12} sm={4}>
                <InputLabel htmlFor="classRoomId">Semester Name</InputLabel>
                <Select
                  error={formik.touched.semester && Boolean(formik.errors.semester)}
                  fullWidth
                  id="semester"
                  value={formik.values.semester}
                  name="semester"
                  onBlur={(e: any) => {
                    formik.handleBlur(e);
                    localStorage.setItem('examData', JSON.stringify({ ...parsedData, semester: e.target.value }));
                  }}
                  onChange={(event: SelectChangeEvent<any>) => {
                    const selectedValues = event.target.value;
                    if (selectedValues.includes('custom')) {
                      navigate('/classroom/add');
                    } else {
                      formik.setFieldValue('semester', selectedValues);
                    }
                  }}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="custom" sx={{ fontWeight: 'bold' }}>
                    <Add />
                    <FormattedMessage id="Add Class" />
                  </MenuItem>
                  {classTypeOption?.map((value: any) => {
                    return <MenuItem value={value._id}>{value.semester}</MenuItem>;
                  })}
                </Select>
                {formik.touched.semester && formik.errors.semester && <FormHelperText error>{formik.errors.semester}</FormHelperText>}
              </Grid>
            )}
            {formik.values.classType === 'semester' && (
              <Grid item xs={12} sm={4}>
                <Stack>
                  <InputLabel htmlFor="streamName">Learner's stream name</InputLabel>
                  <Select
                    error={formik.touched.streamName && Boolean(formik.errors.streamName)}
                    fullWidth
                    id="streamName"
                    value={formik.values.streamName}
                    name="streamName"
                    onBlur={(e: any) => {
                      formik.handleBlur(e);
                      localStorage.setItem('examData', JSON.stringify({ ...parsedData, streamName: e.target.value }));
                    }}
                    onChange={(event: SelectChangeEvent<any>) => {
                      const selectedValues = event.target.value;
                      if (selectedValues.includes('custom')) {
                        navigate('/classroom/add');
                      } else {
                        formik.setFieldValue('streamName', selectedValues);
                      }
                    }}
                    MenuProps={MenuProps}
                  >
                    {/* <MenuItem value="custom" sx={{ fontWeight: 'bold' }}>
                          <Add />
                          <FormattedMessage id="Add streamName" />
                        </MenuItem> */}
                    {classTypeOption?.map((value: any) => {
                      if (formik.values.semester === value._id) return <MenuItem value={value._id}>{value.streamName}</MenuItem>;
                    })}
                  </Select>
                  {/* <Box sx={{ color: 'red' }}>{formik.touched.streamName && formik.errors.streamName}</Box> */}
                  {formik.touched.streamName && formik.errors.streamName && (
                    <FormHelperText error>{formik.errors.streamName}</FormHelperText>
                  )}
                </Stack>
              </Grid>
            )}
            {formik.values.classType === 'other' && (
              <Grid item xs={12} sm={4}>
                <Stack>
                  <InputLabel htmlFor="otherName">Learner's other classType</InputLabel>
                  <Select
                    labelId="otherName"
                    id="otherName"
                    value={formik.values?.otherName ? formik.values.otherName : ''}
                    onBlur={(e: any) => {
                      formik.handleBlur(e);
                      localStorage.setItem('examData', JSON.stringify({ ...parsedData, otherName: e.target.value }));
                    }}
                    onChange={(event: SelectChangeEvent<any>) => {
                      const selectedValues = event.target.value;
                      if (selectedValues.includes('custom')) {
                        navigate('/classroom/add');
                      } else {
                        formik.setFieldValue('otherName', selectedValues);
                      }
                    }}
                  >
                    <MenuItem value="custom" sx={{ fontWeight: 'bold' }}>
                      <Add />
                      <FormattedMessage id="Add Other classType" />
                    </MenuItem>
                    {classTypeOption?.map((value: any) => (
                      <MenuItem value={value._id}>{value.className}</MenuItem>
                    ))}
                  </Select>
                  {/* <Box sx={{ color: 'red' }}>{formik.touched.otherName && formik.errors.otherName}</Box> */}
                  {formik.touched.otherName && formik.errors.otherName && <FormHelperText error>{formik.errors.otherName}</FormHelperText>}
                </Stack>
              </Grid>
            )}
            <Grid item xs={12} sm={4}>
              <InputLabel htmlFor="subjectName">Subject Name</InputLabel>
              <Select
                error={formik.touched.subjectName && Boolean(formik.errors.subjectName)}
                fullWidth
                id="subjectName"
                value={formik.values.subjectName}
                name="subjectName"
                onBlur={(e: any) => {
                  formik.handleBlur(e);
                  localStorage.setItem('examData', JSON.stringify({ ...parsedData, subjectName: e.target.value }));
                }}
                onChange={(event: SelectChangeEvent<any>) => {
                  const selectedValues = event.target.value;
                  if (selectedValues.includes('custom')) {
                    navigate('/subject/add');
                  } else {
                    formik.setFieldValue('subjectName', selectedValues);
                  }
                }}
                // onBlur={(e) => {
                //   // handleBlur(e);
                //   if (!rowData?.firstName) {
                //     parsedData.semester = formik.values.semester;
                //     localStorage.setItem('userData', JSON.stringify(parsedData));
                //   }
                // }}
                MenuProps={MenuProps}
              >
                {(formik.values.classRoomId || formik.values.semester || formik.values.batchName) && (
                  <MenuItem value="custom" sx={{ fontWeight: 'bold' }}>
                    <Add />
                    <FormattedMessage id="Add Subject" />
                  </MenuItem>
                )}
                {subject?.map((item: any) => {
                  return <MenuItem value={item?._id}>{item?.subjectName}</MenuItem>;
                })}
              </Select>
              {formik.touched.subjectName && formik.errors.subjectName && (
                <FormHelperText error>{formik.errors.subjectName}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel htmlFor="totalMarks">Total Marks</InputLabel>
              <TextField
                fullWidth
                id="totalMarks"
                name="totalMarks"
                type="number"
                onChange={(event) => {
                  const inputValue = event.target.value;
                  let newValue = parseFloat(inputValue);

                  // Round to two decimal places
                  newValue = isNaN(newValue) ? 0 : parseFloat(newValue.toFixed(1));

                  if (newValue < 0) {
                    newValue = 0;
                  } else if (newValue > 100) {
                    newValue = 100;
                  }
                  formik.setFieldValue('totalMarks', newValue);
                }}
                value={formik.values.totalMarks}
                onBlur={(e: any) => {
                  formik.handleBlur(e);
                  localStorage.setItem('examData', JSON.stringify({ ...parsedData, totalMarks: e.target.value }));
                }}
                placeholder="Enter Total Marks"
                error={formik.touched.totalMarks && Boolean(formik.errors.totalMarks)}
                inputProps={{
                  min: 0,
                  max: 100,
                  step: 1 // Adjust step if needed
                }}
              />
              {formik.touched.totalMarks && formik.errors.totalMarks && <FormHelperText error>{formik.errors.totalMarks}</FormHelperText>}
            </Grid>

            <Grid item xs={12} sm={4}>
              <InputLabel htmlFor="passingMarks">Passing Marks</InputLabel>
              <TextField
                fullWidth
                id="passingMarks"
                name="passingMarks"
                type="number"
                onChange={(event) => {
                  const inputValue = event.target.value;
                  const totalMarks = formik.values.totalMarks || 100;
                  let newValue = parseFloat(inputValue);

                  // Round to two decimal places
                  newValue = isNaN(newValue) ? 0 : parseFloat(newValue.toFixed(1));

                  if (newValue < 0) {
                    newValue = 0;
                  } else if (newValue > totalMarks) {
                    newValue = totalMarks;
                  } else if (newValue > 100) {
                    newValue = 100;
                  }
                  formik.setFieldValue('passingMarks', newValue);
                }}
                value={formik.values.passingMarks}
                onBlur={(e: any) => {
                  formik.handleBlur(e);
                  localStorage.setItem('examData', JSON.stringify({ ...parsedData, passingMarks: e.target.value }));
                }}
                placeholder="Enter Passing Marks"
                error={formik.touched.passingMarks && Boolean(formik.errors.passingMarks)}
                inputProps={{
                  min: 0,
                  max: 100,
                  step: 1 // Adjust step if needed
                }}
              />
              {formik.touched.passingMarks && formik.errors.passingMarks && (
                <FormHelperText error>{formik.errors.passingMarks}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <InputLabel htmlFor="intruction">Add Exam Instructions</InputLabel>
              <Box
                sx={{
                  flex: 1,
                  '@media screen and (max-width:950px)': {
                    mt: 2
                  },
                  '& .quill': {
                    bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : 'secondary.lighter',
                    borderRadius: '4px',
                    '& .ql-toolbar': {
                      bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'secondary.100',
                      borderColor: theme.palette.divider,
                      borderTopLeftRadius: '4px',
                      borderTopRightRadius: '4px'
                    },
                    '& .ql-container': {
                      borderColor: `${theme.palette.divider} !important`,
                      borderBottomLeftRadius: '4px',
                      borderBottomRightRadius: '4px',
                      '& .ql-editor': {
                        minHeight: 135
                      }
                    }
                  }
                }}
              >
                <Editor
                  placeholder="Write instructions here.."
                  setHtmlContent={(value) => {
                    formik.setFieldValue('examInstructions', value);
                  }}
                  htmlContent={formik.values.examInstructions}
                />
              </Box>
            </Grid>
          </Grid>
          <Stack direction={'row-reverse'} sx={{ mt: 2 }}>
            <Button type="submit" variant="contained" disabled={isButtonDisabled}>
              {rowData && rowData.examName ? 'Update Exam' : 'Add Exam'}
            </Button>
          </Stack>
        </MainCard>
      </form>
    </LocalizationProvider>
  );
};

export default AddExam;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  ListItemText,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { Add, Edit, SaveAdd, Trash } from 'iconsax-react';
import QuestionTable from 'pages/quiz/QuestionTable';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import MainCard from 'components/MainCard';
import Snackbar from 'utils/Snackbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { dispatch } from 'store';
import {
  deleteQuestionData,
  deleteSectionData,
  updateQuestionMarkDetails,
  updateSectionData,
  updateSectionDetails,
  updatesections
} from 'store/reducers/exam';
import { useSelector } from 'react-redux';
import { delquestionbankchecked, delquestionbankcheckedAll, updatequestionbankchecked } from 'store/reducers/questionbank';
import { addExamSection, getExamSingle } from 'services/exam.service';
import moment from 'moment';

const ExamDetails = ({ details }: any) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [loading, setLoading] = useState(true);
  details && loading && setLoading(false);
  const data = [
    { label: 'Exam Name', value: details?.examName ? details?.examName : 'No Exam Name' },
    { label: 'Total Marks', value: details?.totalMarks ? details?.totalMarks : 0 },
    { label: 'Passing Marks', value: details?.passingMarks ? details?.passingMarks : 0 },
    { label: 'Batch', value: details?.batchYear ? details?.batchYear : '20XX-XX' }
  ];
  const sections = useSelector((state: any) => state?.exam)?.sections;
  const handleAddSection = () => {
    dispatch(updateSectionDetails({ id: sections.length, title: 'Section ' + (sections.length + 1) }));
  };

  return (
    <>
      <Card
        sx={{
          maxWidth: '100%',
          borderRadius: '10px',
          boxShadow: 'none',
          background: isDarkMode ? '#1D2630' : '#fff',
          color: isDarkMode ? '#fff' : '#000'
        }}
      >
        <Grid container spacing={2} sx={{ p: 3 }}>
          <Grid item xs={6} sx={{ textAlign: 'left' }}>
            <Typography
              variant="h3"
              sx={{
                fontWeight: 'bold'
              }}
            >
              Examination Details
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'right' }}>
            <Button variant="contained" startIcon={<Add />} onClick={handleAddSection}>
              Add Section
            </Button>
          </Grid>
        </Grid>
        <CardContent sx={{ py: 0 }}>
          <Grid container spacing={1}>
            {data.map((item, index) => (
              <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                {loading ? (
                  <Card sx={{ p: 2 }}>
                    <Skeleton width={150} height={20} />
                    <Skeleton width={100} height={30} style={{ margin: '10px 0' }} />
                  </Card>
                ) : (
                  <Card sx={{ p: 2 }}>
                    <Typography variant="body1">{item.label}</Typography>
                    <Typography variant="h5">{item.value}</Typography>
                  </Card>
                )}
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
const AddSections = () => {
  const { user }: any = useAuth();
  const navigate = useNavigate();
  const sections = useSelector((state: any) => state?.exam)?.sections;
  const [open, setOpen] = useState(false);
  const [openSection, setSectionOpen] = useState<any>({});
  const [edit, setEdit] = useState('');
  const [current, setCurrent] = useState('');
  const [sectionDetails, setSectionDetails] = useState<any>({});
  const [expanded, setExpanded] = useState('');
  const [loading, setLoading] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const location = useLocation();
  const [exam, setExam] = useState<any>();
  const examId = location?.state?.id;
  const updatedExamQuestions = useSelector((state: any) => state?.exam)?.sections;
  const examQuestions = (updatedExamQuestions || []).map(({ _id, totalMark, ...rest }: any) => {
    const filteredDetails = (rest.questionDetails || []).map(({ question, type, ...remainingDetails }: any) => remainingDetails);
    return { ...rest, questionDetails: filteredDetails };
  });
  const onSubmit = () => {
    let totalQuestions = 0;
    sections.map((section: any) => {
      if (section && section.questionDetails) {
        totalQuestions += section.questionDetails.length;
      }
      return null;
    });
    const reqData = { examId, examQuestions, totalQuestions };
    if (getTotalExamMarks() === exam?.totalMarks) {
      addExamSection(reqData).then((res) => {
        if (res.status === 200) {
          Snackbar('Exam Section created successfully', 'success');
          navigate('/exam');
          setIsButtonDisabled(false);
        } else {
          Snackbar(res.data.message, 'error');
          setIsButtonDisabled(false);
        }
      });
    } else if (getTotalExamMarks() > exam?.totalMarks) {
      Snackbar(`Please Remove ${getTotalExamMarks() - exam?.totalMarks} Marks Questions.`, 'error');
    } else {
      Snackbar(`Please Add ${exam?.totalMarks - getTotalExamMarks()} Marks Questions.`, 'error');
    }
  };

  const handleSave = (index: any) => {
    dispatch(
      updateSectionData({
        index: sectionDetails.index,
        title: sectionDetails.title,
        mark: sectionDetails.mark
      })
    );
    setEdit('');
  };
  const handleClose = (sectionId: any) => {
    setEdit('');
    setExpanded('');
  };
  const handleSaveAs = (sectionId: any) => {
    setEdit(sectionId);
    setExpanded(sectionId);
  };
  const openDialog = (id: any) => {
    setCurrent(id);
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };
  const openSectionDialog = (index: any, title: any, _id?: any) => {
    setSectionOpen({ index, status: true, _id, title });
  };
  const closeSectionDialog = () => {
    setSectionOpen({ ...openSection, status: false });
  };
  const sureSectionDialog = () => {
    closeSectionDialog();
    if (openSection.title === 'Question') {
      dispatch(deleteQuestionData({ index: openSection.index, _id: openSection._id }));
      dispatch(delquestionbankchecked(openSection._id));
    } else {
      dispatch(deleteSectionData(openSection.index));
      dispatch(delquestionbankcheckedAll());
    }
  };
  useEffect(() => {
    if (examId) {
      getExamSingle({ examId, orgId: user?.organizationId?._id }).then((data: any) => {
        setExam(data.data.data);
        setLoading(false);
        let questionBank: any = [];
        let sectionBank: any = [];
        let QuestionData = data?.data?.data?.examQuestions;
        QuestionData?.forEach((question: any) => {
          let InnerQuestions: any = [];
          question.questionDetails.map((BulkQuestions: any) => {
            let questions = {
              questionId: BulkQuestions?.questionId?._id,
              _id: BulkQuestions?.questionId?._id,
              question: BulkQuestions?.questionId?.question,
              type: BulkQuestions?.questionId?.type,
              mark: BulkQuestions?.mark
            };
            InnerQuestions.push(questions);
            questionBank.push(questions);
            return questions;
          });
          sectionBank.push({ title: question.title, questionDetails: InnerQuestions });
        });
        dispatch(updatesections(sectionBank));
        dispatch(updatequestionbankchecked(questionBank));
      });
    } else {
      navigate('/exam');
    }
    // eslint-disable-next-line
  }, []);

  const sectionColors = [
    '#ADD8E6',
    '#90EE90',
    '#FFB6C1',
    '#FFFFE0',
    '#E6E6FA',
    '#FFDAB9',
    '#98FB98',
    '#F08080',
    '#FFD700',
    '#E6A8D7',
    '#E0FFFF',
    '#FFA07A',
    '#AFEEEE',
    '#D8BFD8',
    '#F0E68C',
    '#87CEEB',
    '#98FB98',
    '#FFE4C4',
    '#DDA0DD',
    '#F5DEB3',
    '#FF6347',
    '#CD5C5C',
    '#B0E0E6',
    '#20B2AA',
    '#DAA520',
    '#8A2BE2',
    '#7B68EE',
    '#4682B4',
    '#2E8B57',
    '#6495ED'
  ];

  function getRandomColor(index: any) {
    const randomIndex = sectionColors[sectionColors.length < index ? index % sectionColors.length : index];
    return randomIndex;
  }

  const getTotalExamMarks = () => {
    let sum = 0;
    updatedExamQuestions?.forEach((section: any) => {
      section?.questionDetails?.forEach((question: any) => {
        sum += question?.mark;
      });
    });
    return sum;
  };

  const getTotalMarks = (array: any) => {
    let sum = 0;
    array?.forEach((question: any) => {
      sum = sum + question.mark;
    });
    return sum;
  };
  const getDiffMarks = (array: any) => {
    let marks: any = [];
    array?.forEach((question: any) => {
      if (question.mark && !marks.includes(question.mark)) {
        marks.push(question.mark);
      }
    });
    return marks.length === 0 ? 0 : marks.join(', ');
  };
  return (
    <MainCard
      content={false}
      title="Add Sections"
      secondary={
        <Box sx={{ textAlign: 'right' }}>
          {loading ? (
            <Skeleton variant="text" width={150} height={60} />
          ) : (
            <>
              <Typography variant="h3">
                {getTotalExamMarks()}/{exam?.totalMarks}
              </Typography>
              <Typography variant="body2">Selected/Total Marks</Typography>
            </>
          )}
        </Box>
      }
    >
      <ExamDetails details={exam} />
      {loading ? (
        <Skeleton variant="rounded" animation="wave" width={1200} height={200} sx={{ ml: 1.3 }} />
      ) : (
        <Grid container spacing={1}>
          {sections.map((section: any, index: any) => (
            <Grid item key={index} lg={index === expanded || edit === index ? 12 : 6}>
              <Accordion sx={{ borderRadius: '18px', mx: 1, my: 0.5 }} expanded={index === expanded}>
                <AccordionSummary
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                  sx={{
                    borderRadius: index === expanded ? '18px 18px 0px 0px' : '18px 18px 18px 18px',
                    borderRight: `12px solid ${getRandomColor(index)}`
                  }}
                  onClick={() => {
                    setExpanded(index === expanded ? '' : index);
                    setEdit('');
                  }}
                >
                  <div onClick={(e) => e.stopPropagation()}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {edit !== index ? (
                        <Typography variant="h5" id="modal-modal-description" sx={{ ml: 1 }}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              flexWrap: 'wrap',
                              gap: { xs: '16px', sm: '26px', md: '30px', lg: '30px' }
                            }}
                          >
                            <Box sx={{ mt: { sm: 1, md: 0.7, lg: 0.7 } }}>
                              {section.title}
                              <Typography variant="body1" id="modal-modal-description">
                                Total Questions: {section?.questionDetails?.length}
                              </Typography>
                            </Box>
                            <Box sx={{ mt: { sm: 1, md: 1, lg: 1 } }}>
                              <Typography variant="body1">Total Marks: {getTotalMarks(section?.questionDetails)}</Typography>
                              <Typography variant="body1" id="modal-modal-description">
                                Mark Per Questions: {getDiffMarks(section?.questionDetails)}
                              </Typography>
                            </Box>
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                              {edit !== index && (
                                <>
                                  <Tooltip title="Edit">
                                    <span>
                                      <Fab
                                        color="info"
                                        aria-label="edit"
                                        sx={{ mr: 1, width: '34px', height: '34px' }}
                                        onClick={() => {
                                          handleSaveAs(index);
                                          setSectionDetails({ index: index, title: section.title, mark: section.totalMark });
                                        }}
                                      >
                                        <Edit />
                                      </Fab>
                                    </span>
                                  </Tooltip>
                                  <Tooltip title="Remove">
                                    <span>
                                      <Fab
                                        color="error"
                                        aria-label="delete"
                                        onClick={() => openSectionDialog(index, 'Section')}
                                        sx={{ mr: 1, width: '34px', height: '34px' }}
                                      >
                                        <Trash variant="Bold" />
                                      </Fab>
                                    </span>
                                  </Tooltip>
                                </>
                              )}
                            </div>
                          </Box>
                        </Typography>
                      ) : (
                        <>
                          <TextField
                            variant="outlined"
                            label={`Section ${index + 1}`}
                            value={sectionDetails.title}
                            onChange={(event: any) => {
                              setSectionDetails({ ...sectionDetails, index: index, title: event.target.value });
                            }}
                            sx={{ mr: 1 }}
                          />
                          <TextField
                            variant="outlined"
                            label="Mark Per Question"
                            value={sectionDetails.mark}
                            inputProps={{
                              inputMode: 'numeric',
                              pattern: '[0-9]*',
                              min: 1,
                              max: 100,
                              step: 1
                            }}
                            name="totalMarks"
                            onChange={(event: any) => {
                              const inputValue = event.target.value;
                              let newValue = parseInt(inputValue, 10);
                              if (isNaN(newValue) || newValue < 0) {
                                newValue = 0;
                              } else if (newValue > 100) {
                                newValue = 100;
                              } else {
                                newValue = parseInt(inputValue, 10);
                              }
                              setSectionDetails({ ...sectionDetails, index: index, mark: newValue });
                            }}
                            sx={{ mr: 1 }}
                          />
                          <Tooltip title="Save">
                            <span>
                              <Fab
                                color="success"
                                aria-label="save"
                                sx={{ mr: 1, width: '34px', height: '34px' }}
                                onClick={() => handleSave(index)}
                              >
                                <SaveAdd />
                              </Fab>
                            </span>
                          </Tooltip>
                          <Tooltip title="Cancel">
                            <span>
                              <Fab color="warning" aria-label="close" onClick={handleClose} sx={{ mr: 1, width: '34px', height: '34px' }}>
                                <Add style={{ transform: 'rotate(45deg)' }} />
                              </Fab>
                            </span>
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails sx={{ borderRadius: '0px 0px 18px 18px', borderRight: `12px solid ${getRandomColor(index)}` }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '4px' }}>
                    <Typography
                      variant="h4"
                      id="modal-modal-description"
                      sx={{ fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '16px' } }}
                    >
                      List Of Questions
                    </Typography>
                    <Tooltip title="Add">
                      <span>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          sx={{ px: 1, py: 1, borderRadius: 1, fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '16px' } }}
                          startIcon={<Add />}
                          aria-label="add"
                          onClick={() => openDialog(index)}
                        >
                          Add Question
                        </Button>
                      </span>
                    </Tooltip>
                  </div>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ textAlign: 'center' }}>#</TableCell>
                        <TableCell>Question</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>Marks</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>Action</TableCell>
                      </TableRow>
                      {section?.questionDetails?.length > 0 ? (
                        section?.questionDetails?.map((questions: any, questionsIndex: number) => (
                          <TableRow key={questionsIndex}>
                            <TableCell sx={{ textAlign: 'center' }}>{questionsIndex + 1}.</TableCell>
                            <TableCell>
                              <ListItemText primary={questions.question} />
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>
                              <TextField
                                placeholder="Enter Mark"
                                value={questions.mark ? questions.mark : 1}
                                inputProps={{
                                  inputMode: 'numeric',
                                  pattern: '[0-9]*',
                                  min: 1,
                                  max: 100,
                                  step: 1
                                }}
                                onChange={(event) => {
                                  const inputValue = event.target.value;
                                  let newValue = parseInt(inputValue, 10);
                                  if (isNaN(newValue) || newValue < 0) {
                                    newValue = 0;
                                  } else if (newValue > 100) {
                                    newValue = 100;
                                  } else {
                                    newValue = parseInt(inputValue, 10);
                                  }
                                  dispatch(
                                    updateQuestionMarkDetails({
                                      index: index,
                                      questionsIndex: questionsIndex,
                                      mark: newValue
                                    })
                                  );
                                }}
                                disabled={section?.totalMark && section?.totalMark !== 0}
                                name="mark"
                                onClick={() => {
                                  const inputElement = document.activeElement as HTMLInputElement;
                                  inputElement && inputElement.select();
                                }}
                              />
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                color="error"
                                onClick={() => openSectionDialog(index, 'Question', questions._id || questions.questionId)}
                              >
                                <Trash />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={4}
                            style={{
                              display: 'table-cell',
                              verticalAlign: 'middle',
                              textAlign: 'center',
                              height: '10vh',
                              fontWeight: 'bold',
                              fontSize: '15px',
                              paddingBottom: 0
                            }}
                          >
                            No questions added for this section.
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
      )}
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h4" id="modal-modal-description">
              Select Questions
              <Typography variant="body2" id="modal-modal-description" sx={{ color: '#ffc107' }}>
                *If you diselect any of the selected question it will impact in the sequence of another section.
              </Typography>
            </Typography>
          </div>
          <Stack sx={{ m: 1 }} direction={'row-reverse'}>
            <Button onClick={closeDialog} color="error" variant="outlined">
              Close
            </Button>
          </Stack>
        </DialogTitle>
        <DialogActions>
          <QuestionTable
            title="All Questions"
            createdBys={user?.type === 'faculty' ? user?._id : undefined}
            section={current}
            usesFor={'exam'}
          />
        </DialogActions>
      </Dialog>

      <Dialog open={openSection?.status || false} onClose={closeSectionDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <Box sx={{ p: 1, pb: 1.2, textAlign: 'center' }}>
          <DialogTitle id="alert-dialog-title" sx={{ fontSize: '20px' }}>
            <b>Delete {openSection.title}</b>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Are You Want To Delete {openSection.title}?</DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button variant="outlined" onClick={closeSectionDialog} >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={sureSectionDialog}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Box>
      </Dialog>


      <Stack direction={'row-reverse'} sx={{ m: 1.5 }}>
        {loading ? (
          <Skeleton variant="text" width={150} height={60} />
        ) : (
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={onSubmit}
            disabled={isButtonDisabled || sections.every((section: any) => section.questionDetails.length === 0)}
          >
            Submit
          </Button>
        )}
      </Stack>
    </MainCard>
  );
};

export default AddSections;

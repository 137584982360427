import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  assignpackage: [],
  pageIndex: 1,
  count: 0,
  size: 10,
  search: undefined
};

const assignpackage = createSlice({
  name: 'assignpackage',
  initialState,
  reducers: {
    updateassignpackage(state, action) {
      state.assignpackage = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload;
    },
    setassignpackagePageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    setassignpackageSearch(state, action) {
      state.search = action.payload;
    },
    setassignpackageTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    },
    resetStateassignpackage: (state) => {
      return initialState;
    }
  }
});

export const {
  updateassignpackage,
  setCount,
  setassignpackageSearch,
  setassignpackagePageIndex,
  setassignpackageTableSize,
  resetStateassignpackage
} = assignpackage.actions;
export default assignpackage.reducer;

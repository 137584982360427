import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project-imports
import { APP_DEFAULT_PATH, APP_STUDENT_DEFAULT_PATH, APP_FACULTY_DEFAULT_PATH, APP_ADMIN_DEFAULT_PATH } from 'config';
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, user } = useAuth();
  const isLoggedInUserType = user && user.type ? user.type : '';
  const navigate = useNavigate();
  const location = useLocation();
  const userType = ['admin', 'sub-admin', 'superadmin', 'manager', 'faculty', 'teacher', 'student'];
  useEffect(() => {
    if (location.pathname === '/learner/feedback') {
      navigate(location.pathname + location.search);
    } else if (isLoggedIn && isLoggedInUserType !== '' && userType.includes(isLoggedInUserType)) {
      let updatedUrl;
      const words = location?.state?.from?.split('/');
      const lastWord = words?.[words?.length - 1];
      const urlParams = new URLSearchParams(window.location.search);
      const orgId = urlParams.get('orgId');
      if (lastWord === 'edit') {
        updatedUrl = location?.state?.from.replace(/\/edit$/, ' ');
        navigate(`${updatedUrl}`);
      } else if (lastWord === undefined) {
        if (orgId) {
          navigate(`/?orgId=${orgId}`);
        } else {
          navigate('/');
        }
      } else {
        navigate(
          location?.state?.unauthorized
            ? '/login'
            : user?.type === 'student'
            ? APP_STUDENT_DEFAULT_PATH
            : user?.type === 'faculty'
            ? APP_FACULTY_DEFAULT_PATH
            : user?.type === 'admin' || user?.type === 'superadmin'
            ? APP_ADMIN_DEFAULT_PATH
            : APP_DEFAULT_PATH
        );
      }
    }
  }, [isLoggedIn]);

  return children;
};

export default GuestGuard;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  history: [],
  pageIndex: 1,
  size: 10
};

const history = createSlice({
  name: 'history',
  initialState,
  reducers: {
    updateHistory(state, action) {
      state.history = action.payload.data;
    },
    setUserPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    setUserTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    }
  }
});

export const { updateHistory, setUserPageIndex, setUserTableSize } = history.actions;
export default history.reducer;
import { createContext, ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

const ContemporaryContext = createContext<{ setData: (data: Object) => void; prevStoredData: any } | null>(null);

export const ContemporaryProvider = ({ children }: { children: ReactElement }) => {
  const [prevStoredData, setPrevStoredData] = useState<any>({});
  const { pathname } = useLocation();
  useEffect(() => {
    const filteredData = Object.keys(prevStoredData).reduce((acc: any, key: string) => {
      if (key === pathname || prevStoredData[key]?.gotoUrl === pathname) {
        console.log('===>', { acc, key });
        acc[key] = prevStoredData[key];
      }
      return acc;
    }, {});
    setPrevStoredData(filteredData);
  }, [pathname]);

  const setData = (data: Object) => {
    setPrevStoredData((prev: any) => ({
      ...prev,
      ...data
    }));
  };

  return <ContemporaryContext.Provider value={{ prevStoredData, setData }}>{children}</ContemporaryContext.Provider>;
};
export default ContemporaryContext;

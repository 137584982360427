import { useTheme } from '@mui/material';
import { ArrowCircleLeft } from 'iconsax-react';
import { useNavigate } from 'react-router-dom';

interface BackToggleProps {
  show?: boolean;
}

const BackToggle = (props: BackToggleProps) => {
  const { show = true } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const isDarkTheme = theme.palette.mode === 'dark';

  const handleClick = () => {
    navigate(-1);
  };

  const buttonColor = isDarkTheme ? '#F7F7F8' : '#1D2630';

  if (!show) {
    return null;
  }

  return <ArrowCircleLeft color={buttonColor} onClick={handleClick} size="24" style={{ cursor: 'pointer' }} />;
};

export default BackToggle;
import { createSlice } from '@reduxjs/toolkit';
const initialState: any = {
  data: [],
  count: 0,
  pageIndex: 1,
  size: 10,
  search: ''
};

const allresult = createSlice({
  name: 'allresult',
  initialState,
  reducers: {
    setallresult(state, action) {
      return {
        ...state,
        data: action.payload
      };
    },
    delallresult(state, action) {
      return {
        ...state,
        data: state.data.filter((item: { _id: any }) => item._id !== action.payload)
      };
    },
    setallresultSearch(state, action) {
      state.search = action.payload;
    },
    setallresultTableSize(state, action) {
      state.size = action.payload;
    },
    setallresultPageIndex(state, action) {
      state.pageIndex = action.payload;
    },
    setallresultCount(state, action) {
      state.count = action.payload;
    }
  }
});

export const { setallresult, delallresult, setallresultSearch, setallresultTableSize, setallresultPageIndex, setallresultCount } =
  allresult.actions;
export default allresult.reducer;

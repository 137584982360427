import axios from 'axios';

const API_URL = process.env.REACT_APP_BASEURL + 'completeExam/';

export const getExamsList = (reqData: Object) => {
  return axios.get(API_URL + 'getUserExams', { params: reqData });
};

export const examCaptures = (reqData: Object) => {
  return axios.put(API_URL + 'examCaptures', reqData);
};

export const getExam = (reqData: Object) => {
  return axios.get(API_URL + 'loadExam', { params: reqData });
};

export const getExamsDetails = (reqData: Object) => {
  return axios.get(API_URL + 'getUserExamDetails', { params: reqData });
};

export const getExamCompletionUserDetails = (reqData: Object) => {
  return axios.get(API_URL + 'getCompleteExamUserList', { params: reqData });
};

export const getCompleteExamUserReport = (reqData: Object) => {
  return axios.get(API_URL + 'getCompleteExamUserReport', { params: reqData });
};

export const addUserDetails = (reqData: Object) => {
  return axios.post(API_URL, reqData);
};

export const submitExam = (reqData: Object) => {
  return axios.put(API_URL + 'submitExam', reqData);
};

export const getExamPaper = (reqData: Object) => {
  return axios.get(API_URL + 'getExamDetailsForChecking', { params: reqData });
};

export const addSingleMarks = (reqData: Object) => {
  return axios.put(API_URL + 'assignMark', reqData);
};

export const generateResult = (reqData: Object) => {
  return axios.put(API_URL + 'generateResult', reqData);
};

import { useState, MouseEvent } from 'react';
import { Chip, ListItemButton, Menu, Stack } from '@mui/material';
import { FilterAdd, Lock, Unlock } from 'iconsax-react';
import MainCard from 'components/MainCard';
const Filters = ({ Filters, Fields, handleSelect }: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [value, setValue] = useState(undefined);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    setValue(undefined);
    handleSelect(undefined);
  };
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
      <MainCard
        id="wallet-button"
        aria-controls={open ? 'wallet-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        content={false}
        onClick={handleClick}
        sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
      >
        {value ? (
          <Chip
            color="success"
            icon={value == 'public' ? <Unlock variant="TwoTone" /> : <Lock variant="Bold" />}
            label={value}
            onDelete={handleDelete}
            sx={{ py: 1 }}
          />
        ) : (
          <Chip color="primary" icon={<FilterAdd variant="Bold" />} label="Filter" sx={{ py: 1 }} />
        )}
      </MainCard>
      <Menu
        id="wallet-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'wallet-button',
          sx: { p: 1.25, textTransform: 'capitalize' }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {Filters.map((filter: any, index: any) => (
          <ListItemButton
            key={filter}
            id={`item-${index}`}
            onClick={() => {
              handleClose();
              setValue(Fields ? Fields[index] : filter);
              handleSelect(filter);
            }}
          >
            {Fields ? Fields[index] : filter}
          </ListItemButton>
        ))}
      </Menu>
    </Stack>
  );
};

export default Filters;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  resultdetails: [],
  pageIndex: 1,
  size: 10,
  deleted: false,
  search: undefined,
  resultdetailsSorting: []
};

const resultdetails = createSlice({
  name: 'resultdetails',
  initialState,
  reducers: {
    updateresultdetails(state, action) {
      state.resultdetails = action.payload;
    },
    setresultdetailsPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    setresultdetailsstatus(state, action) {
      state.deleted = action.payload;
    },
    setresultdetailsSearch(state, action) {
      state.search = action.payload;
    },
    setresultdetailsSorting(state, action) {
      state.resultdetailsSorting = action.payload;
    },
    delresultdetails(state: any, action) {
      state.resultdetails.groupData = state.resultdetails.groupData.filter((item: { _id: any }) => item._id !== action.payload);
    },
    setresultdetailsTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    }
  }
});

export const {
  updateresultdetails,
  setresultdetailsSearch,
  setresultdetailsstatus,
  setresultdetailsSorting,
  setresultdetailsPageIndex,
  delresultdetails,
  setresultdetailsTableSize
} = resultdetails.actions;
export default resultdetails.reducer;

import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  classRooms: [],
  count: 0,
  pageIndex: 1,
  pageSize: 10
};

const classRoomslice = createSlice({
  name: 'classroom',
  initialState,
  reducers: {
    setClassRooms(state, action) {
      return {
        ...state,
        classRooms: action.payload
      };
    },
    setClassRoomTableCount(state, action) {
      state.count = action.payload;
    },
    setClassRoomPageIndex(state, action) {
      state.pageIndex = action.payload;
    },
    setClassRoomPageSize(state, action) {
      state.pageSize = action.payload;
    },
    delClassRoom(state, action) {
      const arr = state.classRooms.filter((item: { _id: any }) => item._id !== action.payload);
      return {
        ...state,
        classRooms: arr
      };
    },
    resetStateClassRoom: (state) => {
      return initialState;
    }
  }
});

export const { setClassRooms, setClassRoomTableCount, delClassRoom, setClassRoomPageSize, setClassRoomPageIndex, resetStateClassRoom } =
  classRoomslice.actions;

export default classRoomslice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import { dispatch } from 'store';
import { CalendarProps } from 'types/calendar';

const initialState: CalendarProps = {
  calendarView: 'dayGridMonth',
  error: false,
  events: [],
  reqUsers: [],
  isLoader: false,
  isModalOpen: false,
  selectedEventId: null,
  selectedRange: null,
  count: 0,
  pageIndex: 1,
  eventAllPageIndex: 1,
  eventReqPageIndex: 1,
  size: 10,
  allEventSize: 10,
  reqEventSize: 10,
  search: '',
  allEventSearch: '',
  reqEventSearch: ''
};

// ==============================|| SLICE - CALENDAR ||============================== //

const calendar = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    loading(state) {
      state.isLoader = true;
    },
    hasError(state, action) {
      state.isLoader = false;
      state.error = action.payload;
    },
    setEvents(state, action) {
      state.isLoader = false;
      state.events = action.payload;
    },
    updateCalendarView(state, action) {
      state.calendarView = action.payload;
    },
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isModalOpen = true;
      state.selectedEventId = eventId;
    },
    createEvent(state, action) {
      const newEvent = action.payload;
      state.isLoader = false;
      state.isModalOpen = false;
      state.events = [...state.events, newEvent];
    },
    setUnenrollData(state, action) {
      const eventUpdate = state.events.map((item) => {
        if (item._id === action.payload?.eventId) {
          const updatedUser = item?.users?.filter((user: { _id: string }) => user?._id !== action?.payload?.userId);
          return { ...item, users: updatedUser };
        }
        return item;
      });
      state.isLoader = false;
      state.isModalOpen = false;
      state.events = eventUpdate;
    },
    updateEvent(state, action) {
      const event = action.payload;
      const eventUpdate = state.events.map((item) => {
        if (item._id === event._id) {
          return event;
        }
        return item;
      });
      state.isLoader = false;
      state.isModalOpen = false;
      state.events = eventUpdate;
    },
    deleteEvent(state, action) {
      const { eventId } = action.payload;
      state.isModalOpen = false;
      const deleteEvent = state.events.filter((user) => user._id !== eventId);
      state.events = deleteEvent;
    },
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isModalOpen = true;
      state.selectedRange = { start, end };
    },
    toggleModal(state) {
      state.isModalOpen = !state.isModalOpen;
      if (state.isModalOpen === false) {
        state.selectedEventId = null;
        state.selectedRange = null;
      }
    },
    setEventSearch(state, action) {
      state.search = action.payload;
    },
    setAllEventSearch(state, action) {
      state.allEventSearch = action.payload;
    },
    setReqEventSearch(state, action) {
      state.reqEventSearch = action.payload;
    },
    setEventListSize(state, action) {
      state.size = action.payload;
    },
    setEventPageIndex(state, action) {
      state.pageIndex = action.payload;
    },
    setEventCount(state, action) {
      state.count = action.payload;
    },
    setReqUsers(state, action) {
      state.reqUsers = action.payload;
    },
    approveReqUsers(state: any, action) {
      const userId = action?.payload;
      const approveUser = state.reqUsers.filter((user: any) => user?._id !== userId);
      state.reqUsers = approveUser;
    },
    setEventAllPageIndex(state, action) {
      state.eventAllPageIndex = action.payload;
    },
    setEventReqPageIndex(state, action) {
      state.eventReqPageIndex = action.payload;
    },
    setAllEventListSize(state, action) {
      state.allEventSize = action.payload;
    },
    setReqEventListSize(state, action) {
      state.reqEventSize = action.payload;
    },
    resetStateCalender: (state) => {
      return initialState;
    }
  }
});

export default calendar.reducer;

export const {
  selectEvent,
  createEvent,
  updateEvent,
  deleteEvent,
  setUnenrollData,
  setEvents,
  toggleModal,
  updateCalendarView,
  setEventSearch,
  setEventListSize,
  setEventPageIndex,
  setEventAllPageIndex,
  setEventReqPageIndex,
  setEventCount,
  setReqUsers,
  approveReqUsers,
  setAllEventListSize,
  setReqEventListSize,
  setAllEventSearch,
  setReqEventSearch,
  resetStateCalender
} = calendar.actions;

export function getEvents() {
  return async () => {
    dispatch(calendar.actions.loading());
    try {
      const response = await axios.get('/api/calendar/events');
      dispatch(calendar.actions.setEvents(response.data.events));
    } catch (error) {
      dispatch(calendar.actions.hasError(error));
    }
  };
}

// export function createEvent(newEvent: Omit<EventInput, 'id'>) {
//   return async () => {
//     dispatch(calendar.actions.loading());
//     try {
//       const response = await axios.post('/api/calendar/events/add', newEvent);
//       dispatch(calendar.actions.createEvent(response.data.event));
//     } catch (error) {
//       dispatch(calendar.actions.hasError(error));
//     }
//   };
// }

// export function updateEvent(
//   eventId: string,
//   updateEvent: Partial<{
//     allDay: boolean;
//     start: Date | null;
//     end: Date | null;
//   }>
// ) {
//   return async () => {
//     dispatch(calendar.actions.loading());
//     try {
//       const response = await axios.post('/api/calendar/events/update', {
//         eventId,
//         update: updateEvent
//       });
//       dispatch(calendar.actions.updateEvent(response.data.event));
//     } catch (error) {
//       dispatch(calendar.actions.hasError(error));
//     }
//   };
// }

// export function deleteEvent(eventId: string) {
//   return async () => {
//     dispatch(calendar.actions.loading());
//     try {
//       await axios.post('/api/calendar/events/delete', { eventId });
//       dispatch(calendar.actions.deleteEvent({ eventId }));
//     } catch (error) {
//       dispatch(calendar.actions.hasError(error));
//     }
//   };
// }

export function selectRange(start: Date, end: Date) {
  return async () => {
    dispatch(
      calendar.actions.selectRange({
        start: start.getTime(),
        end: end.getTime()
      })
    );
  };
}

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { AddCircle, FormatCircle } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = { icon1: FormatCircle, addIcon: AddCircle };

// ==============================|| MENU ITEMS - category ||============================== //

const meeting: NavItemType = {
  id: 'meeting-groups',
  //   title: <FormattedMessage id="group" />,
  type: 'group',
  icon: icons.icon1,
  name: 'Meeting',
  children: [
    {
      icon: icons.icon1,
      id: 'meeting',
      title: <FormattedMessage id="Meeting" />,
      type: 'item',
      url: '/meeting'
    }
  ]
};

export default meeting;

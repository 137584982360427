/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

import useAuth from 'hooks/useAuth';
import { useSelector } from 'store';

// ==============================|| LOGO SVG ||============================== //

const LogoMain = ({ reverse, ...others }: { reverse?: boolean }) => {
  const organization: any = useSelector((state) => state.organization);
  const { user } = useAuth();

  // const name = organization?.settings?.identity?.site_name ? organization?.settings?.identity?.site_name : organization?.name;

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="icon logo" width="100" />
     *
     */
    <>
      <h3
        className="mainlogo"
        style={{ textTransform: 'capitalize', color: user?.type === 'student' ? '#fff' : 'primary', whiteSpace: 'normal' }}
      >
        {organization?.setting?.identity?.site_name
          ? organization?.setting?.identity?.site_name
          : user?.type === 'superadmin'
          ? 'Super Admin'
          : user?.type === 'support'
          ? 'Support'
          : organization?.name}
      </h3>
    </>
  );
};

export default LogoMain;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tags: [],
  languages: [],
  themes: {},
  degree: [],
  batches: [],
  batchName: [],
  classes: [],
  semesters: [],
  divisions: [],
  subjects: [],
  streams: [],
  tabValue: 0,
  whatWeLearn: [],
  courseKeyFeatures: [],
  logo: [],
  favIcon: [],
  setting: {},
  name: ''
};

const organizationSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    setSettings: (state, action) => {
      state.tags = action?.payload?.tags;
      state.languages = action?.payload?.language;
      state.themes = action?.payload?.themes;
      state.degree = action?.payload?.degree;
      state.batches = action?.payload?.batches;
      state.batchName = action?.payload?.batchName;
      state.streams = action?.payload?.streams;
      state.classes = action?.payload?.classes;
      state.semesters = action?.payload?.semesters;
      state.divisions = action?.payload?.divisions;
      state.subjects = action?.payload?.subjects;
      state.whatWeLearn = action?.payload?.whatWeLearn;
      state.courseKeyFeatures = action?.payload?.courseKeyFeatures;
      state.logo = action?.payload?.logoImage;
      state.favIcon = action?.payload?.iconImage;
      state.setting = action?.payload?.basicsettings;
      state.name = action?.payload?.name;
    },
    setLogo: (state, action) => {
      state.logo = action.payload;
    },
    setFavIcon: (state, action) => {
      state.logo = action.payload;
    },
    setCourseTag(state, { payload }) {
      return {
        ...state,
        tags: payload
      };
    },
    setThemeSetting(state, { payload }) {
      return {
        ...state,
        themes: payload
      };
    },
    setBatches(state, { payload }) {
      state.batches = payload;
    },
    setBatchName(state, { payload }) {
      state.batchName = payload;
    },
    setStreams(state, { payload }) {
      state.streams = payload;
    },
    setClasses(state, { payload }) {
      state.classes = payload;
    },
    setSemesters(state, { payload }) {
      state.semesters = payload;
    },
    setDivisions(state, { payload }) {
      state.divisions = payload;
    },
    setSubjects(state, { payload }) {
      state.subjects = payload;
    },
    setlanguages(state, { payload }) {
      state.languages = payload;
    },
    setdegree(state, { payload }) {
      return {
        ...state,
        degree: payload
      };
    },
    setWhatWillLearn(state, { payload }) {
      return {
        ...state,
        whatWeLearn: payload
      };
    },
    setkeyfeatures(state, { payload }) {
      return {
        ...state,
        courseKeyFeatures: payload
      };
    },
    setTabValues: (state, action) => {
      state.tabValue = action.payload;
    }
  }
});

export const {
  setSettings,
  setLogo,
  setFavIcon,
  setThemeSetting,
  setCourseTag,
  setClasses,
  setSemesters,
  setDivisions,
  setSubjects,
  setBatches,
  setBatchName,
  setStreams,
  setlanguages,
  setdegree,
  setWhatWillLearn,
  setkeyfeatures,
  setTabValues
} = organizationSlice.actions;

export default organizationSlice.reducer;

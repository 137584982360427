import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notifymessages: [],
  notifyAllmessages: [],
  page: 1,
  size: 5
};

const notifySlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    setMessage: (state, action) => {
      state.notifymessages = action.payload;
    },
    setAllMessage: (state, action) => {
      state.notifyAllmessages = action.payload;
    },
    removeMessage(state, action) {
      return {
        ...state,
        notifymessages: state.notifymessages.filter((message: any) => message._id !== action.payload)
      };
    },
    setnotifyPage(state, action) {
      return {
        ...state,
        page: action.payload
      };
    },
    setnotifysize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    }
  }
});

export const { setMessage, removeMessage, setAllMessage, setnotifyPage, setnotifysize } = notifySlice.actions;

export default notifySlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import StatusCode from '../../utils/StatusCode';

const initialState = {
  data: [],
  count: 0,
  pageIndex: 1,
  size: 10,
  search: '',
  status: StatusCode.IDLE
};

const recursiveRemove = (list: any, id: String) => {
  return list
    .map((item: any) => {
      return { ...item };
    })
    .filter((item: any) => {
      if ('childCategory' in item) {
        item.childCategory = recursiveRemove(item.childCategory, id);
      }
      return item._id !== id;
    });
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    getCategory(state, { payload }) {
      state.data = payload;
    },

    addCategory(state, { payload }) {
      state.data = payload;
    },

    delCategory(state, { payload }) {
      return {
        ...state,
        data: recursiveRemove(state.data, payload)
      };
    },

    updateCategory(state, { payload }) {
      state.data = payload;
    },

    setCategorySearch(state, { payload }) {
      state.search = payload;
    },

    setCategoryTableSize(state, { payload }) {
      state.size = payload;
    },

    setCategoryPageIndex(state, { payload }) {
      state.pageIndex = payload;
    },

    setCategoryCount(state, { payload }) {
      state.count = payload;
    },
    resetCategories: (state) => {
      return initialState;
    },
  }
});

export const {
  getCategory,
  addCategory,
  delCategory,
  updateCategory,
  setCategoryTableSize,
  setCategoryPageIndex,
  setCategoryCount,
  setCategorySearch,
  resetCategories
} = categorySlice.actions;
export default categorySlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  courseassessmentlist: [],
  pageIndex: 1,
  size: 10
};

const courseassessmentlist = createSlice({
  name: 'courseassessmentlist',
  initialState,
  reducers: {
    updatecourseassessmentlist(state, action) {
      state.courseassessmentlist = action.payload;
    },
    setcourseassessmentlistPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    setcourseassessmentlistTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    }
  }
});

export const { updatecourseassessmentlist, setcourseassessmentlistPageIndex, setcourseassessmentlistTableSize } = courseassessmentlist.actions;
export default courseassessmentlist.reducer;

import axios from 'axios';

const API_URL = process.env.REACT_APP_BASEURL + 'assignment';

export const addAssignment = (reqData: any) => {
  return axios.post(API_URL, reqData);
};

export const getCourseAssignmentList = (reqData: any) => {
  return axios.get(`${process.env.REACT_APP_BASEURL}/analysis/getCourseAssignmentList`, { params: reqData });
};

export const getFaultyAssignment = (reqData: any) => {
  return axios.get(`${API_URL}/facultyAssignments`, { params: reqData });
};

export const getAssignmentSubmission = (assignmentId: string) => {
  return axios.get(`${API_URL}/assignmentUserList?assignmentId=${assignmentId}`);
};

export const updateAssignment = (reqData: any) => {
  return axios.put(API_URL, reqData);
};

export const getAllAssignment = (reqData: any) => {
  return axios.get(API_URL, { params: reqData });
};

export const deleteAssignment = (reqData: any) => {
  return axios.delete(API_URL, { params: reqData });
};
export const approveAssignment = (reqData: any) => {
  return axios.put(`${API_URL}/completeAssignment`, { params: reqData });
};
export const downloadFile = (reqData: any) => {
  return axios.get(`${process.env.REACT_APP_BASEURL}/file/download`, { params: reqData, responseType: 'blob' });
};

// export const getCourseWiseAssignment = (reqData: any) => {
//   return axios.get(`${API_URL}/course-assignment`, { params: reqData });
// };

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  NotificationList: { pageIndex: 1, pageSize: 10, search: '', notification: [] },
  NotificationHistory: { pageIndex: 1, pageSize: 10, search: '', notification: [] },
  PendingNotification: { pageIndex: 1, pageSize: 10, search: '', notification: [] }
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotificationList: (state, action) => {
      state.NotificationList.notification = action.payload;
    },
    delNotificationList: (state, action) => {
      state.NotificationList.notification = state.NotificationList.notification.filter((item:{_id:string}) => item._id !== action.payload);
    },
    setNotificationListPageIndex: (state, action) => {
      state.NotificationList.pageIndex = action.payload;
    },
    setNotificationListPageSize: (state, action) => {
      state.NotificationList.pageSize = action.payload;
    },
    setNotificationListSearch: (state, action) => {
      state.NotificationList.search = action.payload;
    },
    setNotificationHistory: (state, action) => {
      state.NotificationHistory.notification = action.payload;
    },
    setNotificationHistoryPageIndex: (state, action) => {
      state.NotificationHistory.pageIndex = action.payload;
    },
    setNotificationHistoryPageSize: (state, action) => {
      state.NotificationHistory.pageSize = action.payload;
    },
    setNotificationHistorySearch: (state, action) => {
      state.NotificationHistory.search = action.payload;
    },
    setPendingNotification: (state, action) => {
      state.PendingNotification.notification = action.payload;
    },
    setPendingNotificationPageIndex: (state, action) => {
      state.PendingNotification.pageIndex = action.payload;
    },
    setPendingNotificationPageSize: (state, action) => {
      state.PendingNotification.pageSize = action.payload;
    },
    setPendingNotificationSearch: (state, action) => {
      state.PendingNotification.search = action.payload;
    },
    resetStateNotification: (state) => {
      return initialState;
    },
  }
});

export const {
  setPendingNotificationSearch,
  setPendingNotificationPageSize,
  setPendingNotificationPageIndex,
  setPendingNotification,
  setNotificationHistorySearch,
  setNotificationHistoryPageSize,
  setNotificationHistoryPageIndex,
  setNotificationHistory,
  setNotificationListSearch,
  setNotificationListPageSize,
  setNotificationListPageIndex,
  resetStateNotification,
  setNotificationList,
  delNotificationList
} = notificationSlice.actions;

export default notificationSlice.reducer;

import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Container, Toolbar } from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
import userType from 'menu-items';
import useConfig from 'hooks/useConfig';
import { dispatch, useSelector } from 'store';
import { openDrawer } from 'store/reducers/menu';
import { LAYOUT_CONST } from 'types/config';
import { DRAWER_WIDTH } from 'config';
import useAuth from 'hooks/useAuth';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = ({ hideDrawer, isStudent }: { hideDrawer: boolean; isStudent: boolean }) => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));
  const { container, miniDrawer, menuOrientation } = useConfig();
  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG;
  const { drawerOpen } = useSelector((state) => state.menu);
  const { user }: any = useAuth();
  const location = useLocation();
  const itemData = userType?.[user?.type as 'student'];
  const navigation = {
    items: itemData
  };
  const [open, setOpen] = useState(!miniDrawer || drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };
  useEffect(() => {
    if (!miniDrawer) {
      setOpen(!matchDownLG);
      dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    }
    // eslint-disable-next-line
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line
  }, [drawerOpen]);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <style>
        {location.pathname === '/learner'
          ? `.MuiContainer-root{
        padding-left:0px !important;
        padding-right:0px !important
      }`
          : ``}
      </style>
      <Box sx={{ marginBottom: 7 }}>
        <Header open={open} handleDrawerToggle={handleDrawerToggle} hideDrawer={hideDrawer} isStudent={isStudent} />
      </Box>
      <Box
        component="main"
        sx={{
          mt: user?.type === 'student' ? { xs: 4.5, sm: 4, md: 4, lg: 7, xl: 7 } : 2,
          width: `calc(100% - ${DRAWER_WIDTH}px)`,
          flexGrow: 1,
          p: location.pathname !== '/learner' ? { xs: 2, sm: 3 } : {}
        }}
      >
        <Toolbar sx={{ mt: isHorizontal ? 8 : 'inherit' }} />
        <Container
          maxWidth={container ? 'xl' : false}
          sx={{
            ...(container && { px: { xs: 0, sm: 2 } }),
            position: 'relative',
            minHeight: 'calc(100vh - 110px)',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} />
          <Outlet />
          <Footer />
        </Container>
      </Box>
    </Box>
  );
};

export default MainLayout;

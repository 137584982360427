import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  allContests: [],
  pageIndex: 1,
  pageSize: 10,
  adminAllContest: [],
  search: '',
  faqs: []
};

const contestSlice = createSlice({
  name: 'accountsettings',
  initialState,
  reducers: {
    getContests(state, action) {
      return {
        ...state,
        allContests: action.payload
      };
    },
    getAdminContests(state, action) {
      return {
        ...state,
        adminAllContest: action.payload
      };
    },
    contestDelete(state, action) {
      state.adminAllContest = state.adminAllContest.filter((section: any) => section._id !== action.payload);
    },
    setContestFaqs(state, action) {
      state.faqs = action.payload;
    },
    AddContestFaqs(state, action) {
      state.faqs = [...state.faqs, action.payload];
    },
    DeleteContestFaqs(state, action) {
      state.faqs = state.faqs.filter((section: any) => section._id !== action.payload);
    },
    EditContestFaqs(state, action) {
      const arr = state.faqs.map((section: any) => {
        if (section._id === action.payload.faqId) {
          section.question = action.payload.question;
          section.answer = action.payload.answer;
          return section;
        }
        return section;
      });
      state.faqs = arr;
    },
    setContestRegisteredUser(state: any, action) {
      const { contestId, userId } = action.payload;
      const index = state.allContests.findIndex((x: any) => x._id === contestId);
      // Ensure allContests is an array and is defined
      if (Array.isArray(state.allContests) && state.allContests[index]) {
        // Ensure registeredUser is an array and initialize it if it's not
        if (!Array.isArray(state.allContests[index].registeredUser)) {
          state.allContests[index].registeredUser = [];
        }

        // Push the userId into the registeredUser array
        state.allContests[index].registeredUser.push(userId);
      }
    },
    setContestPageIndex(state, action) {
      state.pageIndex = action.payload;
    },
    setContestPageSize(state, action) {
      state.pageSize = action.payload;
    },
    setContestSearch(state, action) {
      state.search = action.payload;
    },
    resetStateContest: (state) => {
      return initialState;
    },
  }
});

export const {
  getContests,
  AddContestFaqs,
  setContestFaqs,
  EditContestFaqs,
  contestDelete,
  DeleteContestFaqs,
  getAdminContests,
  setContestRegisteredUser,
  setContestPageIndex,
  setContestPageSize,
  setContestSearch,
  resetStateContest
} = contestSlice.actions;
export default contestSlice.reducer;

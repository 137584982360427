import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  badgelist: [],
  pageIndex: 1,
  size: 5
};

const badgelist = createSlice({
  name: 'badgelist',
  initialState,
  reducers: {
    updatebadgelist(state, action) {
      state.badgelist = action.payload;
    },
    setbadgelistPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    delbadgetypelist(state, action) {
      return {
        ...state,
        badgelist: state.badgelist.filter((item: { _id: any }) => item._id !== action.payload)
      };
    },
    delbadgelist(state: any, action: any) {
      const index = action.payload.index;
      const id = action.payload.id;
      state.badgelist[index].badges = state.badgelist[index].badges.filter((item: { _id: any }) => item._id !== id);
    },
    setbadgelistTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    },
    resetStateBadges: (state) => {
      return initialState;
    },
  }
});

export const { updatebadgelist, setbadgelistPageIndex, setbadgelistTableSize, delbadgelist, delbadgetypelist, resetStateBadges } = badgelist.actions;
export default badgelist.reducer;

import { FormattedMessage } from 'react-intl';

// assets
import { Setting4, TaskSquare } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

const icons = { Setting4 };

// ==============================|| MENU ITEMS - category ||============================== //

const GettingStarted: NavItemType = {
  id: 'GettingStarted',
  // title: <FormattedMessage id="Get Started" />,
  type: 'group',
  icon: icons.Setting4,
  name: 'GettingStarted',
  children: [
    {
      icon: icons.Setting4,
      id: 'GettingStarted',
      title: <FormattedMessage id="Getting Started" />,
      type: 'item',
      url: '/getStarted'
    }
  ]
};

export default GettingStarted;

import { forwardRef, useEffect, ForwardRefExoticComponent, RefAttributes } from 'react';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography, useMediaQuery } from '@mui/material';

// project-imports
import Dot from 'components/@extended/Dot';
import useConfig from 'hooks/useConfig';
import { dispatch, useSelector } from 'store';
import { activeItem, clearBlogpost, openDrawer } from 'store/reducers/menu';

// types
import { LinkTarget, NavItemType } from 'types/menu';
import { LAYOUT_CONST } from 'types/config';

// ==============================|| NAVIGATION - ITEM ||============================== //

interface Props {
  item: NavItemType;
  level: number;
}

const NavItem = ({ item, level }: Props) => {
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { drawerOpen, openItem } = useSelector((state) => state.menu);
  const { menuOrientation } = useConfig();

  let itemTarget: LinkTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps: {
    component: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>> | string;
    href?: string;
    target?: LinkTarget;
  } = {
    component: forwardRef((props, ref) => (
      <Link {...props} to={item.url!} onClick={() => dispatch(openDrawer(false))} target={itemTarget} />
    ))
  };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }
  const isSelected = openItem.findIndex((id) => id === item.id) > -1;

  const Icon = item.icon!;
  const itemIcon = item.icon ? <Icon variant="Bulk" size={drawerOpen ? 20 : 22} /> : false;

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname && pathname.includes('product-details')) {
      if (item.url && item.url.includes('product-details')) {
        dispatch(activeItem({ openItem: [item.id] }));
      }
    }

    if (pathname && pathname.includes('kanban')) {
      if (item.url && item.url.includes('kanban')) {
        dispatch(activeItem({ openItem: [item.id] }));
      }
    }

    if (pathname === item.url) {
      dispatch(activeItem({ openItem: [item.id] }));
    }

    // return () => {
    //   dispatch(clearBlogpost());
    // };
    // eslint-disable-next-line
  }, [pathname]);

  const textColor = theme.palette.mode === 'dark' ? 'secondary.400' : 'secondary.main';
  const iconSelectedColor = '#4F86FF';

  return (
    <>
      {menuOrientation === LAYOUT_CONST.VERTICAL_LAYOUT || downLG ? (
        <ListItemButton
          {...listItemProps}
          id="sidebar-nav"
          disabled={item.disabled}
          selected={isSelected}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderBottom: theme.palette.mode === 'dark' ? '0.1px solid #1D2630' : '0.1px solid #E8EBEE',
            zIndex: 1201,
            pl: drawerOpen ? `${level * 20}px` : 1.5,
            py: !drawerOpen && level === 1 ? 0.5 : 1.25,
            // bgcolor: level === 1 ? 'none' : theme.palette.mode === 'dark' ? 'secondary.100' : 'primary.lighter',
            // '&:hover': {
            //   bgcolor: 'none'
            // },
            ...(drawerOpen && {
              mx: 1.5,
              height:44,
              '&:hover': {
                bgcolor: theme.palette.mode === 'dark' ? '#182337 !important' : '#E9F0FF !important'
              },
              '&.Mui-selected': {
                bgcolor: theme.palette.mode === 'dark' ? '#182337 !important' : '#E9F0FF !important',
                color: iconSelectedColor,
                '&:hover': {
                  bgcolor: theme.palette.mode === 'dark' ? '#182337 !important' : '#E9F0FF !important'
                },
                mx: 1,
                border: theme.palette.mode === 'dark' ? '0.1px dotted #6293FF' : '0.1px dotted #E9F0FF !important'
              }
            }),
            ...(drawerOpen &&
              level === 1 && {
                mx: 1.25,
                my: 0.5,
                borderRadius: 1,
                '&:hover': {
                  // bgcolor: theme.palette.mode === 'dark' ? 'divider' : 'primary.200'
                  bgcolor: theme.palette.mode === 'dark' ? '#182337 !important' : '#E9F0FF !important'
                },
                '&.Mui-selected': {
                  bgcolor: theme.palette.mode === 'dark' ? '#182337 !important' : '#E9F0FF !important',
                  color: '#4F86FF !important'
                  // '&:hover': { color: iconSelectedColor, bgcolor: theme.palette.mode === 'dark' ? 'divider' : 'transparent' }
                }
              }),
            ...(!drawerOpen && {
              bgcolor: 'transparent',
              borderBottom: theme.palette.mode === 'dark' ? '0.1px solid #1D2630' : '0.1px solid #E8EBEE',
              px: 1.25,
              mx: 1.5,
              my: 0.5,
              '&:hover': {
                // bgcolor: level !== 1 ? '#E9F0FF !important' : '#E9F0FF !important'
                bgcolor: theme.palette.mode === 'dark' ? '#182337 !important' : '#E9F0FF !important'
              },
              '&.Mui-selected': {
                '&:hover': {
                  bgcolor: theme.palette.mode === 'dark' ? '#182337 !important' : '#E9F0FF !important'
                },
                bgcolor: theme.palette.mode === 'dark' ? '#182337 !important' : '#E9F0FF !important',
                color: iconSelectedColor
              }
            })
          }}
          {...(downLG && {
            onClick: () => dispatch(openDrawer(false))
          })}
        >
          {itemIcon && (
            <Tooltip title={item?.title} placement="right-end">
              <ListItemIcon
                className="mainlogoicon"
                sx={{
                  // minWidth: 38,
                  color: isSelected ? iconSelectedColor : textColor,
                  ...(!drawerOpen &&
                    level === 1 && {
                      borderRadius: 1,
                      width: 32,
                      height: 32,
                      alignItems: 'center',
                      justifyContent: 'center',
                      '&:hover': {
                        bgcolor: theme.palette.mode === 'dark' ? '#182337 !important' : '#E9F0FF !important'
                      }
                    }),
                  ...(!drawerOpen &&
                    isSelected && {
                      '&:hover': {
                        bgcolor: 'transparent'
                      }
                    })
                }}
              >
                {itemIcon}
              </ListItemIcon>
            </Tooltip>
          )}

          {!itemIcon && drawerOpen && (
            <Tooltip title={item?.title} placement="right-end">
              <ListItemIcon
                // sx={{
                //   minWidth: 30
                // }}
                className="mainlogoicon"
              >
                <Dot size={isSelected ? 6 : 5} color={isSelected ? 'primary' : 'secondary'} />
              </ListItemIcon>
            </Tooltip>
          )}

          {(drawerOpen || (!drawerOpen && level !== 1)) && (
            <ListItemText
              primary={
                <Typography
                  className="mainlogoicon"
                  variant="h6"
                  sx={{ color: isSelected ? iconSelectedColor : textColor, fontWeight: isSelected ? 500 : 400 }}
                >
                  {item.title}
                </Typography>
              }
            />
          )}
          {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
            <Chip
              color={item.chip.color}
              variant={item.chip.variant}
              size={item.chip.size}
              label={item.chip.label}
              avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
            />
          )}
        </ListItemButton>
      ) : (
        <ListItemButton
          {...listItemProps}
          disabled={item.disabled}
          selected={isSelected}
          sx={{
            zIndex: 1201,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ...(drawerOpen && {
              '&:hover': {
                bgcolor: '#B5CCFF !important'
              },
              '&.Mui-selected': {
                bgcolor: '#B5CCFF !important',
                color: iconSelectedColor,
                '&:hover': {
                  bgcolor: '#B5CCFF !important',
                  color: iconSelectedColor
                }
              }
            }),
            ...(!drawerOpen && {
              '&:hover': {
                bgcolor: '#B5CCFF !important'
              },
              '&.Mui-selected': {
                '&:hover': {
                  bgcolor: '#B5CCFF !important'
                },
                bgcolor: '#B5CCFF !important',
                color: iconSelectedColor
              }
            })
          }}
        >
          {itemIcon && (
            <ListItemIcon
              className="mainlogoicon"
              sx={{
                // minWidth: 36,
                ...(!drawerOpen && {
                  borderRadius: 1,
                  width: 36,
                  height: 36,
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  '&:hover': {
                    bgcolor: 'transparent'
                  }
                }),
                ...(!drawerOpen &&
                  isSelected && {
                    bgcolor: 'transparent',
                    '&:hover': {
                      bgcolor: 'transparent'
                    }
                  })
              }}
            >
              {itemIcon}
            </ListItemIcon>
          )}

          <ListItemText
            className="mainlogoicon"
            primary={
              <Typography variant="h6" sx={{ color: isSelected ? iconSelectedColor : textColor, fontWeight: isSelected ? 500 : 400 }}>
                {item.title}
              </Typography>
            }
          />
          {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
            <Chip
              className="mainlogoicon"
              color={item.chip.color}
              variant={item.chip.variant}
              size={item.chip.size}
              label={item.chip.label}
              avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
              sx={{ ml: 1 }}
            />
          )}
        </ListItemButton>
      )}
    </>
  );
};

export default NavItem;

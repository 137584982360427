import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userquiz: [],
  dashboard: '',
  pageIndex: 1,
  size: 10
};

const userquiz = createSlice({
  name: 'userquiz',
  initialState,
  reducers: {
    updateuserquiz(state, action) {
      state.userquiz = action.payload;
    },
    setuserquizPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    setuserquizTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    },
    setquizDashboard(state, action) {
      return {
        ...state,
        dashboard: action.payload
      };
    }
  }
});

export const { updateuserquiz, setquizDashboard, setuserquizPageIndex, setuserquizTableSize } = userquiz.actions;
export default userquiz.reducer;
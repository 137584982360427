import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  feedbackview: [],
  pageIndex: 1,
  size: 10,
  deleted: false,
  search: undefined
};

const feedbackview = createSlice({
  name: 'feedbackview',
  initialState,
  reducers: {
    updatefeedbackview(state, action) {
      state.feedbackview = action.payload;
    },
    setfeedbackviewPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    setfeedbackviewStatus(state, action) {
      state.deleted = action.payload;
    },
    setfeedbackviewSearch(state, action) {
      state.search = action.payload;
    },
    setfeedbackviewTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    },
    resetStatefeedbackview: (state) => {
      return initialState;
    }
  }
});

export const {
  updatefeedbackview,
  setfeedbackviewSearch,
  setfeedbackviewStatus,
  setfeedbackviewPageIndex,
  setfeedbackviewTableSize,
  resetStatefeedbackview
} = feedbackview.actions;
export default feedbackview.reducer;

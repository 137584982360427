import { FormattedMessage } from 'react-intl';
import { Book } from 'iconsax-react';
import { NavItemType } from 'types/menu';

const icons = { Book };

// ==============================|| MENU ITEMS - category ||============================== //

const Subjects: NavItemType = {
  id: 'Subjects',
  type: 'group',
  name: 'Subjects',
  icon: icons.Book,
  children: [
    {
      icon: icons.Book,
      id: 'Subjects',
      title: <FormattedMessage id="Subject" />,
      type: 'item',
      url: '/subject'
    }
  ]
};

export default Subjects;

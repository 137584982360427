import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  questionbankSort: [],
  questionbank: [],
  pageIndex: 1,
  size: 10,
  count: 0,
  questionCreatedBy: [],
  checked: [],
  questionsearch: undefined,
  createdbysearch: undefined
};

const questionbank = createSlice({
  name: 'questionbank',
  initialState,
  reducers: {
    updatequestionbank(state, action) {
      state.questionbank = action.payload;
    },
    updatequestionbankchecked(state, action) {
      state.checked = action.payload;
    },
    delquestionbankchecked(state: any, action) {
      state.checked = state.checked.filter((item: { _id: any }) => item._id !== action.payload);
    },
    delquestionbankcheckedAll(state: any) {
      state.checked = [];
    },
    updatequestionbanksearch(state, action) {
      state.questionsearch = action.payload;
    },
    updatequestionbankcount(state, action) {
      state.count = action.payload;
    },
    updatequestionbankcreatedby(state, action) {
      state.questionCreatedBy = action.payload;
    },
    updatequestionbankcreatedbysearch(state, action) {
      state.createdbysearch = action.payload;
    },
    setquestionbanksort(state, action) {
      state.questionbankSort = action.payload;
    },
    setquestionbankPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    delquestionbank(state: any, action) {
      state.questionbank = state.questionbank.filter((item: { _id: any }) => item._id !== action.payload);
    },
    setquestionbankTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    }
  }
});

export const {
  updatequestionbank,
  updatequestionbankcount,
  updatequestionbankcreatedby,
  delquestionbankcheckedAll,
  updatequestionbanksearch,
  updatequestionbankcreatedbysearch,
  updatequestionbankchecked,
  delquestionbankchecked,
  setquestionbanksort,
  delquestionbank,
  setquestionbankPageIndex,
  setquestionbankTableSize
} = questionbank.actions;
export default questionbank.reducer;

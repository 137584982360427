import { AES, enc } from 'crypto-js';
const moment = require('moment-timezone');
const myPassword = process.env.REACT_APP_CRYPTO_ENCRYPTION_KEY;

// Encryption function
export function encryptData(data: Object) {
  let string = JSON.stringify(data);
  const cipherText = AES.encrypt(string, myPassword);
  return cipherText.toString();
}

export function checkValidUser(userType: string, receiverType: string) {
  if (userType === 'admin' && receiverType === 'admin') {
    return true;
  } else if (userType === 'faculty') {
    if (receiverType === 'faculty' || receiverType === 'admin') {
      return true;
    } else return false;
  } else if (userType === 'student') {
    return true;
  } else return false;
}

export function commaSeparatedFormat(number: number | string) {
  const stringValue = typeof number === 'number' ? number.toFixed(2) : number.toString();

  const [integerPart, decimalPart] = number.toString().split('.');
  const lastThree = integerPart.slice(-3);
  const restOfString = integerPart.slice(0, -3);
  let formattedInteger;
  if (integerPart.length > 3) formattedInteger = restOfString.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + ',' + lastThree;
  else formattedInteger = lastThree;

  // Check if decimalPart exists and if it's not empty
  const formattedDecimal = `${number}`.charAt(`${number}`.length - 1) === '.' || decimalPart ? `.${decimalPart}` : '';

  // Only add the decimal part if it's not .00
  const finalDecimal = formattedDecimal === '.00' ? '' : formattedDecimal;

  return formattedInteger + finalDecimal;
}

export function daysLeftUntilDate(utcDate: Date) {
  // Parse the provided UTC date string using Moment.js
  const targetDate = moment.utc(utcDate);

  // Get the current date
  const currentDate = moment.utc();

  // Calculate the difference in days
  const daysDifference = targetDate.diff(currentDate, 'days');

  // If the difference is negative, it means the date has already passed
  if (daysDifference < 0) {
    return -1;
  }

  if (daysDifference === 0) {
    // Calculate the difference in minutes
    const minutesDifference = targetDate.diff(currentDate, 'minutes');
    const hoursDifference = targetDate.diff(currentDate, 'hours');

    // If the difference is positive, it means the time is in the future
    if (hoursDifference > 0) {
      // If there are more than 1 hour left, return hours
      if (hoursDifference > 1) {
        return `${hoursDifference} hours left`;
      } else {
        // If less than 1 hour left, return minutes
        return `${minutesDifference} minutes left`;
      }
    } else if (minutesDifference > 0) {
      // If less than 1 hour left, return minutes
      return `${minutesDifference} minutes left`;
    } else {
      return -1;
    }
  }

  return `${daysDifference} days left`;
}

export function packageCheck(name: string, noOfSubjects: number, organization: any) {
  const num = organization?.packageData?.packageId?.[name];
  // console.log(noOfSubjects);
  if (noOfSubjects === null || noOfSubjects === undefined) return false;
  if (!num) return false;
  // console.log(organization?.packageData?.packageId);
  // console.log(num);

  if (num === 'unlimited') return true;
  if (Number(num) <= Number(noOfSubjects)) return false;
  else return true;
}

export function packageFeaturePermissionCheck(name: string, organization: any) {
  const num = organization?.packageData?.packageId?.selectedPerks?.includes(name);
  if (num) return true;
  else return false;
}

// Decryption function
export function decryptData(encryptedData: any) {
  const bytes = AES.decrypt(encryptedData, myPassword);
  const decrypted = bytes.toString(enc.Utf8);
  return JSON.parse(decrypted);
}

export function maskEmail(email: string | null) {
  if (email) {
    return email.replace(/^(.)(.*)(.@.*)$/, (_, a, b, c) => a + b.replace(/./g, '*') + c);
  }
}

export default function objectToKeyValueArrays(obj: Object) {
  const keys = Object.keys(obj);
  const values = Object.values(obj);
  return [keys, values];
}

export function generateString(strLength: any) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < strLength; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function getDateTimeAsTimeZone(dateTime: string, orgTimeZone: string, dateformat: string, timeFormat: string) {
  const timezone = orgTimeZone ? orgTimeZone : 'Asia/Kolkata';
  const date = dateformat ? dateformat : 'DD-MM-YYYY';
  const time = timeFormat ? timeFormat : '12-hour';
  const moment = require('moment-timezone');
  return moment(dateTime)
    .tz(timezone)
    .format(`${date} ${time === '12-hour' ? 'hh:mm A' : 'HH:mm'}`);
}

export function getHourAsTimeZone(hour: string, orgTimeZone: string, timeFormat: string) {
  const timezone = orgTimeZone ? orgTimeZone : 'Asia/Kolkata';
  const time = timeFormat ? timeFormat : '12-hour';
  const moment = require('moment-timezone');
  const hoursAsTimeZone = moment.tz(timezone).hour(hour);
  const formattedTime = hoursAsTimeZone.format(time === '12-hour' ? 'hh A' : 'HH');
  return formattedTime;
}

export function formatTimeRange(startDate: string, endDate: string, orgTimeZone: string) {
  const moment = require('moment-timezone');
  const timezone = orgTimeZone ? orgTimeZone : 'Asia/Kolkata';
  const date1 = moment(startDate);
  const date2 = moment(endDate);
  const isSameDay = date1.format('YYYY-MM-DD') === date2.format('YYYY-MM-DD');
  const formattedStartTime = moment(date1).tz(timezone).format('dddd, MMMM D, YYYY h:mm A');
  const formattedEndTime = isSameDay ? date2.format('h:mm A') : date2.format('dddd, MMMM D, YYYY h:mm A');
  return `${formattedStartTime} – ${formattedEndTime}`;
}

export function formatCurrency(amount: any, orgCurrency: any) {
  if (orgCurrency) {
    const country = orgCurrency && orgCurrency.country ? orgCurrency.country : 'en-IN';
    const currency = orgCurrency && orgCurrency.currency ? orgCurrency.currency : 'INR';
    const formatter = new Intl.NumberFormat(country, { style: 'currency', currency: currency });

    return formatter.format(Number(amount));
  } else {
    return amount;
  }
}

export async function getLocaleName(i18n: string) {
  // const { i18n } = useConfig();
  let word: any;
  switch (i18n) {
    case 'fr':
      word = await import('utils/locales/fr.json');
      break;

    case 'ro':
      word = await import('utils/locales/ro.json');
      break;

    case 'zh':
      word = await import('utils/locales/zh.json');
      break;
    // case 'hi':
    //   return import('utils/locales/hi.json');
    case 'ar':
      word = await import('utils/locales/ar.json');
      break;
    case 'en':
      word = await import('utils/locales/en.json');
      break;

    default:
      word = await import('utils/locales/en.json');
      break;
  }
  const filteredJson = word;
  return filteredJson;
}

export function GetTimeFromMiliseconds(miliseconds: number) {
  const moment = require('moment');

  let x = miliseconds; // Milliseconds
  let d = moment.duration(x, 'milliseconds');

  // Check if the duration has days
  if (d.days() > 0) {
    let days = d.days();
    let hours = d.asHours() - days * 24;
    let mins = d.asMinutes() - days * 24 * 60 - hours * 60;
    return { days: days, hours: hours, mins: mins };
  } else {
    let hours = Math.floor(d.asHours());
    let mins = Math.floor(d.asMinutes()) - hours * 60;
    return { hours: hours, mins: mins };
  }
}

export function compareDatesByDay(date1: Date, date2: Date) {
  const year1 = date1.getFullYear();
  const month1 = date1.getMonth();
  const day1 = date1.getDate();

  const year2 = date2.getFullYear();
  const month2 = date2.getMonth();
  const day2 = date2.getDate();

  if (year1 < year2 || (year1 === year2 && month1 < month2) || (year1 === year2 && month1 === month2 && day1 < day2)) {
    return 'date1 < date2';
  } else if (year1 === year2 && month1 === month2 && day1 === day2) {
    return 'date1 = date2';
  } else {
    return 'date1 > date2';
  }
}
export function secondsToTime(secs: number) {
  let hours = Math.floor(secs / (60 * 60));
  let divisor_for_minutes = secs % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);
  return `0${hours}:${minutes}`;
}

export function displayDuration(min: String | Number) {
  let mins = Number(min);
  let hour;
  if (mins > 60) {
    hour = mins / 60;
    mins = mins % 60;
    return `${Math.floor(hour)}h ${mins}m`;
  }
  return `${mins}m`;
}

export function isDateExpired(dateString: string | Date) {
  const currentDate = new Date();
  const endDate = new Date(dateString);

  const currentUtcDate = new Date(currentDate.toUTCString());
  const endUtcDate = new Date(endDate.toUTCString());

  return currentUtcDate > endUtcDate;
}

export function displayhours(min: String | Number) {
  let mins = Number(min);
  let hour;
  if (mins > 60) {
    hour = mins / 60;
    mins = mins % 60;
    return `${Math.floor(hour)}+ hours`;
  }
  return `${mins}m`;
}
export function getWorkingDaysAndHolidays(startDate: any, endDate: any) {
  const currentDate = new Date(startDate);
  const finalDate = new Date(endDate);
  let result: any = {
    workingDays: [],
    holidays: []
  };
  while (currentDate <= finalDate) {
    const currentDateClone = new Date(currentDate); // Create a new Date object
    if (currentDateClone.getDay() === 0) {
      result.holidays.push(currentDateClone);
    } else {
      result.workingDays.push(currentDateClone);
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return result;
}

export const getTime = (dateString: any, orgTimeZone: string) => {
  const moment = require('moment');
  const utcMoment = moment?.utc(dateString);

  // Convert the time to Asia/Kolkata timezone
  const kolkataMoment = utcMoment?.tz(orgTimeZone);

  // Extract the hours and minutes in 12-hour format
  const hours = kolkataMoment?.hours();
  const minutes = kolkataMoment?.minutes();
  let timeString;

  if (hours >= 12) {
    timeString = `${(hours % 12).toString().padStart(2, '0')}:${minutes?.toString()?.padStart(2, '0')} PM`;
  } else {
    timeString = `${hours?.toString()?.padStart(2, '0')}:${minutes?.toString()?.padStart(2, '0')} AM`;
  }

  return timeString;
};

export const convertToLocalTime = (dateString: any, timeZone = 'Asia/Calcutta') => {
  // Parse the date string with moment and convert to the desired timezone
  const localTime = moment(dateString).tz(timeZone).format('YYYY-MM-DDTHH:mm:ssZ');
  return localTime;
};

export const isStartTimeValid = (startTime: string, endTime: string) => {
  const startMoment = moment(startTime);
  const endMoment = moment(endTime);
  const currentMoment = moment();
  const diffInMinutes = startMoment.diff(currentMoment, 'minutes');
  const endDiffInMinutes = endMoment.diff(currentMoment, 'minutes');

  return diffInMinutes > 2 || endDiffInMinutes < 0;
};

export const isStartTimePassed = (startTime: string) => {
  const startMoment = moment(startTime);
  const currentMoment = moment();
  const diffInMinutes = startMoment.diff(currentMoment, 'minutes');
  return diffInMinutes < 0;
};

export const formatDateBasedOnOrgSettings = (date: any, organization: any) => {
  // Default values
  const defaultTimeZone = process.env.REACT_APP_DEFAULT_ORGANIZATION_TIMEZONE;
  const defaultTimeFormat = process.env.REACT_APP_DEFAULT_ORGANIZATION_TIME_FORMATE;
  const defaultDateFormat = process.env.REACT_APP_DEFAULT_ORGANIZATION_DATE_FORMATE;

  if (!date) date = new Date();

  const orgTimeZone =
    organization?.basicsettings?.defaulttimezone && organization.basicsettings.defaulttimezone !== 'undefined'
      ? organization.basicsettings.defaulttimezone
      : defaultTimeZone;

  const orgTimeFormat =
    organization?.basicsettings?.locale?.timeformat && organization.basicsettings?.locale?.timeformat !== 'undefined'
      ? organization.basicsettings?.locale?.timeformat
      : defaultTimeFormat;

  const orgDateFormat =
    organization?.basicsettings?.locale?.dateformat && organization.basicsettings?.locale?.dateformat !== 'undefined'
      ? organization.basicsettings?.locale?.dateformat
      : defaultDateFormat;

  // Time format conversion
  // const timeFormat = orgTimeFormat === '12-hour' ? 'h:mm:ss a' : 'HH:mm:ss';
  const timeFormat = orgTimeFormat === '12-hour' ? 'h:mm a' : 'HH:mm';
  // Return null if either date format or time format is not defined
  if (!orgDateFormat || !timeFormat) {
    return null;
  }

  // Format date
  return moment.utc(date).tz(orgTimeZone).format(`${orgDateFormat}, ${timeFormat}`);
};

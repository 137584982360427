import {
  Autocomplete,
  Avatar,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  useMediaQuery,
  useTheme
} from '@mui/material';
import * as IconsaxReact from 'iconsax-react';
import useAuth from 'hooks/useAuth';
import { useState } from 'react';
import MainCard from 'components/MainCard';
import { MedalStar } from 'iconsax-react';
// import 'iconsax-react/dist/iconsax.css';

function NewSampleBadge({ upperBackground, lowerBackground, badgeTitle, badgeDesc, icon: IconComponent }: any) {
  const iconName: keyof typeof IconsaxReact = IconComponent;
  const IconComponents = (IconsaxReact as any)?.[iconName] || MedalStar;
  const { organization } = useAuth();
  const [selectedIcon, setSelectedIcon] = useState('');
  const [small, setSmall] = useState(true);
  const theme = useTheme();
  const downSM = useMediaQuery(theme.breakpoints.down('sm'));

  const handleIconSelect = ({ _, value }: any) => {
    setSelectedIcon(value);
  };

  return (
    <>
      <Box
        sx={{
          zIndex: -2,
          justifyContent: 'center',
          display: 'flex',
          borderRadius: 40
        }}
        height={small ? 160 : 210}
        width={small ? 220 : 270}
        // mt={1}
        mb={2.5}
        // ml={downSM ? -1.8 : 1}
      >
        <Box
          height={small ? 200 : 240}
          width={small ? 200 : 250}
          zIndex={500}
          borderRadius={small ? 15 : 30}
          style={{
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.3)',
            backgroundColor: upperBackground ? upperBackground : '#E8EBEE',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column'
          }}
          pt={4}
        >
          <Box style={{ flex: 0.8 }}>
            <Grid container spacing={1} mb={0.5} px={3}>
              <Grid item xs={4} sx={{ justifyContent: 'center', display: 'flex', borderRight: '1px solid #A5A5A5' }}>
                <Avatar alt="MUI Logo" sx={{ width: small ? 35 : 55, height: small ? 35 : 55, bgcolor: 'transparent' }}>
                  {organization?.logoImage?.[0] ? (
                    <img
                      src={
                        organization.logoImage[0]?.path
                          ? process.env.REACT_APP_FILE_URL + organization.logoImage[0]?.path
                          : `data:image/png;base64,${organization.logoImage[0]?.file}`
                      }
                      alt="Organization Logo"
                      height={45}
                      width={45}
                    />
                  ) : (
                    <svg
                      version="1.0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="40.000000pt"
                      height="40.000000pt"
                      viewBox="0 0 40.000000 40.000000"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <g transform="translate(0.000000,40.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                        <path
                          d="M0 361 l0 -38 43 21 c61 30 185 29 245 -2 40 -22 82 -72 82 -99 0 -9
-19 -13 -60 -13 -50 0 -63 4 -84 25 -29 29 -90 34 -122 10 -19 -15 -19 -15 3
-34 12 -10 52 -26 89 -35 98 -25 143 -51 160 -93 9 -20 13 -47 9 -61 -6 -23 6
-41 28 -42 4 0 7 90 7 200 l0 200 -200 0 -200 0 0 -39z"
                        />
                        <path
                          d="M0 120 c0 -40 0 -40 40 -40 27 0 40 -4 40 -13 0 -44 113 -68 148 -33
15 15 15 17 1 32 -9 8 -48 23 -87 34 -40 10 -88 27 -107 39 l-35 20 0 -39z"
                        />
                      </g>
                    </svg>
                  )}
                </Avatar>
              </Grid>
              <Grid item xs={2} sx={{ userSelect: 'none', color: 'black' }}>
                <Stack fontSize={small ? '13px' : '18px'} fontWeight={600}>
                  {organization?.basicsettings?.identity?.site_name ? (
                    <>
                      <Stack>
                        {organization?.basicsettings?.identity?.site_name.split(' ').length > 2
                          ? organization?.basicsettings?.identity?.site_name.split(' ').slice(0, 2).join(' ')
                          : organization?.basicsettings?.identity?.site_name}
                      </Stack>

                      {organization?.basicsettings?.identity?.site_name.split(' ').length > 3 && (
                        <Stack>{organization?.basicsettings?.identity?.site_name.split(' ')?.slice(3).join(' ')}</Stack>
                      )}
                    </>
                  ) : (
                    'Sapphire LMS'
                  )}
                </Stack>
                {/* <b>
                  {organization?.basicsettings?.identity?.site_name
                    ? organization.basicsettings.identity.site_name
                    : 'Sapphire Sophtware Solutions'}
                </b> */}
              </Grid>
            </Grid>
          </Box>
          <Box
            style={{
              flex: 2,
              backgroundColor: lowerBackground ? lowerBackground : '#7C0000',
              justifyContent: 'center',
              display: 'flex',
              userSelect: 'none',
              paddingInline: '10px'
            }}
          >
            <Stack direction={'column'} textAlign={'center'} pt={1} spacing={0} color={'white'}>
              <Stack fontSize={small ? '13px' : '22px'} textTransform={'uppercase'}>
                {badgeTitle ? <b>{badgeTitle}</b> : <b>Title</b>}
              </Stack>
              {/* <Stack fontSize={small ? '14px' : '18px'}>{badgeDesc !== 'undefined undefined' ? badgeDesc : 'Description'}</Stack> */}
              <Stack fontSize={small ? '11px' : '18px'}>
                {badgeDesc !== 'undefined undefined' ? (
                  <>
                    <Stack>{badgeDesc.split(' ').length > 2 ? badgeDesc.split(' ')?.slice(0, 3).join(' ') : badgeDesc}</Stack>
                    {badgeDesc.split(' ').length > 3 && <Stack>{badgeDesc.split(' ')?.slice(3).join(' ')}</Stack>}
                  </>
                ) : (
                  'Description'
                )}
              </Stack>
              <Stack sx={{ display: 'flex', alignItems: 'center' }}>
                <IconComponents color="#ffffff" size={small ? '32' : '44'} variant="Bulk" />
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default NewSampleBadge;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  coursereassign: [],
  pageIndex: 1,
  deleted: false,
  count: 0,
  size: 10,
  search: undefined
};

const coursereassign = createSlice({
  name: 'coursereassign',
  initialState,
  reducers: {
    updatecoursereassign(state, action) {
      state.coursereassign = action.payload;
    },
    setcoursereassignSearch(state, action) {
      state.search = action.payload;
    },
    setcoursereassignTotalCount(state, action) {
      state.count = action.payload;
    },
    setcoursereassignstatus(state, action) {
      state.deleted = action.payload;
    },
    setcoursereassignPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    setcoursereassignTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    },
    setcourseassignRemove(state: any, action) {
      return {
        ...state,
        coursereassign: {
          ...state.coursereassign,
          data: state.coursereassign.data.filter((message: any) => message._id !== action.payload)
        }
      };
    },
    editcoursereassignstatus(state: any, action) {
      const { status, id } = action.payload;
      const index = state.coursereassign.data.findIndex((item: any) => item._id === id);
      const updatedCustomPageBanners: any = [...state.coursereassign.data];
      updatedCustomPageBanners[index] = { ...updatedCustomPageBanners[index], status };
      return {
        ...state,
        coursereassign: {
          ...state.coursereassign,
          data: updatedCustomPageBanners
        }
      };
    }
  }
});

export const {
  updatecoursereassign,
  setcoursereassignSearch,
  setcoursereassignTotalCount,
  setcoursereassignstatus,
  setcoursereassignPageIndex,
  setcourseassignRemove,
  setcoursereassignTableSize,
  editcoursereassignstatus
} = coursereassign.actions;
export default coursereassign.reducer;

// action - state management
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { REGISTER, LOGIN, LOGOUT } from './actions';

// types
// import { AuthProps, AuthActionProps } from 'types/auth';
import { AuthProps } from 'types/auth';
import { getUserByToken } from 'services/user.service';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { KeyedObject } from 'types/root';
import { dispatch } from 'store';

const verifyToken: (st: string) => boolean = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded: KeyedObject = jwtDecode(serviceToken);

  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken?: string | null) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.authToken = `${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.authToken;
  }
};

// initial state
export const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  mode: 'light'
};

const authSilce = createSlice({
  name: 'users',
  initialState,
  reducers: {
    regiter(state, action) {
      const { user } = action.payload!;
      state.user = user;
    },
    login(state, action) {
      const { user } = action.payload!;
      state.user = user;
      state.isLoggedIn = true;
      state.isInitialized = true;
    },
    logout(state, action) {
      state.user = null;
      state.isInitialized = true;
      state.isLoggedIn = false;
    },
    setmode(state, action) {
      state.mode = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getLoggedInUser.fulfilled, (state: any, action: any) => {
      state.data = action.payload;
    });
  }
});

export const { regiter, login, logout, setmode } = authSilce.actions;

export const getLoggedInUser = createAsyncThunk('auth/user', async () => {
  const serviceToken = localStorage.getItem('serviceToken');
  if (serviceToken && verifyToken(serviceToken)) {
    setSession(serviceToken);
    // const response = await axios.get('/api/account/me');
    const response = await getUserByToken();
    const user = response.data.data;
    return user;
  } else {
    dispatch(logout);
  }
});

export default authSilce.reducer;

import { Box, Divider, Grid, Skeleton, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTable, Column, HeaderGroup } from 'react-table';

function ReactTable({ columns, count }: { columns: Column[]; count?: any }) {
  const data = Array.from({ length: count || 10 }, () => ({}));
  const { getTableProps, headerGroups } = useTable({
    columns,
    data
  });

  return (
    <Stack>
      <Table {...getTableProps()}>
        <TableHead sx={{ borderTopWidth: 2 }}>
          {headerGroups.map((headerGroup: HeaderGroup, groupIndex: number) => {
            const headerGroupProps = headerGroup.getHeaderGroupProps();
            const { key, ...restHeaderGroupProps } = headerGroupProps;
            return (
              <TableRow key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column: any, columnIndex: number) => (
                  <TableCell key={columnIndex}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Box sx={{ width: 'max-content' }}>
                        <FormattedMessage id={String(column.render('Header'))} />
                      </Box>
                    </Stack>
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableHead>
        <TableBody>
          {data.map((_, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((_, columnIndex) => (
                <TableCell key={columnIndex}>
                  <Skeleton sx={{ height: 30 }} animation="wave" variant="rounded" />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Stack>
  );
}

const SkeletonTable = ({ columns, count, pagination }: { columns: Column[]; count?: any; pagination?: any }) => {
  return (
    <>
      <ReactTable columns={columns} count={count} />
      <Divider />
      {!pagination && (
        <Box sx={{ p: 2, py: 2 }}>
          <Grid container alignItems="center" justifyContent="space-between" sx={{ width: 'auto' }}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Skeleton sx={{ height: 30, width: 162 }} animation="wave" variant="rounded" />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Skeleton sx={{ height: 30, width: 162 }} animation="wave" variant="rounded" />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default SkeletonTable;

import React, { useState } from 'react';
import { Grid, TextField, Button, Stack, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { addContent, editContent } from 'services/landingPageManagement.service';
import Snackbar from 'utils/Snackbar';

// Validation schema for a single question and answer
const validationSchema = yup.object({
  question: yup.string().required('Question is required'),
  answer: yup.string().required('Answer is required'),
});

const FAQForm = (data: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { rowData } = location.state || {};
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const formik = useFormik({
    initialValues: {
      question: rowData?.question || '',
      answer: rowData?.answer || '',
    },
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      setIsButtonDisabled(true);

      const serviceMethod = rowData ? editContent : addContent;
      formData.append('question', values.question);
      formData.append('answer', values.answer);
      rowData && formData.append('id', rowData?._id?.toString());

      serviceMethod(formData, 'faq')
        .then((res) => {
          const successMessage = rowData ? 'Content updated successfully' : 'Content added successfully';
          const errorMessage = res?.data?.message || 'An error occurred';

          if (res.status === 200 || res.status === 201) {
            Snackbar(successMessage, 'success');
            navigate('/superadmin/landingpagemanagement');
          } else {
            Snackbar(errorMessage, 'error');
            setIsButtonDisabled(false);
          }
        })
        .catch((err) => {
          Snackbar(err?.data?.message || 'An error occurred', 'error');
          setIsButtonDisabled(false);
        });
    },
  });

  return (
    <Box p={2}>
      <form onSubmit={formik.handleSubmit}>
        <Box mb={2} p={2} border="1px solid #e0e0e0" borderRadius="8px">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Question"
                fullWidth
                variant="outlined"
                id="question"
                name="question"
                value={formik.values.question}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.question && Boolean(formik.errors.question)}
                helperText={formik.touched.question && formik.errors.question}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Answer"
                fullWidth
                variant="outlined"
                id="answer"
                name="answer"
                multiline
                rows={4}
                value={formik.values.answer}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.answer && Boolean(formik.errors.answer)}
                helperText={formik.touched.answer && formik.errors.answer}
              />
            </Grid>
          </Grid>
        </Box>

        <Stack direction="row" justifyContent="center" mt={3}>
          <Button variant="contained" type="submit" disabled={isButtonDisabled}>
            {rowData ? 'Update FAQ' : 'Submit FAQ'}
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export default FAQForm;

import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const WindowFocusHandler = () => {
  const [show, setShow] = useState(false);
  let timeout: any;
  const navigate = useNavigate();
  const { user, logout }: any = useAuth();

  const onFocus = () => {
    clearTimeout(timeout);
    setShow(false);
  };

  const onBlur = () => {
    timeout = setTimeout(() => {
      setShow(true);
      logout(user?._id)
        .then((response: any) => {
          if (response?.status === 400 || response?.status === 404) {
            navigate('/');
          } else if (!user?._id) {
            navigate('/');
          }
        })
        .catch((error: any) => {
          console.error('Error:', error);
        });
    }, 900000);
  };

  useEffect(() => {
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    onFocus();
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
      clearTimeout(timeout);
    };
  }, []);

  if (!show) {
    return null;
  }

  return (
    <div className="alert">
      <p>You are away from the screen. Please log in again to continue...</p>
    </div>
  );
};

export default WindowFocusHandler;

import axios from 'axios';

const API_URL = process.env.REACT_APP_BASEURL;

export const getAllQuestions = (reqData: Object, signal?: any) => {
  return axios.get(API_URL + 'question/all', { params: reqData, signal });
};
export const addQuestions = (questions: Object) => {
  return axios.post(API_URL + 'question/add', questions);
};
export const addCompleteAssessment = (questions: Object) => {
  return axios.post(API_URL + 'completeAssessment/add', questions);
};
export const submitCompleteAssessment = (questions: Object) => {
  return axios.put(API_URL + 'completeAssessment/submit', questions);
};
export const resultWithAnswer = (reqData: Object) => {
  return axios.get(API_URL + 'completeAssessment/resultWithAns', { params: reqData });
};
export const addAssessment = (questions: Object) => {
  return axios.post(API_URL + 'assessment/add', questions);
};
export const updateAssessment = (questions: Object) => {
  return axios.put(API_URL + 'assessment', questions);
};
export const deleteQuestions = (reqData: object) => {
  return axios.delete(API_URL + 'question', { params: reqData });
};
export const getAssessment = (parameters: Object) => {
  return axios.get(API_URL + 'assessment', { params: parameters });
};
export const getAllLearnerAssessment = (parameters: Object) => {
  return axios.get(API_URL + 'assessment/getall', { params: parameters });
};
export const getAllAssessment = (parameters: Object) => {
  return axios.get(API_URL + 'assessment/all', { params: parameters });
};
export const checkExistOrNot = (parameters: Object) => {
  return axios.get(API_URL + 'question/check', { params: parameters });
};
export const assessmentQuiz = (parameters: Object) => {
  return axios.get(API_URL + 'assessment/orgQuiz', { params: parameters });
};
export const completeAssessment = (parameters: Object) => {
  return axios.put(API_URL + 'completeAssessment/resubmit', parameters);
};
export const assessmentCheck = (parameters: Object) => {
  return axios.get(API_URL + 'completeAssessment/check', { params: parameters });
};
export const deleteAssessment = (parameters: Object) => {
  return axios.delete(API_URL + 'assessment', { params: parameters });
};
export const questionSingle = (parameters: Object) => {
  return axios.get(API_URL + 'question', { params: parameters });
};
export const updateQuestion = (parameters: Object) => {
  return axios.put(API_URL + 'question', parameters);
};
export const assessmentHistory = (parameters: Object) => {
  return axios.get(API_URL + 'completeAssessment/userCompletedList', { params: parameters });
};
export const importQuiz = (parameters: any) => {
  return axios.post(API_URL + 'question/importQuiz', parameters);
};
export const getSingleAssessment = (parameters: Object) => {
  return axios.get(API_URL + 'assessment', { params: parameters });
};

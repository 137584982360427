import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  categoryview: [],
  pageIndex: 1,
  size: 10,
  deleted: false,
  search: undefined
};

const categoryview = createSlice({
  name: 'categoryview',
  initialState,
  reducers: {
    updatecategoryview(state, action) {
      state.categoryview = action.payload;
    },
    setcategoryviewPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    setCategoryViewSearch(state, action) {
      state.search = action.payload;
    },
    setCategoryViewStatus(state, action) {
      state.deleted = action.payload;
    },
    delcategoryview(state: any, action) {
      state.categoryview.categories = state.categoryview.categories.filter((item: { _id: any }) => item._id !== action.payload);
    },
    setcategoryviewTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    },
    resetStatecategoryview: (state) => {
      return initialState;
    },
  }
});

export const {
  updatecategoryview,
  setCategoryViewSearch,
  setCategoryViewStatus,
  setcategoryviewPageIndex,
  delcategoryview,
  setcategoryviewTableSize,
  resetStatecategoryview
} = categoryview.actions;
export default categoryview.reducer;

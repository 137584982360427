import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  Button,
  TextField,
  FormControl,
  Typography,
  FormHelperText,
  Grid,
  Stack,
  Autocomplete,
  Box,
  Divider,
  Select,
  MenuItem,
  InputLabel,
  OutlinedInput,
  useTheme,
  useMediaQuery
} from '@mui/material';
import MainCard from 'components/MainCard';
import NewSampleBadge from './NewSampleBadge';
import * as Iconsax from 'iconsax-react';
import { CloseSquare, Eye, Save2 } from 'iconsax-react';
import useAuth from 'hooks/useAuth';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import { getLevels, putBadge, setBadge } from 'services/badges.service';
import { useLocation, useNavigate } from 'react-router-dom';
import Snackbar from 'utils/Snackbar';
import BackToggle from 'components/third-party/dropzone/BackToggle';

const Type = [
  { key: 'ACTIVITY BADGES', value: 'Login', event: 'Login' },
  { key: 'LEARNING BADGES', value: 'CoursesCompleted', event: 'Courses Completed' },
  { key: 'TEST BADGES', value: 'QuizzesCompleted', event: 'Quizzes Completed' },
  { key: 'CONTEST BADGES', value: 'ContestCompleted', event: 'Contest Completed' },
  { key: 'PERFECTIONISM BADGES', value: 'CourseCompletionPercentage', event: 'Course Completion Percentage' },
  { key: 'CERTIFICATION BADGES', value: 'CertificatesAchieved', event: 'Certificates Achieved' },
  { key: 'POINT BADGES', value: 'PointEarned', event: 'Point Earned' },
  { key: 'BLOG BADGES', value: 'BlogPostsPublished', event: 'Blog Posts Published' },
  { key: 'BLOG LIKE BADGES', value: 'BlogLikeReceived', event: 'Blog Like Received' },
  { key: 'BLOG COMMENT BADGES', value: 'BlogCommentsReceived', event: 'Blog Comments Received' }
];
const Levels = [
  'Novice Level',
  'Apprentice Level',
  'Journeyman Level',
  'Advanced Level',
  'Expert Level',
  'Master Level',
  'Grandmaster Level',
  'Supreme Level'
];

const AddBadgeForm = () => {
  const { user }: any = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  let reqData = { organizationId: user?.organizationId?._id };
  const updateData = location.state?.dataset;
  const selectedType = location.state?.data;
  const [selectedIcon, setSelectedIcon] = useState<any>(updateData?.icon || 'MedalStar');
  const [finalValue, setFinalValue] = useState<any>([]);
  const [numberCount, setNumberCount] = useState<any>([]);
  const [badgeLimit, setBadgeLimit] = useState<any>('');
  const [submit, setSubmit] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const downMD = useMediaQuery(theme.breakpoints.down('md'));
  const downSM = useMediaQuery(theme.breakpoints.down('sm'));

  const initialValues = {
    icon: updateData && updateData?.icon ? updateData?.icon : selectedIcon,
    type: updateData && updateData?.type ? updateData?.type : selectedType || '',
    BadgeEvent: updateData && updateData?.BadgeEvent ? updateData?.BadgeEvent : '',
    BadgeTitle: updateData && updateData?.BadgeTitle ? updateData?.BadgeTitle : '',
    unlockAchievement: updateData && updateData?.unlockAchievement ? updateData?.unlockAchievement : '',
    unlockCount: updateData && updateData?.unlockCount ? updateData?.unlockCount : 1,
    BadgeLevel: updateData && updateData?.BadgeLevel ? updateData?.BadgeLevel : '',
    upperBackgroundColor: updateData && updateData?.upperBackgroundColor ? updateData?.upperBackgroundColor : '#E8EBEE',
    lowerBackgroundColor: updateData && updateData?.lowerBackgroundColor ? updateData?.lowerBackgroundColor : '#7C0000',
    organizationId: reqData.organizationId
  };

  const validationSchema: Yup.ObjectSchema<any> = Yup.object().shape({
    type: Yup.string().required('Type is required'),
    unlockAchievement: Yup.string().required('Level is required'),
    icon: Yup.string().required('Select an icon'),
    unlockCount: Yup.number()
      .required('Unlocked On is required')
      .min(1, 'Unlocked On must be at least 1')
      .max(10000, 'Unlocked On must be at most 10000')
  });
  const Icons: any = Iconsax;
  const onSubmit = (values: any) => {
    values.BadgeEvent = Type.find((item: any) => item.key === values.type)?.value;
    values.BadgeTitle = Type.find((item: any) => item.key === values.type)?.event;
    values.BadgeLevel = Levels[parseInt(values.unlockAchievement) - 1];
    setFinalValue(values);

    if (submit) {
      setDisabled(true);
      if (updateData) {
        values.badgeId = updateData?._id;
        putBadge(values)
          .then((res) => {
            if (res.status === 200) {
              setSubmit(false);
              Snackbar('Badge Updated Successfully', 'success');
              navigate('/badges');
            } else {
              if (res.status === 208) {
                setSubmit(false);
                Snackbar(res.data.message, 'success');
              }
            }
          })
          .catch((err) => {
            setSubmit(false);
            if (err?.response?.status === 403) {
              const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
              Snackbar(errorMessage, 'warning');
            } else {
              const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
              Snackbar(errorMessage, 'error');
            }
          })
          .finally(() => {
            setDisabled(false);
          });
      } else {
        setBadge(values)
          .then((res) => {
            if (res.status === 201) {
              setSubmit(false);
              Snackbar('Badge Created Successfully.', 'success');
              navigate('/badges');
              localStorage.removeItem('BadgeData');
              localStorage.removeItem('logoDetails');
            } else if (res.status === 208) {
              Snackbar(res.data.message, 'success');
            }
          })
          .catch((err) => {
            setSubmit(false);
            if (err?.response?.status === 403) {
              const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
              Snackbar(errorMessage, 'warning');
            } else {
              const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
              Snackbar(errorMessage, 'error');
            }
          })
          .finally(() => {
            setDisabled(false);
          });
      }
    }
  };

  const formik: any = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  });

  const handleCheck = () => {
    if (formik.values.type) {
      getLevels({ type: formik.values.type, organizationId: reqData.organizationId })
        .then((result: any) => {
          const unlockAchievementArray: any = result.data.data.map((item: any) => item.unlockAchievement);
          setBadgeLimit(unlockAchievementArray);
          setNumberCount(result.data.data);
        })
        .catch((err: any) => {
          console.error('API Response:', err);
        });
    }
  };

  useEffect(() => {
    handleCheck();
  }, [formik.values.type]);
  const iconOptions = Object.keys(Iconsax) as Array<keyof typeof Iconsax>;
  const handleIconChange = (event: any, newValue: string | null) => {
    formik.setFieldValue('icon', newValue);
    setSelectedIcon(newValue);
  };
  const handleSave = () => {
    formik.handleSubmit();
  };

  return (
    <MainCard
      title={
        <span style={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}>
          <BackToggle show={true} />
          <span style={{ display: 'flex', flexDirection: 'column', marginLeft: '8px' }}>
            <Typography variant="h5">
              <FormattedMessage id={updateData?._id ? 'Update Badge' : 'Add Badge'} />
            </Typography>
            <Typography variant="body2">
              <FormattedMessage
                id={
                  updateData?._id
                    ? 'Update unlocked on and design of this badge.'
                    : 'Add new badge by selecting type, unlocked on and level with customizable design.'
                }
              />
            </Typography>
          </span>
        </span>
      }
    >
      <form onSubmit={formik.handleSubmit}>
        <FormControl fullWidth>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8} md={6}>
              <InputLabel>
                <FormattedMessage id="Select Type" />
              </InputLabel>
              <Select
                fullWidth
                id="type"
                name="type"
                // label="Type"
                onChange={(e) => {
                  const existingData = localStorage.getItem('BadgeData');
                  const parsedData = JSON.parse(existingData || '{}');
                  parsedData.type = e.target.value;
                  localStorage.setItem('BadgeData', JSON.stringify(parsedData));
                  formik.handleChange(e);
                }}
                value={formik.values.type}
                // disabled={updateData}
                error={formik.touched.type && Boolean(formik.errors.type)}
              >
                {Type.map((type: any, index: any) => (
                  <MenuItem key={index} value={type.key}>
                    {type.key} - For {type.event}
                  </MenuItem>
                ))}
              </Select>

              {formik.touched.type && formik.errors.type && <FormHelperText sx={{ color: '#dc2626' }}>{formik.errors.type}</FormHelperText>}
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <FormControl error={formik.touched.unlockCount && Boolean(formik.errors.unlockCount)}>
                <InputLabel>
                  <FormattedMessage id="Unlocked On" />
                </InputLabel>
                <OutlinedInput
                  id="unlockCount"
                  name="unlockCount"
                  type="number"
                  fullWidth
                  value={formik.values.unlockCount}
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value, 10); // Parse the value as an integer
                    if (newValue >= 0) {
                      // Check if the new value is not below 0
                      const existingData = localStorage.getItem('BadgeData');
                      const parsedData = JSON.parse(existingData || '{}');
                      parsedData.unlockCount = newValue; // Assign the new value
                      localStorage.setItem('BadgeData', JSON.stringify(parsedData));
                      formik.handleChange(e);
                    }
                  }}
                  error={formik.touched.unlockCount && Boolean(formik.errors.unlockCount)}
                />
                {formik.touched.unlockCount && formik.errors.unlockCount && <FormHelperText>{formik.errors.unlockCount}</FormHelperText>}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} mt={downMD && !downSM ? 2 : 0}>
              <FormControl fullWidth error={formik.touched.unlockAchievement && Boolean(formik.errors.unlockAchievement)}>
                <InputLabel>
                  <FormattedMessage id="Unlocked Level" />
                </InputLabel>
                <Select
                  fullWidth
                  id="unlockAchievement"
                  name="unlockAchievement"
                  onChange={(e) => {
                    const existingData = localStorage.getItem('BadgeData');
                    const parsedData = JSON.parse(existingData || '{}');
                    parsedData.unlockAchievement = e.target.value;
                    localStorage.setItem('BadgeData', JSON.stringify(parsedData));
                    formik.handleChange(e);
                  }}
                  value={formik.values.unlockAchievement}
                  // disabled={updateData}
                  error={formik.touched.unlockAchievement && Boolean(formik.errors.unlockAchievement)}
                >
                  {Levels.map((level: any, index: any) => {
                    const matchingData = numberCount.find((item: any) => item?.unlockAchievement === index + 1);
                    return (
                      <MenuItem key={index} value={index + 1} disabled={!badgeLimit || badgeLimit.includes(index + 1)}>
                        {level}
                        {matchingData && (
                          <>
                            - Achieve On {matchingData ? matchingData?.unlockCount : ''} {matchingData ? matchingData?.BadgeTitle : ''}
                          </>
                        )}
                      </MenuItem>
                    );
                  })}
                </Select>
                {formik.touched.unlockAchievement && formik.errors.unlockAchievement && (
                  <FormHelperText>{formik.errors.unlockAchievement}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            {/* <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="title"
                name="title"
                label="Title"
                value={formik.values.title}
                onChange={formik.handleChange}
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="description"
                name="description"
                label="Description"
                value={formik.values.description}
                onChange={formik.handleChange}
                variant="outlined"
                margin="normal"
              />
            </Grid> */}
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <Typography>Icon</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  {selectedIcon && React.createElement(Icons?.[selectedIcon], { size: '2em' })}
                  &nbsp;&nbsp;
                  <Autocomplete
                    value={formik.values.icon}
                    onChange={handleIconChange}
                    options={iconOptions}
                    getOptionLabel={(option) => option}
                    renderOption={(props, option) => {
                      const IconComponent = Icons?.[option];
                      const { key, ...restProps }: any = props;

                      return (
                        <li key={key} {...restProps}>
                          <IconComponent size="1.5em" />
                          &nbsp;
                          {option}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label={selectedIcon ? 'Selected Icon' : 'Select Icon'} variant="outlined" fullWidth />
                    )}
                    fullWidth
                  />
                </Box>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} display={'flex'} justifyContent={'center'} alignItems={'center'} ml={0}>
              <FormControl fullWidth>
                <Typography>Upper Background Color</Typography>
                <input
                  type="color"
                  id="upperBackgroundColor"
                  name="upperBackgroundColor"
                  value={formik.values.upperBackgroundColor || '#E8EBEE'}
                  onChange={formik.handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <Typography>Lower Background Color</Typography>
                <input
                  type="color"
                  id="lowerBackgroundColor"
                  name="lowerBackgroundColor"
                  value={formik.values.lowerBackgroundColor || '#7C0000'}
                  onChange={formik.handleChange}
                />
              </FormControl>
            </Grid>
          </Grid>
        </FormControl>
      </form>
      <Typography variant="h6" mt={2}>
        {finalValue?.BadgeLevel || updateData?._id ? <b> My Badge: </b> : <b> Sample Badge: </b>}
      </Typography>
      <NewSampleBadge
        badgeTitle={finalValue?.BadgeLevel || updateData?.BadgeLevel}
        badgeDesc={(finalValue?.unlockCount || updateData?.unlockCount) + ' ' + (finalValue?.BadgeTitle || updateData?.BadgeTitle)}
        lowerBackground={finalValue?.lowerBackgroundColor || updateData?.lowerBackgroundColor}
        upperBackground={finalValue?.upperBackgroundColor || updateData?.upperBackgroundColor}
        icon={finalValue?.icon || updateData?.icon}
      />
      <Divider sx={{ mt: 6 }} />
      <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ py: 2 }}>
        <Stack>
          <Button
            color="warning"
            variant="contained"
            fullWidth
            type="submit"
            onClick={() => {
              handleSave();
              setSubmit(false);
            }}
            endIcon={<Eye variant="Bulk" />}
          >
            Preview
          </Button>
        </Stack>
        <Stack direction={downSM ? 'column-reverse' : 'row'} spacing={1}>
          <Button
            size="small"
            color="error"
            variant="outlined"
            sx={{ px: 3 }}
            endIcon={<CloseSquare variant="Bulk" />}
            onClick={() => {
              navigate('/badges');
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            disabled={disabled}
            type="submit"
            onClick={() => {
              handleSave();
              setSubmit(true);
            }}
            sx={{ px: 1.5 }}
            endIcon={<Save2 variant="Bulk" />}
          >
            {updateData?._id ? 'Update' : 'Save'}
          </Button>
        </Stack>
      </Stack>
    </MainCard>
  );
};

export default AddBadgeForm;

import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
// import { stopReportingRuntimeErrors } from "react-error-overlay";

// third-party
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Locales from 'components/Locales';

// fonts
import 'assets/fonts/inter/inter.css';

// scroll bar
import 'simplebar/src/simplebar.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// apex-chart
import 'assets/third-party/apex-chart.css';
import 'assets/third-party/react-table.css';

// load mock apis
import '_api';

// project-imports
import App from './App';
import { store, persister } from 'store';
import { ConfigProvider } from 'contexts/ConfigContext';
import reportWebVitals from './reportWebVitals';
import { APP_BASE_NAME } from './config';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
// import { Provider, ErrorBoundary } from '@rollbar/react';
// import { useEffect } from 'react';

// configuration for the Rollbar.js SDK
// const rollBarConfig = {
//   accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_KEY,
//   environment: process.env.REACT_APP_ROLLBAR_ENV
// };

// if (process.env.REACT_APP_ENV === 'development') {
//   stopReportingRuntimeErrors(); // disables error overlays
// }

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});
const container = document.getElementById('root');
const root = createRoot(container!);

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

root.render(
  // <Provider config={rollBarConfig}>
  //   <ErrorBoundary>
  <ReduxProvider store={store}>
    <QueryClientProvider client={queryClient}>
      <PersistGate loading={null} persistor={persister}>
        <ConfigProvider>
          <Locales>
            <BrowserRouter basename={APP_BASE_NAME}>
              <IntlProvider locale="en">
                <App />
              </IntlProvider>
            </BrowserRouter>
          </Locales>
        </ConfigProvider>
      </PersistGate>
    </QueryClientProvider>
  </ReduxProvider>
  //   </ErrorBoundary>
  // </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

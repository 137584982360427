import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userreport: [],
  pageIndex: 1,
  size: 10,
  search: undefined,
  deleted: false,
  userReportSorting: []
};

const userreport = createSlice({
  name: 'userreport',
  initialState,
  reducers: {
    updateuserreport(state, action) {
      state.userreport = action.payload;
    },
    setuserreportPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    setuserreportstatus(state, action) {
      state.deleted = action.payload;
    },
    setUserReportSearch(state, action) {
      state.search = action.payload;
    },
    setUserReportSorting(state, action) {
      state.userReportSorting = action.payload;
    },
    deluserreport(state: any, action) {
      state.userreport.organizationUserList = state.userreport.organizationUserList.filter(
        (item: { _id: any }) => item._id !== action.payload
      );
    },
    setuserreportTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    },
    resetStateuserreport: (state) => {
      return initialState;
    }
  }
});

export const {
  updateuserreport,
  setUserReportSearch,
  setuserreportstatus,
  setUserReportSorting,
  setuserreportPageIndex,
  deluserreport,
  setuserreportTableSize,
  resetStateuserreport
} = userreport.actions;
export default userreport.reducer;

import axios from 'axios';

const API_URL = process.env.REACT_APP_BASEURL + 'landingPage';

export const addContent = (reqData: Object, type: string) => {
  return axios.post(`${API_URL}/${type}`, reqData);
};
export const editContent = (reqData: object, type: string) => {
  return axios.put(`${API_URL}/${type}`, reqData);
};
export const deleteContent = (reqData: object) => {
  return axios.delete(API_URL, { params: reqData });
};
export const getContent = (reqData: object) => {
  return axios.get(API_URL, { params: reqData });
};
export const statusChange = (reqData: object) => {
  return axios.patch(API_URL + '/active', reqData);
};

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Cup } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = { Cup };

// ==============================|| MENU ITEMS - category ||============================== //

const Badges: NavItemType = {
  id: 'Badges',
  type: 'group',
  icon: icons.Cup,
  name: 'Badges',
  children: [
    {
      icon: icons.Cup,
      id: 'badges',
      title: <FormattedMessage id="Badges" />,
      type: 'item',
      url: '/badges'
    }
  ]
};

export default Badges;

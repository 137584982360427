import { createSlice } from '@reduxjs/toolkit';
const initialState: any = {
  data: [],
  count: 0,
  pageIndex: 1,
  size: 10,
  search: ''
};

const assessment = createSlice({
  name: 'assessment',
  initialState,
  reducers: {
    setAssessment(state, action) {
      return {
        ...state,
        data: action.payload
      };
    },
    delAssessment(state, action) {
      return {
        ...state,
        data: state.data.filter((item: { _id: any }) => item._id !== action.payload)
      };
    },
    setAssesmentSearch(state, action) {
      state.search = action.payload;
    },
    setAssesmentTableSize(state, action) {
      state.size = action.payload;
    },
    setAssesmentPageIndex(state, action) {
      state.pageIndex = action.payload;
    },
    setAssesmentCount(state, action) {
      state.count = action.payload;
    }
  }
});

export const { setAssessment, delAssessment, setAssesmentSearch, setAssesmentTableSize, setAssesmentPageIndex, setAssesmentCount } =
  assessment.actions;
export default assessment.reducer;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  offerview: [],
  pageIndex: 1,
  size: 10,
  deleted: false,
  search: undefined
};

const offerview = createSlice({
  name: 'offerview',
  initialState,
  reducers: {
    updateofferview(state, action) {
      state.offerview = action.payload;
    },
    setofferviewPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    setofferviewstatus(state, action) {
      state.deleted = action.payload;
    },
    setofferviewSearch(state, action) {
      state.search = action.payload;
    },
    delofferview(state: any, action) {
      state.offerview.offerData = state.offerview.offerData.filter((item: { _id: any }) => item._id !== action.payload);
    },
    setofferviewTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    }
  }
});

export const { updateofferview, setofferviewSearch, setofferviewstatus, delofferview, setofferviewPageIndex, setofferviewTableSize } =
  offerview.actions;
export default offerview.reducer;

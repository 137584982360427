import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import StatusCode from '../../utils/StatusCode';
import { getUserCourses } from 'services/userCourse.service';
import Snackbar from 'utils/Snackbar';

const initialState = {
  data: [],
  status: StatusCode.IDLE
};

const subscribedCourseSlice = createSlice({
  name: 'subscribedCourses',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllSubscribedCourses.pending, (state, action) => {
        state.status = StatusCode.LOADING;
      })
      .addCase(getAllSubscribedCourses.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = StatusCode.IDLE;
      })
      .addCase(getAllSubscribedCourses.rejected, (state, action) => {
        state.status = StatusCode.ERROR;
      });
  }
});

// export const { delCourse } = subscribedCourseSlice.actions;
export default subscribedCourseSlice.reducer;

export const getAllSubscribedCourses = createAsyncThunk('course/get', async (reqData: any) => {
  try {
    const result = await getUserCourses(reqData);
    const data = result.data.data;
    return data;
  } catch (error: any) {
    console.error(error);
    if (error?.response?.status === 403) {
      Snackbar("You don't have access to view this", 'warning');
    }
  }
});

import axios from 'axios';

const API_URL = process.env.REACT_APP_BASEURL;


export const deleteTicket = (reqData: Object) => {
  return axios.delete(API_URL + 'adminSupport', { params: reqData });
};

export const addStudentTicket = (reqData: Object) => {
  return axios.post(API_URL + 'adminSupport', reqData);
};
export const updateStudentTicket = (reqData: Object) => {
  return axios.put(API_URL + 'adminSupport', reqData);
};
export const updateTicketStatus = (reqData: Object) => {
  return axios.put(API_URL + 'adminSupport/resolved', reqData);
};
export const getStudentTickets = (reqData: Object) => {
  return axios.get(API_URL + 'adminSupport/all', { params: reqData });
};

import { Button, Divider, FormHelperText, Grid, InputLabel, Stack, TextField } from '@mui/material';
import MainCard from 'components/MainCard';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Snackbar from 'utils/Snackbar';
import { addContent, editContent } from 'services/landingPageManagement.service';
import BackToggle from 'components/third-party/dropzone/BackToggle';
import UploadAvatar from 'components/third-party/dropzone/Avatar';

// Define validation schema
const validationSchema = yup.object({
  title: yup.string().required('Title is required'),
  files: yup.mixed().test('fileType', 'Only JPG, PNG, JPEG, and GIF files are allowed', (value) => {
    // console.log(value, 'valueimage');
    if (!value || value.length === 0) return true; // Allow empty file
    const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
    return allowedFileTypes.includes(value.type);
  })
});

function UseCaseForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const { rowData } = location.state || {};
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const existingImage = rowData?.image ? process.env.REACT_APP_FILE_URL + rowData?.image?.path : null;

  const formik = useFormik({
    initialValues: {
      title: rowData?.title || '',
      files: rowData ? rowData.image : [],
    },
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      setIsButtonDisabled(true);
      const serviceMethod = rowData ? editContent : addContent;

      // Append title and image to form data
      formData.append('title', values.title);
      if (formik.values?.files?.length !== 0) {
        formData.append('image', formik.values.files);
      }

      rowData && formData.append('id', rowData?._id?.toString());

      // Submit form
      serviceMethod(formData, 'useCase')
        .then((res) => {
          const successMessage = rowData ? 'Content updated successfully' : 'Content added successfully';
          const errorMessage = res?.data?.message || 'An error occurred';

          if (res.status === 200 || res.status === 201) {
            Snackbar(successMessage, 'success');
            navigate('/superadmin/landingpagemanagement');
          } else {
            Snackbar(errorMessage, 'error');
            setIsButtonDisabled(false);
          }
        })
        .catch((err) => {
          Snackbar(err?.data?.message || 'An error occurred', 'error');
          setIsButtonDisabled(false);
        });
    }
  });

  return (
    <MainCard
      title={
        <span style={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}>
          <BackToggle show={true} />
          <span style={{ marginLeft: 10 }}>{rowData ? 'Update Content' : 'Add Content'}</span>
        </span>
      }
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          {/* Title Field */}
          <Grid item xs={12}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Title</InputLabel>
              <TextField
                fullWidth
                id="title"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
            </Stack>
          </Grid>

          {/* Image Upload Field */}
          <Grid item xs={12}>
            <Stack spacing={1}>
              <InputLabel htmlFor="image">Upload Image</InputLabel>
              <UploadAvatar setFieldValue={formik.setFieldValue} file={existingImage} />
              {formik.touched.files && formik.errors.files && (
                <FormHelperText error style={{ color: 'red' }}>
                  {formik.errors.files as string}
                </FormHelperText>
              )}
            </Stack>
          </Grid>

          <Divider />

          {/* Submit and Cancel Buttons */}
          <Grid item xs={12}>
            <Stack direction="row" justifyContent={{ xs: 'center', sm: 'center', md: 'flex-start', lg: 'flex-end' }}>
              <Button variant="outlined" color="error" sx={{ mr: 1 }} onClick={() => navigate(-1)}>
                Cancel
              </Button>
              <Button variant="contained" type="submit" disabled={isButtonDisabled}>
                {rowData ? 'Update' : 'Add'}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
}

export default UseCaseForm;

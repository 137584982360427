import { Button, Grid, Box, Typography, useTheme, TextField, Divider, IconButton, Tooltip } from '@mui/material';
import { CloseCircle, CloseSquare, RepeatCircle, TickCircle } from 'iconsax-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { dispatch } from 'store';
import { verificationItem } from 'store/reducers/examDeviceTest';
import Snackbar from 'utils/Snackbar';
import Webcam from 'react-webcam';
import useAuth from 'hooks/useAuth';
import axios from 'axios';

const ViewGrid = ({ text, secondary, content }: any) => {
  return (
    <Grid
      container
      sx={{
        py: 1,
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 'normal' }}>
            {text}&nbsp;:
          </Typography>
        </Box>
        {secondary ? <Typography variant="h6">{secondary}</Typography> : <></>}
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        {content}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Divider sx={{ mt: 2 }} />
      </Grid>
    </Grid>
  );
};

const PermissionDetection = ({ examId }: any) => {
  const [dots, setDots] = useState<{ x: number; y: number; visible: boolean }[]>([]);
  const [count, setCount] = useState<number>(0);
  const formData: any = new FormData();
  const { user }: any = useAuth();
  formData.append('userId', user?._id);
  formData.append('examId', examId);
  formData.append('organizationId', user?.organizationId?._id);
  useEffect(() => {
    const fetchIpInfo = async () => {
      let ip = null;
      const TIMEOUT_DURATION = 10000;
      try {
        const response = await axios.get('https://ipinfo.io/');
        ip = response?.data?.ip;
      } catch (error) {
        try {
          const data = await axios.get('https://www.cloudflare.com/cdn-cgi/trace/', { timeout: TIMEOUT_DURATION });
          const ipRegex = /ip=([\d.]+)/;
          const ipAddressMatch = data?.data.match(ipRegex);
          ip = ipAddressMatch?.[1];
        } catch (error) {
          try {
            const data = await axios.get('https://checkip.amazonaws.com/', { timeout: TIMEOUT_DURATION });
            ip = data?.data;
          } catch (error) {
            return null;
          }
        }
      }
      formData.append('ipAddress', ip);
    };
    fetchIpInfo();
    // eslint-disable-next-line
  }, []);
  const generateDots: any = () => {
    const box = document.getElementById('box');
    if (!box) return;
    const boxWidth = box.offsetWidth;
    const boxHeight = box.offsetHeight;
    const generateRandomPosition = (): { x: number; y: number; visible: boolean } => ({
      x: Math.floor(Math.random() * (boxWidth - 30)),
      y: Math.floor(Math.random() * (boxHeight - 30)),
      visible: true
    });
    setCount(0);
    setDots(Array.from({ length: 3 }, generateRandomPosition));
  };
  useEffect(() => {
    generateDots();
  }, []);
  const colors = ['#FBAF41', '#EF3F37', '#262161'];
  const toggleDotVisibility = (index: number): void => {
    setDots((dots) => dots.map((dot, i) => (i === index ? { ...dot, visible: !dot.visible } : dot)));
    setCount(count + 1);
    if (count + 1 === 3) {
      dispatch(verificationItem({ key: 'mouse', value: true }));
      formData.append('mouse', true);
    }
  };
  const onSubmit: any = () => {
    if (enabled) {
    }
  };
  const generateRandomString: any = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+{}|:<>?~-=[]';
    return Array.from({ length: 5 }, () => characters[Math.floor(Math.random() * characters.length)]).join('');
  };
  const [generatedStringIs, setGeneratedStringIs] = useState(generateRandomString());
  const handleVerification = (inputValue: any) => {
    if (inputValue === generatedStringIs) {
      dispatch(verificationItem({ key: 'keyboard', value: true }));
      formData.append('keyboard', true);
    } else {
      dispatch(verificationItem({ key: 'keyboard', value: false }));
    }
  };
  const guideLine = [
    'Please attach camera with device.',
    'Allow camera permission.',
    `Please don't block camera permission again,allow into site setting.`,
    'Capture your photo here.',
    'Capture your id card photo here.',
    'Please attach microphone with device.',
    'Allow microphone permission.',
    `Please don't block microphone permission again,allow into site setting.`,
    'Sorry, screen sharing is not supported.',
    'Share your entire screen.',
    'Share your entire screen again.',
    `Please write this text ${generatedStringIs} in textfield.`,
    'Sorry, text not match try again.',
    'Click on colored dots.'
  ];
  const verification = useSelector((state: any) => state?.examDeviceTest)?.verification;
  const enabled =
    verification.webcam &&
    verification.webcamPermission === 'granted' &&
    verification.microphone &&
    verification.microphonePermission === 'granted' &&
    verification.screenSharing &&
    verification.screenSharingTest &&
    verification.keyboard &&
    verification.mouse;
  const webcamRef: any = useRef(null);
  const capture = useCallback(
    async (key: any) => {
      const imageSrc = webcamRef.current.getScreenshot();
      const response = await fetch(imageSrc);
      const blob = await response.blob();
      dispatch(verificationItem({ key, value: imageSrc }));
      formData.append(key, blob);
    },
    // eslint-disable-next-line
    [webcamRef]
  );
  const retake = (key: any) => {
    dispatch(verificationItem({ key, value: undefined }));
  };
  const regenerateCode = () => {
    setGeneratedStringIs(generateRandomString());
    dispatch(verificationItem({ key: 'keyboardKey', value: 0 }));
  };
  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        devices.forEach((device) => {
          if (device.kind === 'videoinput') {
            dispatch(verificationItem({ key: 'webcam', value: true }));
          } else if (device.kind === 'audioinput') {
            dispatch(verificationItem({ key: 'microphone', value: true }));
          }
        });
      });
    }

    if (navigator.mediaDevices) {
      dispatch(verificationItem({ key: 'screenSharing', value: true }));
    } else {
      dispatch(verificationItem({ key: 'screenSharing', value: false }));
    }

    navigator.permissions.query({ name: 'camera' as PermissionName }).then((permissionStatus) => {
      dispatch(verificationItem({ key: 'webcamPermission', value: permissionStatus.state }));
    });

    navigator.permissions.query({ name: 'microphone' as PermissionName }).then((permissionStatus) => {
      dispatch(verificationItem({ key: 'microphonePermission', value: permissionStatus.state }));
    });
  }, []);
  const requestMicrophonePermissions = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      if (stream) {
        const tracks = stream.getAudioTracks();
        tracks.forEach((track) => track.stop());
        dispatch(verificationItem({ key: 'microphonePermission', value: 'granted' }));
        formData.append('microphone', true);
      }
      Snackbar('Microphone permission granted.', 'success');
    } catch (error) {
      const errorMessage = typeof error === 'string' ? error : error instanceof Error ? error.name : 'Unknown error';
      Snackbar(errorMessage === 'NotAllowedError' ? 'Microphone is blocked, allow permission.' : 'No microphone found.', 'error');
      dispatch(verificationItem({ key: 'microphonePermission', value: errorMessage === 'NotAllowedError' ? 'denied' : 'prompt' }));
    }
  };
  const requestCameraPermissions = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (stream) {
        const tracks = stream.getVideoTracks();
        tracks.forEach((track) => track.stop());
        dispatch(verificationItem({ key: 'webcamPermission', value: 'granted' }));
        formData.append('camera', true);
      }
      Snackbar('Webcam permission granted.', 'success');
    } catch (error) {
      const errorMessage = typeof error === 'string' ? error : error instanceof Error ? error.name : 'Unknown error';
      dispatch(verificationItem({ key: 'webcamPermission', value: errorMessage === 'NotAllowedError' ? 'denied' : 'prompt' }));
    }
  };
  const screenShareActivation = () => {
    // Retrieve screen constraints
    if (navigator.mediaDevices) {
      const mediaDevices = navigator.mediaDevices as any;
      mediaDevices
        .getDisplayMedia({
          video: {
            displaySurface: 'monitor'
          }
        })
        .then((stream: any) => {
          const display: any = stream.getVideoTracks()[0].getSettings();
          if (display?.displaySurface === 'monitor') {
            dispatch(verificationItem({ key: 'screenSharingTest', value: true }));
            formData.append('screenSharing', true);
          } else {
            dispatch(verificationItem({ key: 'screenSharingTest', value: false }));
            Snackbar('Selection of the entire screen is mandatory!', 'error');
          }
          stream.getTracks().forEach((track: { stop: () => void }) => track.stop());
          dispatch(verificationItem({ key: 'device', value: determineDeviceType(display?.width) }));
          formData.append('device', determineDeviceType(display?.width));
        })
        .catch((err: any) => {
          Snackbar('Error occurred while retrieving screen.', 'error');
        });
    }
  };

  const determineDeviceType = (width: any) => {
    if (width <= 768) {
      return 'mobile';
    } else if (width <= 992) {
      return 'tablet';
    } else if (width <= 1200) {
      return 'laptop';
    } else {
      return 'desktop';
    }
  };
  const theme = useTheme();

  const Tick = ({ text }: any) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TickCircle size={20} style={{ color: theme.palette.success.main }} />
        &nbsp;{text}
      </Box>
    );
  };
  const Cancel = ({ text, secondary }: any) => {
    return (
      <>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CloseCircle size={20} style={{ color: theme.palette.error.main }} />
          <Typography variant="h5">&nbsp;{text}</Typography>
        </Box>
        <Typography style={{ color: theme.palette.error.main }}>(*&nbsp;{secondary})</Typography>
      </>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center', py: 2 }}>
        <Typography variant="h3">Device Compatibility Check</Typography>
      </Grid>
      {verification?.webcam && (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
          {(!verification?.profile || !verification?.idCard) && (
            <>
              <Webcam height={180} width={180} ref={webcamRef} screenshotFormat="image/jpeg" />
              <br />
              <Button variant="contained" color="success" onClick={() => capture(!verification?.profile ? 'profile' : 'idCard')}>
                Capture {!verification?.profile ? 'profile' : 'idCard'}
              </Button>
            </>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'space-evenly', py: 1 }}>
            {verification?.profile && (
              <Box sx={{ textAlign: 'center', border: '1px solid black', borderRadius: '8px', px: 1, py: 0.5 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                  <Typography variant="h5">Profile</Typography>
                  <Tooltip title="Remove and Retake">
                    <IconButton color="error" onClick={() => retake('profile')}>
                      <CloseSquare />
                    </IconButton>
                  </Tooltip>
                </Box>
                <img
                  src={verification?.profile}
                  alt="Selected"
                  style={{
                    height: 120,
                    width: 120,
                    backgroundColor: '#F0F8FF',
                    color: '#73AAE0',
                    border: '1px dashed gray',
                    borderRadius: '18px',
                    cursor: 'pointer'
                  }}
                />
              </Box>
            )}
            {verification?.idCard && (
              <Box sx={{ textAlign: 'center', border: '1px solid black', borderRadius: '8px', px: 1, py: 0.5 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                  <Typography variant="h5">Id Card</Typography>
                  <Tooltip title="Remove and Retake">
                    <IconButton color="error" onClick={() => retake('idCard')}>
                      <CloseSquare />
                    </IconButton>
                  </Tooltip>
                </Box>
                <img
                  src={verification?.idCard}
                  alt="Selected"
                  style={{
                    height: 120,
                    width: 120,
                    backgroundColor: '#F0F8FF',
                    color: '#73AAE0',
                    border: '1px dashed gray',
                    borderRadius: '18px',
                    cursor: 'pointer'
                  }}
                />
              </Box>
            )}
          </Box>
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <ViewGrid
          text="Camera"
          secondary={guideLine[1]}
          content={
            <>
              {verification?.webcam ? (
                verification?.webcamPermission === 'granted' ? (
                  <Tick text="Granted" />
                ) : verification?.webcamPermission === 'prompt' ? (
                  <Button variant="contained" color="success" size="small" onClick={requestCameraPermissions}>
                    Allow permission
                  </Button>
                ) : (
                  <Cancel text="Camera permission blocked." secondary={guideLine[2]} />
                )
              ) : (
                <Cancel text="Camera not available." secondary={guideLine[0]} />
              )}
            </>
          }
        />
        <ViewGrid
          text="Microphone"
          secondary={guideLine[6]}
          content={
            <>
              {verification?.microphone ? (
                verification?.microphonePermission === 'granted' ? (
                  <Tick text="Granted" />
                ) : verification?.microphonePermission === 'prompt' ? (
                  <Button variant="contained" color="success" size="small" onClick={requestMicrophonePermissions}>
                    Allow permission
                  </Button>
                ) : (
                  <Cancel text="Microphone permission blocked." secondary={guideLine[7]} />
                )
              ) : (
                <Cancel text="Microphone not available." secondary={guideLine[5]} />
              )}
            </>
          }
        />
        <ViewGrid
          text="Screen sharing"
          secondary={guideLine[9]}
          content={
            <>
              {verification?.screenSharing ? (
                verification?.screenSharingTest ? (
                  <Tick text="Working perfectly" />
                ) : (
                  <Button variant="contained" color="success" size="small" onClick={screenShareActivation}>
                    Screen share
                  </Button>
                )
              ) : (
                <Cancel text="Not supported" secondary={guideLine[8]} />
              )}
            </>
          }
        />
        <ViewGrid
          text="Keyboard"
          secondary={
            <>
              Write this text : <strong>{generatedStringIs}</strong>
            </>
          }
          content={
            <>
              {!verification?.keyboard && verification?.keyboardKey !== 5 && (
                <TextField
                  placeholder="Use keyboard"
                  onPaste={(e: any) => {
                    e.preventDefault();
                  }}
                  onKeyUp={(e: any) => {
                    let inputValue = e.target.value;
                    if (inputValue?.length === 5) {
                      handleVerification(inputValue);
                      dispatch(verificationItem({ key: 'keyboardKey', value: 5 }));
                    }
                  }}
                />
              )}
              {verification?.keyboard ? (
                <Tick text="Working perfectly" />
              ) : verification?.keyboardKey === 5 ? (
                <Cancel
                  text="Not matched"
                  secondary={
                    <>
                      {guideLine[12]}
                      <IconButton color="primary" onClick={regenerateCode}>
                        <RepeatCircle />
                      </IconButton>
                    </>
                  }
                />
              ) : (
                <></>
              )}
            </>
          }
        />
        <ViewGrid
          text="Mouse/Touch"
          secondary={
            <>
              {guideLine[13]}
              <IconButton color="primary" onClick={generateDots}>
                <RepeatCircle />
              </IconButton>
            </>
          }
          content={
            <>
              {verification?.mouse ? (
                <Tick text="Working perfectly" />
              ) : (
                count !== 3 && (
                  <Box
                    id="box"
                    sx={{
                      position: 'relative',
                      width: '200px',
                      height: '200px',
                      borderRadius: '12px',
                      boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px',
                      p: 1
                    }}
                  >
                    {dots.map(({ x, y, visible }, index) => (
                      <Box
                        key={index}
                        sx={{
                          position: 'absolute',
                          top: y,
                          left: x,
                          width: '30px',
                          height: '30px',
                          borderRadius: '50%',
                          backgroundColor: colors[index],
                          cursor: 'pointer',
                          display: visible ? 'block' : 'none'
                        }}
                        onClick={() => toggleDotVisibility(index)}
                      ></Box>
                    ))}
                  </Box>
                )
              )}
            </>
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Button variant="contained" color="primary" onClick={onSubmit} disabled={!enabled}>
          Submit & Continue
        </Button>
      </Grid>
    </Grid>
  );
};

export default PermissionDetection;

import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  groups: [],
  search: '',
  count: 0,
  pageIndex: 1,
  pageSize: 10,
  sortingData: []
};

const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    setGroup(state, action) {
      return {
        ...state,
        groups: action.payload
      };
    },
    setGroupTableCount(state, action) {
      state.count = action.payload;
    },
    searchGroupTable(state, action) {
      state.search = action.payload;
    },
    setSortingValue(state, action) {
      state.sortingData = action.payload;
    },
    setGroupTablePageIndex(state, action) {
      state.pageIndex = action.payload;
    },
    setGroupTablePageSize(state, action) {
      state.pageSize = action.payload;
    },
    delGroup(state, action) {
      const arr = state.groups.filter((item: { _id: any }) => item._id !== action.payload);
      return {
        ...state,
        groups: arr
      };
    },
    setSingleGroup(state, action) {
      const updatedGroup = state.groups.map((item: any) => {
        if (item._id === action.payload._id) return action.payload;
        return item;
      });
      state.groups = updatedGroup;
    },
    resetStateGroup: (state) => {
      return initialState;
    },
  }
});

export const {
  setGroup,
  setSortingValue,
  setGroupTablePageIndex,
  setGroupTableCount,
  setGroupTablePageSize,
  delGroup,
  setSingleGroup,
  searchGroupTable,
  resetStateGroup
} = groupSlice.actions;

export default groupSlice.reducer;

import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import Recording from 'assets/images/homepage/recording.gif';
import Snackbar from 'utils/Snackbar';
// import Webcam from 'react-webcam';
import { examCaptures } from 'services/completeExam.service';
import { useNavigate } from 'react-router';

const ExamRecording = ({ submit, completeExamId, handleSubmit }: any) => {
  const [scrRecorder, setScrRecorder] = useState(false);
  const [wcRecorder, setWcRecorder] = useState(false);
  interface CustomMediaTrackSettings extends MediaTrackSettings {
    displaySurface?: string;
  }
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [screenshots, setScreenshots] = useState<Blob[]>([]);
  const [recording, setRecording] = useState(false);
  const [stop, setStop] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [recordingBlob, setRecordingBlob] = useState<Blob | null>(null);
  // const [webcamRecordingBlob, setWebcamRecordingBlob] = useState<Blob | null>(null);
  // const [showWebcam, setShowWebcam] = useState(true);
  // const webcamRef = useRef<Webcam>(null);
  const webcamStreamRef = useRef<MediaStream | null>(null);
  const webcamMediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [displaySurface, setDisplaySurface] = useState<string | null>(null);
  const navigate = useNavigate();
  useEffect(() => {
    const captureScreen = async () => {
      try {
        const mediaDevices: any = navigator.mediaDevices
        if (mediaDevices) {
          const captureStream: MediaStream = await mediaDevices.getDisplayMedia({
            video: {
              displaySurface: 'monitor'
            },
            audio: false
          });
          setStream(captureStream);

          const displaySurfaceSetting: any = (captureStream.getVideoTracks()[0].getSettings() as CustomMediaTrackSettings).displaySurface;
          setDisplaySurface(displaySurfaceSetting);

          if (displaySurfaceSetting !== 'monitor') {
            Snackbar('You must select the entire screen to start recording.', 'warning');
            stopRecording();
            return;
          } else {
            setScrRecorder(true);
          }
        }
      } catch (error: any) {
        navigate('/learner/timetable');
        Snackbar(`Error accessing screen recording: ${error?.message}`, 'error');
      }
    };

    captureScreen();
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);
  var screenshotsBlob: any = [];
  useEffect(() => {
    const takeScreenshot = () => {
      if (stream && displaySurface === 'monitor' && document.visibilityState === 'visible') {
        const videoTrack = stream.getVideoTracks()[0];
        const videoElement = document.createElement('video');
        videoElement.srcObject = new MediaStream([videoTrack]);
        videoElement.onloadedmetadata = async () => {
          const canvas = document.createElement('canvas');
          canvas.width = videoTrack.getSettings().width!;
          canvas.height = videoTrack.getSettings().height!;
          const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
          if (ctx) {
            ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
            canvas.toBlob((blob) => {
              if (blob) {
                setScreenshots((prevScreenshots) => [...prevScreenshots, blob]);
                screenshotsBlob = [...screenshotsBlob, blob];
              }
            }, 'image/png');
          }
        };
        videoElement.play();
      }
    };

    const intervalId = setInterval(takeScreenshot, 60000);
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        takeScreenshot();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      clearInterval(intervalId);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [stream, displaySurface]);

  const startRecording = async () => {
    try {
      if (displaySurface !== 'monitor') {
        navigate('/learner/timetable');
        Snackbar('You must select the entire screen to start recording.', 'warning');
        stopRecording();
        return;
      }

      const options = {
        mimeType: 'video/webm;codecs=vp9'
      };

      if (!stream) {
        throw new Error('No media stream available for recording.');
      }

      const newRecorder = new MediaRecorder(stream, options);
      const chunks: Blob[] = [];
      const webcamChunks: Blob[] = [];

      newRecorder.ondataavailable = (event) => {
        if (event.data && event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      newRecorder.onstop = () => {
        const completeBlob = new Blob(chunks, { type: options.mimeType });
        setRecordingBlob(completeBlob);
        Snackbar('Recording stopped', 'error');
        const completeWebcamBlob = new Blob(webcamChunks, { type: options.mimeType });
        // setWebcamRecordingBlob(completeWebcamBlob);
        submitRecording(completeBlob, wcRecorder ? completeWebcamBlob : null, screenshotsBlob);
      };

      newRecorder.start();
      setRecording(true);
      setMediaRecorder(newRecorder);
      Snackbar('Recording started', 'success');

      // if (webcamRef.current && webcamRef.current.video) {
      // const webcamStream = webcamRef.current.video.srcObject as MediaStream;
      // webcamStreamRef.current = webcamStream;
      // if (webcamStreamRef.current) {
      //   setWcRecorder(true);
      // }
      // const webcamMediaRecorder = new MediaRecorder(webcamStream, options);

      // webcamMediaRecorder.ondataavailable = (event) => {
      //   if (event.data && event.data.size > 0) {
      //     webcamChunks.push(event.data);
      //   }
      // };

      // webcamMediaRecorder.onstop = () => {
      //   const completeWebcamBlob = new Blob(webcamChunks, { type: options.mimeType });
      //   setWebcamRecordingBlob(completeWebcamBlob);
      // };

      // webcamMediaRecorder.start();
      // webcamMediaRecorderRef.current = webcamMediaRecorder;
      // }
    } catch (error: any) {
      // Snackbar(`Error accessing screen recording: ${error.message}`, 'error');
    }
  };
  const stopRecording = async () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setMediaRecorder(null);
      Snackbar('Recording stopped.', 'success');
    }
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
    }
    if (webcamMediaRecorderRef.current) {
      webcamMediaRecorderRef.current.stop();
      webcamMediaRecorderRef.current = null;
    }
    if (webcamStreamRef.current) {
      webcamStreamRef.current.getTracks().forEach((track) => track.stop());
      webcamStreamRef.current = null;
    }
    setRecording(false);
  };
  useEffect(() => {
    if (stream) {
      startRecording();
    }
  }, [stream]);

  const videoConstraints = {
    width: { min: 150 },
    height: { min: 150 },
    facingMode: 'user'
  };
  const submitRecording = (ScrRecBlob: any, WebCamBlob: any, screenshotsBlob: any) => {
    const formData: any = new FormData();
    formData.append('completeExamId', completeExamId);
    if (recordingBlob || ScrRecBlob) {
      formData.append('screenRecFile', recordingBlob || ScrRecBlob, 'screenRecorder.mp4');
    }
    if (screenshotsBlob)
      if (Array.isArray(screenshotsBlob)) {
        screenshotsBlob.forEach((screenShot: any, index: any) => {
          formData.append(`activityScreenShot[${index}]`, screenShot, `screenShot${index + 1}.png`);
        });
      } else {
        if (Array.isArray(screenshots)) {
          screenshots.forEach((screenShot: any, index: any) => {
            formData.append(`activityScreenShot[${index}]`, screenShot, `screenShot${index + 1}.png`);
          });
        }
      }
    // if (webcamRecordingBlob || WebCamBlob) {
    //   formData.append('cameraRecFile', webcamRecordingBlob || WebCamBlob, 'webCamRecorder.webm');
    // }
    examCaptures(formData)
      .then((res) => {
        if (res.status === 200) {
          Snackbar('Recording Submitted Successfully', 'success');
          const type = localStorage.getItem('recordingType');
          handleSubmit(type);
        }
      })
      .catch((error) => {
        Snackbar(`Error submitting exam: ${error}`, 'error');
      });
  };
  useEffect(() => {
    if (submit !== '') {
      localStorage.setItem('recordingType', submit === 'completed' ? 'completed' : submit === 'timeOver' ? 'timeOver' : 'misconduct');
      setStop(true);
      stopRecording();
    }
  }, [submit]);
  return (
    <div>
      {/* <div>
        {recording && (
          <Button variant="contained" color="secondary" onClick={stopRecording}>
            Stop Recording
          </Button>
        )}
        <div>
          <h2>Screenshots (Blobs)</h2>
          {screenshots.map((screenshot, index) => (
            <img
              key={index}
              src={URL.createObjectURL(screenshot)}
              alt={`Screenshot ${index + 1}`}
              style={{ width: '200px', margin: '5px' }}
            />
          ))}
        </div>
        {recordingBlob && (
          <div>
            <h2>Screen Recording (Blob)</h2>
            <video controls src={URL.createObjectURL(recordingBlob)} style={{ width: '400px' }} />
          </div>
        )}
        {webcamRecordingBlob && (
          <div>
            <h2>Webcam Recording (Blob)</h2>
            <video controls src={URL.createObjectURL(webcamRecordingBlob)} style={{ width: '400px' }} />
          </div>
        )}
      </div> */}
      {/* <Box
        onMouseEnter={() => setShowWebcam(true)}
        onMouseLeave={() => setShowWebcam(false)}
        sx={{ position: 'relative', display: 'inline-block' }}
      >
        {recording && (
          <img
            alt={`Recording`}
            src={Recording}
            style={{
              cursor: 'pointer',
              width: 'auto',
              height: '30px',
              borderRadius: '12px'
            }}
          />
        )}
        <Webcam
          audio={true}
          ref={webcamRef}
          width={150}
          height={150}
          mirrored={true}
          videoConstraints={videoConstraints}
          style={{ display: showWebcam ? 'block' : 'none', position: 'absolute', marginTop: 20, top: 0, zIndex: 1 }}
        />
      </Box> */}
    </div>
  );
};

export default ExamRecording;
